import ModelInterface from "@/models/Base";
import TimestampsInterface from "@/models/TimestampsInterface";
import User from "@/models/User";
import Project from "@/models/Project";
import { plainToClass, Transform, Type } from "class-transformer";
import Dataset from "@/models/Dataset";
import { IDisplayName } from "@/models/DisplayNameInterface";

export enum ResourceTypes {
  Project = "App\\Models\\Project",
}

export default class Task
  implements ModelInterface, TimestampsInterface, IDisplayName
{
  @Type(() => User)
  public assignee?: User;
  public completed_at: string | null = null;
  public created_at: string;
  public description = "";
  @Type(() => Date)
  public starts_at: Date | null = null;
  @Type(() => Date)
  public due_at: Date | null = null;
  public name: string;
  @Type(() => User)
  public originator: User;
  @Transform(
    ({ value, obj }) => {
      switch (obj.resource_type) {
        case ResourceTypes.Project:
          return plainToClass(Project, value);
        default:
          return null;
      }
    },
    { toClassOnly: true }
  )
  public resource: Project;
  public resource_type: ResourceTypes;
  public updated_at: string;
  public deleted_at: string | null;
  public id: number;
  public is_file_required_for_completion = false;
  public datasets_count = 0;
  @Type(() => Dataset)
  public datasets: Dataset[];

  get isProjectTask(): boolean {
    return this.resource_type === ResourceTypes.Project;
  }

  getDisplayName(): string {
    return this.name;
  }
}
