import { ColumnSortDirection, SortColumnConfig } from "@/reports/ReportModel";
import * as _ from "lodash";
import { isArray, isObject } from "lodash";
import { Query } from "@cubejs-client/core";

const directionMap: Record<string, ColumnSortDirection> = {
  asc: "Ascending",
  desc: "Descending"
};

export const extractSortColumnsFromQuery = ({
  order
}: Query): SortColumnConfig[] => {
  // Theres 2 formats of order, here we convert to uniformize 2 format into one
  if (order && isObject(order) && !isArray(order)) {
    order = _.toPairs<any>(order); // There's something weird about cube-js types, sorry for the any. _.toPairs<QueryOrder>(order); should have worked.
  }
  return order && isArray(order)
    ? _.map(order, ([field, direction]: [string, string]) => ({
        field,
        direction: directionMap[direction]
      }))
    : [];
};
