import Team from "@/models/Team";
import { Type } from "class-transformer";

export default class Company {
  public id: number | null = null;
  public name: string | null = null;
  public abbreviation: string | null = null;
  public category: string | null = null;
  public url: string | null = null;
  @Type(() => Team)
  public team: Team | null = null;
}
