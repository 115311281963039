import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import ProtocolVersion from "@/models/ProtocolVersion";
import { AxiosPromise } from "axios";

export function show(
  protocolId: number,
  protocolVersionId: number
): AxiosPromise<ProtocolVersion> {
  return $http
    .get(
      `${prefix}/protocols/${protocolId}/protocol-versions/${protocolVersionId}`
    )
    .then(response => responseToClass(ProtocolVersion, response));
}
