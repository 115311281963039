import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType,
} from "@/reports/ReportModel";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["Datasets.count"],
  timeDimensions: [
    {
      dimension: "Datasets.createdAt",
    },
  ],
  order: [["Datasets.id", "desc"]],
  dimensions: [
    "Datasets.id",
    "Datasets.name",
    "Datasets.category",
    "Datasets.createdAt",
    "DatasetTask.exists",
    "Projects.businessId",
    "ProjectLeader.participationUniversityName",
    "ProjectLeader.name",
    "SrcAccessCategoryTags.businessEntity",
    "SrcAccessCategoryTags.thrust",
    "SrcAccessCategoryTags.subthrust",
    "SrcAccessCategoryTags.center",
    "SrcAccessCategoryTags.theme",
    "SrcAccessCategoryTags.area",
    "SrcAccessCategoryTags.seismicshift",
    "DatasetAuthorLists.authorListWithAffiliation",
  ],
  segments: [
    "Datasets.onlyDataset",
    "SrcAccessCategoryTags.notDeleted",
    "DatasetAuthorLists.notDeleted",
    "ProjectLeader.notDeleted",
  ],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Datasets.id",
    label: "Dataset ID",
  },
  {
    dimension: "Datasets.category",
    label: "Dataset Category",
  },
  {
    dimension: "Datasets.name",
    label: "Dataset Name",
  },
  {
    dimension: "Projects.businessId",
    label: "Project ID",
  },
  {
    dimension: "ProjectLeader.participationUniversityName",
    label: "Project University",
  },
  {
    dimension: "Projects.endedStatus",
    label: "Status",
    defaultValue: "Active",
  },
  {
    dimension: "DatasetTask.exists",
    label: "Satisfies a task",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Dataset ID",
    key: "Datasets.id",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Dataset Category",
    key: "Datasets.category",
    type: ReportColumnType.Text,
  },
  {
    title: "Dataset Name",
    key: "Datasets.name",
    type: ReportColumnType.Link,
    linkAccessor: (row) => ({
      name: "dataset",
      params: { id: row["Datasets.id"].toString() },
    }),
  },
  {
    title: "Project ID",
    key: "Projects.businessId",
    type: ReportColumnType.Text,
    width: 110,
    freeze: "Left",
  },
  {
    title: "Created Date",
    key: "Datasets.createdAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Author",
    key: "DatasetAuthorLists.authorListWithAffiliation",
    type: ReportColumnType.Text,
  },
  {
    title: "Business Entity",
    key: "SrcAccessCategoryTags.businessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "SrcAccessCategoryTags.center",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "SrcAccessCategoryTags.theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "SrcAccessCategoryTags.thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "SrcAccessCategoryTags.subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Science Area",
    key: "SrcAccessCategoryTags.area",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "SrcAccessCategoryTags.seismicshift",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Leader",
    key: "ProjectLeader.name",
    type: ReportColumnType.Text,
  },
  {
    title: "Project University",
    key: "ProjectLeader.participationUniversityName",
    type: ReportColumnType.Text,
  },
  {
    title: "Satisfies a Task",
    key: "DatasetTask.exists",
    type: ReportColumnType.Text,
  },
];

export const DataListReportConfig: ReportConfiguration = {
  name: "Datasets (351M)",
  section: "Dataset",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.Moment,
  timeDimension: "Datasets.createdAt",
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Bottom,
};
