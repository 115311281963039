import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass, responseToClass } from "@/api/lib";
import DatasetCategory from "@/models/DatasetCategory";
import { AxiosPromise } from "axios";
import CreateViewModel from "@/forms/models/organization/datasetCategory/CreateViewModel";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";
import { LimitableResults } from "@/api/limitable";

export type DatasetCategoryListOptions = LimitableResults;
export function list(
  organizationId: number,
  params: DatasetCategoryListOptions
): AxiosPromise<PaginationResponse<DatasetCategory[]>> {
  return $http
    .get(`${prefix}/organizations/${organizationId}/dataset-categories`, {
      params
    })
    .then(response => paginatedResponseToClass(DatasetCategory, response));
}

export function create(
  organizationId: number,
  data: CreateViewModel
): AxiosPromise<DatasetCategory> {
  return $http
    .post(`${prefix}/organizations/${organizationId}/dataset-categories`, data)
    .then(response => responseToClass(DatasetCategory, response));
}
