import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import AccessCategory from "@/models/AccessCategory";
import { AxiosPromise } from "axios";
import CreateViewModel from "@/forms/models/organization/accessCategory/CreateViewModel";

export function update(
  accessCategoryId: number,
  data: CreateViewModel
): AxiosPromise<AccessCategory> {
  return $http
    .put(`${prefix}/accessCategories/${accessCategoryId}`, data)
    .then(response => responseToClass(AccessCategory, response));
}

export function remove(accessCategoryId: number): AxiosPromise<AccessCategory> {
  return $http
    .delete(`${prefix}/accessCategories/${accessCategoryId}`)
    .then(response => responseToClass(AccessCategory, response));
}

export function archive(
  accessCategoryId: number
): AxiosPromise<AccessCategory> {
  return $http
    .post(`${prefix}/accessCategories/${accessCategoryId}/archive`)
    .then(response => responseToClass(AccessCategory, response));
}

export function restore(
  accessCategoryId: number
): AxiosPromise<AccessCategory> {
  return $http
    .post(`${prefix}/accessCategories/${accessCategoryId}/restore`)
    .then(response => responseToClass(AccessCategory, response));
}
