import { UserRolePivot } from "@/models/UserRolePivot";
import { TeamRolesEnum } from "@/staticCollections/teamRoles";

export function isTeamUserPivot(teamUser: unknown): teamUser is TeamUser {
  if (typeof teamUser !== "object") {
    return false;
  }

  return Object.prototype.hasOwnProperty.call(teamUser, "position");
}

export class TeamUser implements UserRolePivot<TeamRolesEnum> {
  public role: TeamRolesEnum;
  public position: string;
  public created_at: string;
  public updated_at: string;
}
