import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree
} from "vuex";
import Vue from "vue";
import { RootState } from "@/store";
import * as _ from "lodash";
import { ApiClient } from "@/api";
import { EDefinitionTable } from "@/api/namespaces/options";

export type TypeDefinition = EDefinitionTable;

export type DefinitionsState = {
  [index in TypeDefinition]?: DefTableRecord[];
};

export type DefTableRecord = {
  code: string;
  label: string;
  duration?: number;
};

enum MutationTypes {
  UpdateDefinitionTable = "updateDefinitionTable"
}

const state: DefinitionsState = {};

const getters: GetterTree<DefinitionsState, RootState> = {};

const actions: ActionTree<DefinitionsState, RootState> = {
  // Add error handling
  async getDefinitionTable(
    { commit, state }: ActionContext<DefinitionsState, RootState>,
    defTableName: TypeDefinition
  ) {
    if (!_.has(state, defTableName)) {
      const response = await ApiClient.Options.availableOptions(defTableName);

      commit(MutationTypes.UpdateDefinitionTable, {
        type: defTableName,
        data: response.data
      });
    }

    return state[defTableName];
  }
};

const mutations: MutationTree<DefinitionsState> = {
  [MutationTypes.UpdateDefinitionTable](
    state: DefinitionsState,
    table: { type: TypeDefinition; data: DefTableRecord[] }
  ) {
    Vue.set(state, table.type, table.data);
  }
};

export const definitions: Module<DefinitionsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
