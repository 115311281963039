import Team from "@/models/Team";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { RootState } from "@/store";
import { TeamRolesEnum } from "@/staticCollections/teamRoles";
import { TeamPermissions } from "@/staticCollections/teamPermissions";

export enum MutationTypes {
  SetTeam = "SetTeam",
}

export interface ITeamState {
  team: Team | null;
}

const state: ITeamState = {
  team: null,
};

const getters: GetterTree<ITeamState, RootState> = {
  team: (state: ITeamState): Team | null => state.team,
  hasAccessToTeamSettingsPage: (state: ITeamState) =>
    state.team &&
    state.team.pivot &&
    [TeamRolesEnum.Admin].includes(state.team.pivot.role),
};

const actions: ActionTree<ITeamState, RootState> = {
  async setTeam(
    { commit, state }: ActionContext<ITeamState, RootState>,
    team: Team
  ) {
    commit(MutationTypes.SetTeam, team);
  },
};

const mutations: MutationTree<ITeamState> = {
  [MutationTypes.SetTeam](state: ITeamState, team: Team) {
    state.team = team;
  },
};

export const team: Module<ITeamState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
