import ModelInterface from "@/models/IModel";
import TimestampsInterface from "@/models/TimestampsInterface";

export interface IActivity extends ModelInterface, TimestampsInterface {
  app: string;
  client_ip: string;
  client_ua: string;
  id: number;
  options: Record<string, string>;
  route: string;
  url: string;
}

export default class Activity implements IActivity {
  public id: number;
  public app: string;
  public client_ip: string;
  public client_ua: string;
  public options: Record<string, string>;
  public route: string;
  public url: string;
  public created_at: string;
  public updated_at: string;
}
