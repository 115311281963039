import $http from "@/services/Http";
import { responseToClass } from "@/api/lib";
import User from "@/models/User";
import { AxiosPromise } from "axios";
import { prefix } from "@/api/config";
import { Credentials } from "@/services/Auth";

export function me(): AxiosPromise<User> {
  return $http
    .get(`${prefix}/auth/me`)
    .then((response) => responseToClass(User, response));
}

export function verifyCredentials(
  credentials: Credentials
): AxiosPromise<{ valid: boolean }> {
  return $http.post(`${prefix}/auth/verify-credentials`, credentials);
}
