


















import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import _ from "lodash";
import { ApiGeneratedClient } from "@/api";
import OpenalexConcept from "@/models/OpenalexConcept";

const handleSearchDebounced = _.debounce(
  async (query: string, context: InterestsAutocomplete) => {
    if (!query || query.length === 0) {
      return;
    }
    context.isFetching = true;
    const response = await ApiGeneratedClient.openAlexProxyConceptsAutocomplete(
      { q: query }
    );
    const filteredResponse = response.data.results.filter(
      (concept) => !context.value.some((selected) => selected.id === concept.id)
    );

    context.conceptAutocompleteList.length = 0;
    context.conceptAutocompleteList.push(...filteredResponse);
    context.isFetching = false;
  },
  300
);

@Component
export default class InterestsAutocomplete extends Vue {
  @Prop({ default: null }) value!: OpenalexConcept[];
  @Prop({ default: false }) disabled!: false;

  isFetching = false;
  conceptAutocompleteList: OpenalexConcept[] = [];

  handleSearch(query: string): void {
    handleSearchDebounced(query, this);
  }

  conceptsChanged(concepts: OpenalexConcept[]): void {
    this.value = concepts;
    this.conceptAutocompleteList.length = 0;
    this.$emit("input", this.value.slice(0));
  }
}
