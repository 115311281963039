import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/ai",
    name: "demo",
    component: () =>
      import(/* webpackChunkName: "route.demo" */ "../views/Demo.vue"),
    meta: {
      auth: true,
      breadcrumbs: "Demo",
    },
    children: [
      {
        path: "talents",
        name: "demo_talents",
        component: () =>
          import(
            /* webpackChunkName: "route.demo.talents" */ "../views/DemoTalents.vue"
          ),
        meta: {
          auth: true,
          breadcrumbs: "Talents",
        },
      },
      {
        path: "experts",
        name: "demo_experts",
        component: () =>
          import(
            /* webpackChunkName: "route.demo.experts" */ "../views/DemoExperts.vue"
          ),
        meta: {
          auth: true,
          breadcrumbs: "Experts",
        },
      },
      {
        path: "contacts",
        name: "demo_contacts",
        component: () =>
          import(
            /* webpackChunkName: "route.demo.experts" */ "../views/DemoContacts.vue"
          ),
        meta: {
          auth: true,
          breadcrumbs: "Contacts",
        },
      },
    ],
  },
  {
    path: "/university-dashboard",
    name: "demo.university-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "route.demo.university-dashboard" */ "../views/DemoUniversityDashboard.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/university-dashboard/companies/:id",
    name: "demo.university-dashboard.company",
    component: () =>
      import(
        /* webpackChunkName: "route.demo.university-dashboard.company" */ "../views/DemoUniversityDashboardCompany.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/company-dashboard",
    name: "demo.company-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "route.demo.company-dashboard" */ "../views/DemoCompanyDashboard.vue"
      ),
    meta: {
      auth: true,
    },
  },
];

export default routes;
