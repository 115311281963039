import { ApiGeneratedPublicClient } from "@/api/index";
import { Singleton } from "@/utils/Singleton";
import { FrontendConfiguration } from "@/apiClientGenerated";

const defaultValues = {
  homeRoute: "news",
};

export const configuration: Singleton<Promise<FrontendConfiguration>> =
  new Singleton(() =>
    ApiGeneratedPublicClient.getClientConfiguration().then((response) =>
      Object.assign({}, defaultValues, response.data)
    )
  );
