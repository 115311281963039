import $http from "@/services/Http";
import { responseToClass } from "@/api/lib";
import Task from "@/models/Task";
import { prefix } from "@/api/config";
import { AxiosPromise } from "axios";
import User from "@/models/User";
import CreateViewModel from "@/forms/models/project/task/CreateViewModel";

export function list(
  projectId: number,
  showCompleted = false
): AxiosPromise<Task[]> {
  return $http
    .get(`${prefix}/projects/${projectId}/tasks`, { params: { showCompleted } })
    .then(response => responseToClass(Task, response));
}

export function create(
  projectId: number,
  data: CreateViewModel
): AxiosPromise<Task> {
  return $http
    .post(`${prefix}/projects/${projectId}/tasks`, data)
    .then(response => responseToClass(Task, response));
}

export function autocompleteAssignees(
  projectId: number,
  query = ""
): AxiosPromise<User[]> {
  return $http
    .get(`${prefix}/projects/${projectId}/autocomplete-task-assignees`, {
      params: { query }
    })
    .then(response => responseToClass(User, response));
}
