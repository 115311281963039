import IResource from "@/components/report/models/common/IResource";
import IName from "@/components/report/models/common/IName";
import { year_month } from "@/plugins/moment";
import Formation from "@/models/Formation";
import UpdateFormRequest from "@/forms/requests/degree/UpdateFormRequest";

export function formal(resource: IResource & IName): string {
  return `${resource.name} (ID=${resource.id})`;
}

export function formattedFormation(
  formation: Formation | UpdateFormRequest
): string {
  const fields = [
    formation.degree,
    formation.degree_field,
    formation.university
  ];

  if (!formation || fields.some(f => !f) || !formation.degree_field?.label) {
    return "";
  }

  const format: string[] = [
    formation.degree_field?.label || "",
    formation.university?.name || "",
    formation.university?.country || "",
    formation.ends_at ? year_month(formation.ends_at) || "" : ""
  ].filter(s => s);

  return `${formation.degree.label} in ${format.join(", ")}`;
}
