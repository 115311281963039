import "reflect-metadata";
import "vue-class-component/hooks";
import "@/globalImports/class-component-hooks";
import "@/globalImports/vue-router-hooks";
import Vue from "vue";
import App from "./App.vue";
import Maintenance from "./Maintenance.vue";
import router from "@/router";
import store from "./store";
import Plugins from "@/plugins/index";
import Buefy from "buefy";
import Vuelidate from "vuelidate";
import i18n from "./i18n";
// import '@/globalImports/froala.ts';
import "./toast-ui-calendar-dst-fix.ts";
import "sweetalert2/src/sweetalert2.scss";
import { buefyConfigs } from "@/config/buefy";
//import "froala-editor/css/froala_style.min.css";
import { ApplicationEvents } from "@/events/ApplicationEvents";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import { authenticationPromise } from "@/services/Auth";
import AppNavbar from "@/navbars/AppNavbar.vue";
import amplitude from "@/services/amplitude";

const mountApp = (el: Element | string) => {
  Vue.use(GridPlugin);

  Vue.config.productionTip = false;

  if (!["/callback"].includes(window.location.pathname)) {
    // /callback is a special route that needs to do its own business
    // on its own. We will not call initialize and let the route
    // handle itself
    authenticationPromise
      .getInstance()
      .then((instance) => instance.initialize());
  }

  Vue.use(Plugins);
  Vue.use(Buefy, buefyConfigs);
  Vue.use(Vuelidate);

  const isMaintenance = [1, "1", "true", true].includes(
    process.env.MAINTENANCE || false
  );

  const renderPage = isMaintenance ? Maintenance : App;

  new Vue({
    i18n,
    router,
    store,
    mounted() {
      this.$bus.$emit(ApplicationEvents.ApplicationReady);
    },
    render: (h) => h(renderPage),
  }).$mount(el);

  import("@/initialized"); // lazy loaded
};

if (process.env.NODE_ENV === "development") {
  const devRoot = document.querySelector("#app");
  if (devRoot) {
    mount(devRoot);
  }
}

const mountNavbar = (el) => {
  Vue.config.productionTip = false;

  if (!["/callback"].includes(window.location.pathname)) {
    // /callback is a special route that needs to do its own business
    // on its own. We will not call initialize and let the route
    // handle itself
    authenticationPromise
      .getInstance()
      .then((instance) => instance.initialize());
  }

  Vue.use(Plugins);
  Vue.use(Buefy, buefyConfigs);

  const isMaintenance = [1, "1", "true", true].includes(
    process.env.MAINTENANCE || false
  );

  const renderPage = isMaintenance ? null : AppNavbar;

  new Vue({
    i18n,
    router,
    store,
    mounted() {
      this.$bus.$emit(ApplicationEvents.ApplicationReady);
    },
    render: (h) => h(renderPage),
  }).$mount(el);

  import("@/initialized"); // lazy loaded
};

export { mountApp, mountNavbar, store, authenticationPromise, amplitude };
