




























import SpecialNavbar from "@/navbars/SpecialNavbar.vue";
import Helper from "../services/errorHelper";
import Component from "vue-class-component";
import Vue from "vue";
import { RawLocation, Route } from "vue-router";
import { configuration } from "@/config/dynamic";

@Component({
  components: {
    SpecialNavbar
  }
})
export default class Forbidden extends Vue {
  homeRoute = "news";
  mounted(): void {
    configuration.getInstance().then(({ homeRoute }) => {
      this.homeRoute = homeRoute || "news";
    });
  }

  beforeRouteLeave(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
  ) {
    Helper.clear();

    next();
  }
}
