import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType,
} from "@/reports/ReportModel";
import {
  ProjectLinkAccessor,
  TaskLinkAccessor,
  userLinkAccessorBuilder,
} from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: [],
  order: {
    "Tasks.createdAt": "desc",
  },
  dimensions: [
    "Tasks.id",
    "Tasks.name",
    "Tasks.description",
    "Tasks.status",
    "Users.name",
    "Users.universityName",
    "Users.id",
    "Tasks.dueAt",
    "Tasks.completedAt",
    "Tasks.isFileRequiredForCompletion",
    "Projects.id",
    "Projects.name",
    "SrcAccessCategoryTags.businessEntity",
    "SrcAccessCategoryTags.seismicshift",
    "SrcAccessCategoryTags.thrust",
    "SrcAccessCategoryTags.subthrust",
    "SrcAccessCategoryTags.theme",
    "SrcAccessCategoryTags.center",
    "SrcAccessCategoryTags.area",
    "DatasetTask.id",
    "Datasets.id",
    "Datasets.name",
    "Datasets.category",
  ],
  segments: [
    "Tasks.notDeleted",
    "Users.notDeleted",
    "Projects.notDeleted",
    "SrcAccessCategoryTags.notDeleted",
    "Datasets.notDeleted",
  ],
  ungrouped: true,
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Tasks.name",
    label: "Task Name",
  },
  {
    dimension: "Users.name",
    label: "Assignee",
  },
  {
    dimension: "Tasks.status",
    label: "Task Status",
  },
  {
    dimension: "Projects.businessId",
    label: "Project Id",
  },
  {
    dimension: "Projects.name",
    label: "Project Name",
  },
  {
    dimension: "Teams.name",
    label: "Team",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Task Name",
    key: "Tasks.name",
    type: ReportColumnType.Link,
    linkAccessor: TaskLinkAccessor,
    width: 200,
  },
  {
    title: "Task Description",
    key: "Tasks.description",
    type: ReportColumnType.Text,
  },
  {
    title: "Task Status",
    key: "Tasks.status",
    type: ReportColumnType.Text,
  },
  {
    title: "Assignee",
    key: "Users.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder(),
  },
  {
    title: "University",
    key: "Users.universityName",
    type: ReportColumnType.Text,
  },
  {
    title: "Due Date",
    key: "Tasks.dueAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Completed Date",
    key: "Tasks.completedAt",
    type: ReportColumnType.Date,
  },
  {
    title: "File Required",
    key: "Tasks.isFileRequiredForCompletion",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Name",
    key: "Projects.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    width: 200,
  },
  {
    title: "Business Entity",
    key: "SrcAccessCategoryTags.businessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "SrcAccessCategoryTags.seismicshift",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "SrcAccessCategoryTags.thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "SrcAccessCategoryTags.subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "SrcAccessCategoryTags.theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "SrcAccessCategoryTags.center",
    type: ReportColumnType.Text,
  },
  {
    title: "Area",
    key: "SrcAccessCategoryTags.area",
    type: ReportColumnType.Text,
  },
  {
    title: "Dataset Id",
    key: "Datasets.id",
    type: ReportColumnType.Text,
  },
  {
    title: "Dataset Name",
    key: "Datasets.name",
    type: ReportColumnType.Link,
    linkAccessor: (row) => ({
      name: "dataset",
      params: { id: row["Datasets.id"].toString() },
    }),
  },
  {
    title: "Dataset Category",
    key: "Datasets.category",
    type: ReportColumnType.Text,
  },
];

export const TSK203AllTasks: ReportConfiguration = {
  name: "Tasks (501)",
  section: "Task",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Tasks.dueAt",
    endsAt: "Tasks.dueAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
};
