import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import { AxiosPromise } from "axios";
import LicenseAgreement from "@/models/LicenseAgreement";

export function latest(): AxiosPromise<LicenseAgreement | null> {
  return $http
    .get(`${prefix}/license-agreements/latest`)
    .then((response) => responseToClass(LicenseAgreement, response));
}

export function accept(marketingNotification: boolean): AxiosPromise<void> {
  return $http.post(`${prefix}/license-agreements/accept`, {
    marketingNotification: marketingNotification,
  });
}
