import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import Team from "@/models/Team";

export function list(projectId: number) {
  return $http
    .get(`${prefix}/projects/${projectId}/customers`)
    .then(response => responseToClass(Team, response));
}
