import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import $http from "@/services/Http";
import { downloadResponse } from "@/utils/Download";
import File from "@/models/File";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import FileService from "@/services/fileService";

export enum MutationTypes {
  StartDownload = "StartDownload",
  UpdateProgress = "UpdateProgress",
  ClearDownloadHistory = "ClearDownloadHistory"
}

export interface DownloadProgress {
  id: symbol;
  started_at: Date;
  name: string;
  progress: number;
}

export interface IDownloadState {
  downloads: DownloadProgress[];
}

const state: IDownloadState = {
  downloads: []
};

const getters: GetterTree<IDownloadState, RootState> = {
  activeDownloads: (state): DownloadProgress[] =>
    state.downloads.filter(d => d.progress < 100),
  completedDownloads: (state): DownloadProgress[] =>
    state.downloads.filter(d => d.progress === 100),
  recentDownloads: (state): DownloadProgress[] =>
    state.downloads
      .slice(0)
      .sort((a, b) => {
        const diffProgress = b.progress - a.progress;

        if (diffProgress !== 0) {
          return diffProgress;
        }

        return b.started_at.getTime() - a.started_at.getTime();
      })
      .slice(0, 8)
};

type ApiDownloadCallback = (config: AxiosRequestConfig) => AxiosPromise;
export interface ApiDownloadPayload {
  callback: ApiDownloadCallback;
  name: string;
}

const actions: ActionTree<IDownloadState, RootState> = {
  queueFileForDownload({ commit, state, dispatch }, file: File) {
    new FileService(file).resolveUrl().then(url => {
      dispatch("queueForDownload", { url, name: file.name });
    });
  },
  queueForDownload(
    { commit, state },
    {
      url,
      name,
      config = {}
    }: { url: string; name: string; config: AxiosRequestConfig }
  ) {
    const id = Symbol();
    commit(MutationTypes.StartDownload, {
      id,
      name,
      started_at: new Date(),
      progress: 0
    });

    //Force no-cache for download via XHR - Bug #787
    if (typeof config.headers !== "undefined") {
      config.headers["Cache-Control"] = "no-cache";
    } else {
      config = {
        headers: {
          "Cache-Control": "no-cache"
        }
      };
    }

    $http
      .get(
        url,
        Object.assign(
          {
            responseType: "blob",
            onDownloadProgress: (event: ProgressEvent) => {
              if (event.type !== "progress") {
                return;
              }

              commit(MutationTypes.UpdateProgress, {
                id,
                progress: (event.loaded / event.total) * 100
              });
            }
          },
          config
        )
      )
      .then(response => {
        const match = name.match(/\.(\w+)\?/) || [];
        downloadResponse(
          response,
          name,
          match[1] || null //Force extension to be the same as the one found in the url
        );

        return response;
      });
  },
  queueApiCallForDownload(
    { commit, state },
    { callback, name }: ApiDownloadPayload
  ) {
    const id = Symbol();
    commit(MutationTypes.StartDownload, {
      id,
      name,
      started_at: new Date(),
      progress: 0
    });

    callback({
      responseType: "blob",
      onDownloadProgress: (event: ProgressEvent) => {
        if (event.type !== "progress") {
          return;
        }

        commit(MutationTypes.UpdateProgress, {
          id,
          progress: (event.loaded / event.total) * 100
        });
      }
    }).then(response => {
      downloadResponse(response, name);

      return response;
    });
  }
};

const mutations: MutationTree<IDownloadState> = {
  [MutationTypes.StartDownload](state, payload: DownloadProgress) {
    state.downloads.push(payload);
  },
  [MutationTypes.UpdateProgress](
    state,
    payload: Exclude<DownloadProgress, "name" | "started_at">
  ) {
    const download = state.downloads.find(d => d.id === payload.id);

    if (!download) {
      return;
    }

    download.progress = payload.progress;
  },
  [MutationTypes.ClearDownloadHistory](state) {
    state.downloads = state.downloads.filter(d => d.progress < 100);
  }
};

export const downloads: Module<IDownloadState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
