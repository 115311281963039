import { CompareResult } from "@/utils/Sort";

export function spaceship<T>(a?: T, b?: T, nullFirst = true): CompareResult {
  if (a === b) {
    return 0;
  }

  if (a === null || a === undefined) {
    return nullFirst ? -1 : 1;
  }

  if (b === null || b === undefined) {
    return nullFirst ? 1 : -1;
  }

  if (typeof a === "string") {
    return spaceship(a.localeCompare(String(b)), 0);
  } else {
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    }

    return 0;
  }
}
