export default class Dict<
  K extends string | number = string | number,
  V = unknown
> {
  public data: Record<K, V>;

  constructor(data: Record<K, V>) {
    this.data = data;
  }

  public all(): V[] {
    return Object.values(this.data);
  }

  public get(key: K): V {
    return this.data[key];
  }

  public getKeys(): string[] {
    return Object.keys(this.data);
  }
}
