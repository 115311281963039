import { Type } from "class-transformer";
import User from "@/models/User";
import Project from "@/models/Project";
import ITimestamps from "@/models/TimestampsInterface";
import moment from "moment";
import NoteVersion from "@/models/NoteVersion";
import IDisplayName from "@/models/DisplayNameInterface";

export default class Note implements ITimestamps, IDisplayName {
  public id: number;
  public title: string;
  @Type(() => User)
  public author: User;
  @Type(() => Project)
  public project: Project;
  public content: string;
  public deleted_at?: string;
  public versions_count: number;
  @Type(() => NoteVersion)
  public versions: NoteVersion[];
  public created_at?: string;
  public updated_at?: string;
  public work_done_on?: string;
  @Type(() => NoteVersion)
  public current_version: NoteVersion;

  public get isDeleted() {
    if (typeof this.deleted_at === "undefined") {
      return false;
    }

    return this.deleted_at !== null;
  }

  public get isRecentlyCreated(): boolean {
    const lastMinute = moment().subtract(1, "minute");

    return moment(this.created_at).isAfter(lastMinute);
  }

  getDisplayName(): string {
    return this.title;
  }
}
