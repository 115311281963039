import Dict from "./dict";
import { IItemRole } from "@/staticCollections/index";

export enum TeamRolesEnum {
  User = "user",
  Admin = "admin",
}

const items: Record<TeamRolesEnum, IItemRole> = {
  [TeamRolesEnum.User]: {
    order: 1,
    value: TeamRolesEnum.User,
    translationKey: "staticCollections.userTeam.role.user",
  },
  [TeamRolesEnum.Admin]: {
    order: 2,
    value: TeamRolesEnum.Admin,
    translationKey: "staticCollections.userTeam.role.admin",
  },
};

export default new Dict<TeamRolesEnum, IItemRole>(items);
