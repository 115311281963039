import { DirectiveBinding } from "vue/types/options";

/**
 * Replace the el element (element with the directive) by the
 * binding content (directive value). The tag used is irrelevant.
 */
const svg = {
  inserted: (el: HTMLElement, binding: DirectiveBinding): void => {
    if (typeof binding.value === "string") {
      const doc = new DOMParser().parseFromString(binding.value, "text/xml");
      const element = doc.firstElementChild;

      if (!element) {
        return;
      }

      const path = element.querySelector("path");

      if (path) {
        path.setAttribute("fill", "currentColor");
      }

      element.setAttribute("class", el.getAttribute("class") || "");
      element.setAttribute("style", el.getAttribute("style") || "");

      el.replaceWith(element);
    } else {
      el.insertAdjacentElement("afterbegin", binding.value);
    }
  }
};

export default svg;
