import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
} from "@/reports/ReportModel";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  order: [["Users.email", "asc"]],
  dimensions: ["Users.email", "Users.position"],
  segments: ["Users.notDeleted"],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Users.position",
    label: "Position",
    multiple: true,
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Email",
    key: "Users.email",
    type: ReportColumnType.Text,
  },
  {
    title: "Position",
    key: "Users.position",
    type: ReportColumnType.Text,
  },
];

export const UsersMailingList: ReportConfiguration = {
  name: "Mailing List",
  section: "Users",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: false,
  sortColumns: extractSortColumnsFromQuery(query),
};
