import _Vue from "vue";

export function yesno(value: any): string {
  return value ? "yes" : "no";
}

export default {
  install(Vue: typeof _Vue, options: any) {
    Vue.mixin({
      filters: {
        yesno
      }
    });
  }
};
