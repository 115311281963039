import ITimestamps from "@/models/TimestampsInterface";
import { TeamRolesEnum } from "@/staticCollections/teamRoles";
import { TeamPermissions } from "@/staticCollections/teamPermissions";
import { Type } from "class-transformer";
import User from "@/models/User";
import Project from "@/models/Project";
import AccessCategory from "@/models/AccessCategory";
import { TeamUser } from "@/models/TeamUser";
import Organization from "@/models/Organization";

export enum AmplitudeTeamType {
  Academic = "academic",
  Industry = "industry",
  Other = "other",
}

export default class Team implements ITimestamps {
  public id: number;
  public hash: string;
  public name: string;
  public description = "";
  public restrict_team_method_editing: boolean;
  public allow_external_sharing: boolean;
  public allow_create_project: boolean;
  @Type(() => TeamUser)
  public pivot?: TeamUser;
  public permissions: TeamPermissions[] = [];
  @Type(() => User)
  public users: User[] = [];
  public users_count = 0;
  public amplitude_team_type?: AmplitudeTeamType;
  public archived_at?: string;
  public amplitude_paying_client?: string;
  @Type(() => AccessCategory)
  public access_categories: AccessCategory[] = [];
  @Type(() => Project)
  public projects: Project[] = [];
  public active_projects_count = 0;
  @Type(() => Organization)
  public organization?: Organization;

  get admins(): User[] {
    return this.users.filter(
      (user) => user.pivot && user.pivot.role === TeamRolesEnum.Admin
    );
  }

  get members(): User[] {
    return this.users.filter(
      (user) => user.pivot && user.pivot.role === TeamRolesEnum.User
    );
  }

  public hasPermission(permission: TeamPermissions): boolean {
    if (!this.permissions) {
      return false;
    }

    return this.permissions.includes(permission);
  }
}
