import { AxiosResponse } from "axios";
import asyncPool from "tiny-async-pool";
import { parseIntOrDefault } from "@/utils/Number";

/** @deprecated */
export function downloadResponse(
  response: AxiosResponse,
  name: string,
  extension: string | null
) {
  download(
    response.data,
    extension ? name.replace(/\.[0-9a-zA-Z]+$/, "." + extension) : name
  );
}

export const download = (url: string, name: string, createObjectUrl = true) => {
  const a = document.createElement("a");
  a.setAttribute("download", name);
  let objectUrl;
  if (createObjectUrl) {
    objectUrl = window.URL.createObjectURL(url);
    a.href = objectUrl;
  } else {
    a.href = url;
  }
  a.download = name;
  document.body.append(a);
  a.click();
  a.remove();

  if (createObjectUrl && objectUrl) {
    window.URL.revokeObjectURL(objectUrl);
  }
};

/**
 * Configured async pool specifically for data upload
 * for users.
 *
 * @param array
 * @param iteratorFn
 */
export function simultaneousUploads<IN, OUT>(
  array: ReadonlyArray<IN>,
  iteratorFn: (generator: IN) => Promise<OUT>
): Promise<OUT[]> {
  return asyncPool(
    parseIntOrDefault(process.env.FILE_UPLOAD_POOL_SIZE, 1),
    array,
    iteratorFn
  );
}
