export default {
  components: {
    inviteUser: {
      autocomplete: {
        placeholder: "Enter name or email of collaborator",
      },
    },
  },
  permissions: {
    "user-team-management": "Manage users and teams",
    "debug-tools": "Gives access to admin debug tools",
    "ignore-when-tracking":
      "Ignore this user from tracking (Amplitude, GA, etc)",
  },
  breadcrumb: {
    home: {
      label: "Home",
      projects: {
        label: "Projects",
        create: {
          label: "Create project",
        },
      },
    },
    datasets: {
      label: "Datasets",
    },
    tasks: {
      label: "Tasks",
    },
    methods: {
      label: "Methods",
      create: {
        label: "Create methods",
      },
    },
    help: {
      label: "Help",
    },
  },
  vuelidate: {
    required: "{field} is required",
    email: "{field} is not a valid email format",
    minLength: "{field} should be at least {minLength} characters",
    maxLength: "{field} should not be more than {maxLength} characters",
    minLengthArray: "You should select at least {minLengthArray} {field}",
    maxLengthArray: "You cannot select more than {maxLength} {field}",
    sameAs: "{field} must match {target}",
    complexity:
      "{field} is too weak. Make sure you have a mix of lower case letters, upper case letters, numbers and symbols",
    url: "{field} must be a complete url like https://www.google.com/",
    alpha: "{field} should only contain alphabetical characters",
    regex: "{field} is invalid. Valid characters are {characters}",
    minValue: "{field} should be at least {minValue}",
    maxValue: "{field} should be at most {maxValue}",
    numeric: "{field} should be a numeric value",
  },
  form: {
    submit: {
      create: "Create",
      update: "Update",
    },
  },
  utils: {
    stepper: {
      back: "Back",
      continue: "Continue",
      complete: "Complete",
    },
  },
  index: {
    login: "Login",
    register: "register",
    home: "Home",
    title: "Pillar Science",
    subtitle: "Your All-In-One Research Data Solution",
  },
  login: {
    form: {
      email: {
        label: "Email",
        validation: {
          field: "The email",
        },
      },
      password: {
        label: "Password",
        validation: {
          field: "The password",
        },
      },
      toastr: {
        loginFailed: "Login failed",
      },
      actions: {
        login: "Login",
      },
      haveAccount: "Don't have account?",
      signup: "Signup",
      signinwith: "Sign in with",
    },
  },
  register: {
    form: {
      email: {
        label: "Your Email",
        validation: {
          field: "The email",
        },
      },
      password: {
        label: "Password",
        validation: {
          field: "The password",
        },
      },
      name: {
        label: "Full Name",
        validation: {
          field: "Full name",
        },
      },
      company: {
        label: "Institution/Company",
      },
      phone: {
        label: "Phone number",
      },
      toastr: {
        registerFailed: "Register failed",
      },
      actions: {
        register: "Register",
      },
      haveAccount: "Already have an account?",
      signin: "Sign in",
      signupwith: "Sign up with",
    },
  },
  forgotPassword: {
    form: {
      actions: {
        submit: {
          label: "Request password change",
        },
      },
    },
  },
  resetpassword: {
    form: {
      email: {
        label: "Your Email",
        validation: {
          field: "The email",
        },
      },
      password: {
        label: "Password",
        validation: {
          field: "The password",
        },
      },
      password_confirmation: {
        label: "Confirm Password",
        validation: {
          field: "The confirmation password",
          target: "the password",
        },
      },
      submit: "Change password",
      toastr: {
        resetFailed: "Password reset failed",
      },
    },
  },
  home: {
    title: "Home page",
    subtitle: "Projects",
  },
  appNavbar: {
    actions: {
      gettingStarted: {
        label: "Help",
      },
      projects: {
        label: "Projects",
      },
      main: {
        admin: "Admin",
        logout: "Logout",
      },
    },
  },
  staticCollections: {
    userTeam: {
      role: {
        admin: "Administrator",
        user: "Member",
      },
    },
    organization: {
      role: {
        admin: "Administrator",
        member: "Member",
      },
    },
    projectRoles: {
      role: {
        viewer: "Viewer",
        contributor: "Contributor",
        manager: "Manager",
      },
    },
  },
  projectTasks: {
    swal: {
      delete: {
        title: "Delete tasks",
        text: "Are you sure you want to delete this uncompleted task from your project?",
        confirm: "Delete",
        cancel: "Keep",
      },
    },
  },
  projectCreateWizard: {
    steps: {
      information: {
        title: "Information",
      },
      sharing: {
        title: "Sharing",
      },
      confirmation: {
        title: "Confirmation",
      },
    },
  },
  projectConfirmationStep: {
    fields: {
      name: {
        label: "Project name",
      },
      team: {
        label: "Team",
      },
      members: {
        label: "Members",
      },
    },
  },
  projects: {
    actions: {
      createProject: {
        label: "Create project",
      },
    },
  },
  projectCard: {
    updated: "Updated {time}",
    actions: {
      open: {
        label: "Open",
      },
      settings: {
        label: "Settings",
      },
    },
  },
  csvRenderer: {
    form: {
      name: {
        validation: {
          field: "The dataset name",
        },
      },
    },
  },
  xlsRenderer: {
    message:
      "XLS Excel files are deprecated since 2007 and cannot previewed. To update to the most recent Excel format, open your file in Microsoft Excel and save it as .xlsx.",
  },
  richTextRenderer: {
    actions: {
      save: {
        label: "Save",
      },
    },
    swal: {
      leaveWithoutSaving: {
        title: "Unsaved changes",
        text: "Do you wish to leave without saving your changes?",
        confirm: "Leave",
        cancel: "Stay on the page",
      },
    },
  },
  protocolForm: {
    messages: {
      inheritedProtocol:
        "You are viewing an inherited methods for this dataset. This method is bound to {boundDataset} dataset. Any changes made to this method will affect all child dataset of {parentDataset}.",
    },
    fields: {
      name: {
        label: "Name",
        validation: {
          required: {
            field: "The method name",
          },
        },
      },
      author: {
        label: "Author",
      },
      dataset: {
        label: "Target dataset",
      },
      content: {
        label: "Content",
      },
      team: {
        label: "Team",
        validation: {
          required: {
            field: "A team",
          },
        },
      },
    },
    actions: {
      create: {
        label: "Create",
      },
      update: {
        label: "Update",
      },
      detach: {
        label: "Detach",
      },
      back: {
        label: "Back",
      },
    },
    swal: {
      removeInherited: {
        title: "Removing inherited method",
        text: "This action will remove the method from a parent dataset ({protocolDataset}). This will affect all datasets that are located under this dataset including this one ({dataset}). Are you sure you want to do this action?",
        confirm: "OK",
        cancel: "Cancel",
      },
    },
  },
  datasetSidePanel: {
    tabs: {
      protocols: {
        label: "methods",
      },
      metadata: {
        label: "Metadata",
      },
    },
    actions: {
      addProtocol: "Add method",
      editMetadata: "Edit metadata",
    },
  },
  metadata: {
    table: {
      empty: "This dataset has no metadata",
      value: {
        empty: "No value",
      },
    },
    actions: {
      edit: {
        label: "Edit",
      },
      remove: {
        label: "Remove",
      },
      add: {
        label: "Add metadata",
      },
    },
    form: {
      key: {
        label: "Key",
        validation: {
          field: "The key",
        },
      },
      value: {
        label: "Value",
      },
    },
  },
  infoTab: {
    messages: {
      subtitle: "File information",
    },
  },
  metadataTab: {
    table: {
      empty: "This dataset does not have any metadata",
    },
    messages: {
      metadata: "Metadata",
      subtitle: "Add metadata tags to easily retrieve your data.",
      metadataEmpty: "No value",
    },
  },
  protocolsTab: {
    fields: {
      name: {
        label: "Name",
      },
      edit: {
        label: "Edit",
      },
      detach: {
        label: "Detach",
      },
    },
    tabs: {
      attach: {
        label: "Attach existing",
      },
      new: {
        label: "New",
      },
    },
    messages: {
      methods: "Methods",
      from: "from",
      subtitle: "Add the methology used to create the data",
    },
  },
  search: {
    messages: {
      searchEverywhere: "Search everywhere",
      noResults: "No results",
      unknownResource: "Unknown resource type",
    },
  },
  datasetRenderer: {
    actions: {
      download: {
        label: "Download",
      },
      upload: {
        label: "Upload new version",
      },
      save: {
        label: "Save",
      },
    },
    toast: {
      nameTaken: {
        message: "Dataset name already taken. Please use another name",
      },
      genericSaveError: {
        message: "An unknown error occurred while saving",
      },
    },
  },
  datasetResultType: {
    messages: {
      updated: "Updated",
      created: "Created at",
    },
  },
  protocolResultType: {
    messages: {
      updated: "Updated",
      created: "Created at",
    },
  },
  projectOverview: {
    fields: {
      description: {
        label: "Project description",
        emptyEditable: "Click here to set a description.",
        empty: "No description available",
      },
    },
    actions: {
      save: "Save",
      cancel: "Cancel",
    },
  },
  protocolFormInline: {
    fields: {
      name: {
        label: "Name",
        placeholder: "Type in your method name",
        validation: {
          required: {
            field: "",
          },
        },
      },
    },
    actions: {
      save: "Create method",
    },
  },
  protocolLookup: {
    fields: {
      query: {
        label: "Search for methods",
        placeholder: "Search for methods",
      },
      results: {
        label: "Search results",
        empty: "No methods found",
        fields: {
          name: {
            label: "Name",
          },
          attach: {
            label: "Attach",
          },
        },
      },
    },
  },
  protocol: {
    fields: {
      name: {
        label: "Name",
      },
      last_update: {
        label: "Last updated",
      },
      author: {
        label: "Author",
      },
      dataset: {
        label: "Target dataset",
      },
      content: {
        label: "Content",
      },
    },
  },
  projectSharing: {
    results: {
      fields: {
        name: {
          label: "Name",
        },
        role: {
          label: "Role",
        },
        shared: {
          label: "Shared Since",
        },
        remove: {
          label: "Remove",
        },
      },
    },
    actions: {
      add: {
        label: "Add",
      },
    },
    swal: {
      confirmSelf: {
        title: "Remove yourself",
        text: "Removing yourself will cause you to have no more access to this project. Do you wish to continue?",
        confirmButtonText: "Yes, remove me",
        cancelButtonText: "No, I still need access",
      },
    },
  },
  help: {
    categories: {
      general: {
        label: "General",
        items: {
          gettingStarted: {
            label: "Getting Started",
          },
        },
      },
      metadata: {
        label: "Metadata",
        items: {
          metadata: {
            label: "Metadata",
          },
        },
      },
    },
  },
  searchPage: {
    categories: {
      datasets: "Datasets",
      protocols: "Protocols",
      projects: "Projects",
    },
  },
  newFileForm: {
    form: {
      name: {
        label: "Name",
        validation: {
          field: "The name",
        },
      },
    },
  },
  protocols: {
    table: {
      name: {
        label: "Name",
      },
      abstract: {
        label: "Abstract",
      },
      tags: {
        label: "Tags",
      },
      author: {
        label: "Author",
      },
    },
  },
};
