import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import { ResourceViewModes } from "@/models/enum/ResourceViewModes";

export enum MutationTypes {
  SetViewMode = "SetViewMode",
  UpdateAvailable = "UpdateAvailable",
  SetPreventReload = "SetPreventReload",
}

export interface ViewModeStates {
  project: ResourceViewModes;
}

export interface IApplicationState {
  viewMode: ViewModeStates;
  updateAvailable: boolean;
  preventReload: boolean;
}

const state: IApplicationState = {
  viewMode: {
    project: ResourceViewModes.Card,
  },
  updateAvailable: false,
  preventReload: false,
};

export interface ViewModePayload {
  resource: keyof ViewModeStates;
  mode: ResourceViewModes;
}

const getters: GetterTree<IApplicationState, RootState> = {
  getViewMode: (state) => (resource: keyof ViewModeStates) =>
    state.viewMode[resource],
};

const actions: ActionTree<IApplicationState, RootState> = {
  setViewMode({ commit, state, rootState }, payload: ViewModePayload): void {
    commit(MutationTypes.SetViewMode, payload);
  },
};

const mutations: MutationTree<IApplicationState> = {
  [MutationTypes.SetViewMode](state, { resource, mode }: ViewModePayload) {
    state.viewMode[resource] = mode;
  },
  [MutationTypes.UpdateAvailable](state) {
    state.updateAvailable = true;
  },
  [MutationTypes.SetPreventReload](state, value) {
    state.preventReload = value;
  },
};

export const application: Module<IApplicationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
