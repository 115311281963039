export enum MutationTypes {
  SetField = "SetField"
}

export default {
  [MutationTypes.SetField]: <TState>(
    state: TState,
    payload: { field: keyof TState; value: TState[keyof TState] }
  ): void => {
    state[payload.field] = payload.value;
  }
};
