import ITimestamps from "@/models/TimestampsInterface";
import User from "@/models/User";
import { RoleRelationshipInterface } from "@/interfaces/behavior/RoleRelationshipInterface";
import { Roles } from "@/staticCollections/projectRoles";
import { Type } from "class-transformer";
import Team from "@/models/Team";
import Dataset from "@/models/Dataset";
import AccessCategory from "@/models/AccessCategory";
import { ProjectUser } from "@/models/ProjectUser";
import ICountry from "@/models/Country";
import University from "@/models/University";
import ProjectParticipation from "@/models/ProjectParticipation";
import { IDisplayName } from "@/models/DisplayNameInterface";
import { TagResource } from "@/apiClientGenerated";

export default class Project
  implements ITimestamps, RoleRelationshipInterface<Roles>, IDisplayName
{
  public id: number;
  public hash: string;
  public internal_id: string | null = null;
  public name: string;
  public description: string;
  public pivot?: ProjectUser;
  public tags: TagResource[];
  @Type(() => User)
  public author: User;
  @Type(() => Team)
  public team: Team;
  @Type(() => User)
  public users: User[];
  @Type(() => User)
  public former_users: User[];
  @Type(() => Dataset)
  public directory?: Dataset;
  public created_at: string;
  public updated_at: string;
  public deleted_at: string;
  public tasks_count?: number;
  public completed_tasks_count?: number;
  @Type(() => University)
  public university_affiliation?: University;
  public overdue_tasks_count?: number;
  public archived_at: string | null = null;
  @Type(() => AccessCategory)
  public access_categories: AccessCategory[] = [];
  @Type(() => User)
  public me: User | null = null; // Only 1 element
  public starts_at: string | null = null;
  public ends_at: string | null = null;
  @Type(() => User)
  public leader?: User;
  public leader_participation?: ProjectParticipation;
  public country: ICountry;
  public country_code?: string | null;
  public allow_s3_direct_upload?: boolean;
  public former_users_count = 0;
  public min_nb_signatories: number | null = null;
  public max_nb_signatories: number | null = null;

  get nameWithInternalId(): string {
    return this.internal_id ? `${this.internal_id} - ${this.name}` : this.name;
  }

  get hasProgress(): boolean {
    return (
      typeof this.completed_tasks_count === "number" &&
      typeof this.tasks_count === "number"
    );
  }

  get percentProgress(): number {
    const completed = this.completed_tasks_count || 0;
    const total = this.tasks_count || 1;

    return Math.round((completed / total) * 100);
  }

  getDisplayName(): string {
    return this.name;
  }
}
