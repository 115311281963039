import User from "@/models/User";
import ITimestamps from "@/models/TimestampsInterface";
import IAsset from "@/models/AssetInterface";
import { Type } from "class-transformer";
import Dataset from "@/models/Dataset";
import DatasetCategory from "@/models/DatasetCategory";

export default class DatasetVersion implements IAsset, ITimestamps {
  public id: number;
  public hash: string;
  public name: string;
  public description: string;
  @Type(() => DatasetCategory)
  public category: DatasetCategory;
  public mime_type: string;
  public size: number;
  public structure: unknown;
  public available: boolean;
  @Type(() => User)
  public originator: User;
  @Type(() => Dataset)
  public dataset: Dataset;
  public metadata?: string;
  public version_id?: number;
}
