import { RouteConfig } from "vue-router";

const routes: readonly RouteConfig[] = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "route.login" */ "../views/Login.vue"),
    meta: {
      redirectWhenAuthenticated: true,
    },
  },
  {
    path: "/callback",
    name: "login_callback",
    component: () =>
      import(
        /* webpackChunkName: "route.login-callback" */ "../views/LoginCallback.vue"
      ),
  },
  {
    path: "/join/:token",
    name: "app_invitation",
    component: () =>
      import(
        /* webpackChunkName: "route.first-login" */ "../views/FirstLogin.vue"
      ),
  },
];

export default routes;
