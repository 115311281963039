import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType,
} from "@/reports/ReportModel";
import {
  noteLinkAccessorBuilder,
  ProjectLinkAccessor,
  userLinkAccessorBuilder,
} from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: [],
  order: {
    "Users.name": "asc",
  },
  dimensions: [
    "Signatories.order",
    "Signatories.action",
    "Signatories.statement",
    "ElectronicSignatures.createdAt",
    "ElectronicSignatureOriginatorUser.id",
    "ElectronicSignatureOriginatorUser.name",
    "Users.id",
    "Users.name",
    "NoteVersions.noteId",
    "NoteVersions.title",
    "Projects.id",
    "Projects.name",
  ],
  segments: [
    "Projects.notArchived",
    "Projects.notDeleted",
    "Signatories.pending",
    "ElectronicSignatures.notDeleted",
    "ElectronicSignatures.active",
    "Notes.notDeleted",
    "NoteVersions.head",
  ],
  ungrouped: true,
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Signatories.signatoryStatus",
    label: "Upcoming only",
    defaultValue: "Upcoming",
    multiple: true,
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Signatory",
    key: "Users.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder(),
    width: 200,
  },
  {
    title: "Request Date",
    key: "ElectronicSignatures.createdAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Note",
    key: "NoteVersions.title",
    type: ReportColumnType.Link,
    linkAccessor: noteLinkAccessorBuilder("NoteVersions.noteId"),
    width: 200,
  },
  {
    title: "Project",
    key: "Projects.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    width: 200,
  },
  {
    title: "Requester",
    key: "ElectronicSignatureOriginatorUser.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder(
      "ElectronicSignatureOriginatorUser.id"
    ),
    width: 200,
  },
];

export const ElectronicSignatures: ReportConfiguration = {
  name: "Outstanding Signatures",
  section: "Notes",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "ElectronicSignatures.createdAt",
    endsAt: "ElectronicSignatures.createdAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
};
