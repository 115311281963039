import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import University from "@/models/University";
import { AxiosPromise } from "axios";

export function search(query = ""): AxiosPromise<University[]> {
  return $http
    .get(`${prefix}/universities-search`, { params: { query } })
    .then(response => responseToClass(University, response));
}
