import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/datasets/:dataset_id/protocols/create",
    name: "dataset_protocol_create",
    component: () =>
      import(
        /* webpackChunkName: "route.protocol-form.create" */ "../views/ProtocolForm.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/datasets/:dataset_id/protocols/:protocol_id",
    name: "dataset_protocol",
    component: () =>
      import(
        /* webpackChunkName: "route.dataset-protocol" */ "../views/Protocol.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/datasets/:dataset_id/protocols/:protocol_id/edit",
    name: "dataset_protocol_edit",
    component: () =>
      import(
        /* webpackChunkName: "route.protocol-form.edit" */ "../views/ProtocolForm.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/protocols",
    name: "protocols",
    component: () =>
      import(
        /* webpackChunkName: "route.protocols" */ "../views/Protocols.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/protocols/:protocol_id/history",
    name: "protocol_history",
    component: () =>
      import(
        /* webpackChunkName: "route.protocol.history" */ "../views/ProtocolHistory.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/protocols/:protocol_id/versions/:version_id",
    name: "protocol_version",
    component: () =>
      import(
        /* webpackChunkName: "route.protocol.version" */ "../views/ProtocolVersion.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/protocols/:protocol_id",
    name: "protocol",
    component: () =>
      import(/* webpackChunkName: "route.protocol" */ "../views/Protocol.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/template-protocols/create",
    name: "template_protocol_create",
    component: () =>
      import(
        /* webpackChunkName: "route.template-protocol.create" */ "../views/ProtocolForm.vue"
      ),
    meta: {
      templatePerspective: true,
      auth: true
    }
  },
  {
    path: "/protocols/:protocol_id/edit",
    name: "protocol_edit",
    component: () =>
      import(
        /* webpackChunkName: "route.protocol.edit" */ "../views/ProtocolForm.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/template-protocols/:protocol_id/edit",
    name: "template_protocol_edit",
    component: () =>
      import(
        /* webpackChunkName: "route.template-protocol.edit" */ "../views/ProtocolForm.vue"
      ),
    meta: {
      templatePerspective: true,
      auth: true
    }
  },
  {
    path: "/protocols/:protocol_id/datasets",
    name: "template_protocol_datasets",
    component: () =>
      import(
        /* webpackChunkName: "route.template-protocols" */ "../views/ProtocolDatasets.vue"
      ),
    meta: {
      templatePerspective: true,
      auth: true
    }
  }
];

export default routes;
