import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass, responseToClass } from "@/api/lib";
import User from "@/models/User";
import { AxiosPromise, AxiosResponse } from "axios";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";

export interface IUserListOptions {
  // @deprecated Favoriser la composition avec l'interface LimitableResults dans limitable.ts
  page?: number;
  query?: string;
  excludedUserIds?: number[];
}

export function list(projectId: number): AxiosPromise<User[]> {
  return $http
    .get(`${prefix}/projects/${projectId}/users`)
    .then(response => responseToClass(User, response));
}

export function listPaginated(
  projectId: number,
  params: IUserListOptions
): AxiosPromise<PaginationResponse<User[]>> {
  return $http
    .get(`${prefix}/projects/${projectId}/users-paginated`, {
      params
    })
    .then(response => paginatedResponseToClass(User, response));
}

export function listFormerPaginated(
  projectId: number,
  params: IUserListOptions
): AxiosPromise<PaginationResponse<User[]>> {
  return $http
    .get(`${prefix}/projects/${projectId}/former-users-paginated`, {
      params
    })
    .then(response => paginatedResponseToClass(User, response));
}

export function attach(
  projectId: number,
  userId: number,
  data: Record<string, unknown>
): AxiosPromise<User> {
  return $http
    .put(`${prefix}/projects/${projectId}/users/${userId}`, data)
    .then(response => responseToClass(User, response));
}

export function detach(projectId: number, userId: number) {
  return $http.delete(`${prefix}/projects/${projectId}/users/${userId}`);
}

export function inviteByEmail(projectId: number, email: string, data = {}) {
  return $http
    .post(`${prefix}/projects/${projectId}/users`, { email, ...data })
    .then(response => responseToClass(User, response) as AxiosResponse<User>);
}

export function searchAvailable(projectId: number, query: string) {
  if (!query) {
    query = "";
  }

  return $http
    .get(`${prefix}/projects/${projectId}/users-available`, {
      params: {
        query
      }
    })
    .then(response => responseToClass(User, response));
}
