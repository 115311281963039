import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import DatasetCategory from "@/models/DatasetCategory";
import { AxiosPromise } from "axios";

export function available(datasetId: number): AxiosPromise<DatasetCategory[]> {
  return $http
    .get(`${prefix}/datasets/${datasetId}/dataset-categories/available`)
    .then(response => responseToClass(DatasetCategory, response));
}
