/* eslint typescript-sort-keys/string-enum: "warn", curly: "error" */

/**
 * Automatically bound as Amplitude events
 */
export enum AmplitudeEvents {
  AccessCategoryCreated = "access-category.created",
  AuditTrailViewed = "audit-trail.reports.viewed",
  DatasetAdded = "dataset.added",
  DatasetCommented = "dataset.commented",
  DatasetDeleted = "dataset.deleted",
  DatasetDownloaded = "dataset.downloaded",
  DatasetDownloadedBulk = "dataset.downloaded.bulk",
  DatasetFolderSearch = "dataset.folder-search",
  DatasetMoved = "dataset.moved",
  DatasetMultiSelected = "dataset.multiselected",
  DatasetOpenEdit = "dataset.open-edit",
  DatasetSaveEdit = "dataset.save-edit",
  DatasetSharedByEmail = "dataset.shared-by-email",
  DatasetUpdated = "dataset.updated",
  DatasetViewed = "dataset.viewed",
  LearnMore = "learn-more",
  NoteCreated = "notes.created",
  NoteDeleted = "notes.deleted",
  NoteHistoryViewed = "notes.history.viewed",
  NoteResourceTagged = "notes.resources.tagged",
  NoteSignatureFormViewed = "notes.signature.viewed",
  NoteSignatureProcessStarted = "notes.signature.started",
  NoteSignatureRejected = "notes.signature.rejected",
  NoteSignatureSigned = "notes.signature.signed",
  NoteUpdated = "notes.updated",
  NotesOpenTableOfContent = "notes.open-table-of-contents",
  NotesProtocolImported = "notes.protocol.imported",
  NotesTableOfContentItemClick = "notes.table-of-content-item-click",
  NotesViewed = "notes.viewed",
  ProjectAccessCategoriesUpdated = "project.access-categories.updated",
  ProjectArchived = "project.archived",
  ProjectCreated = "project.created",
  ProjectDeleted = "project.deleted",
  ProjectOverviewListItemClicked = "project.overview.list-item-click",
  ProjectOverviewUpdated = "project.overview.updated",
  ProjectShared = "project.shared",
  ProjectUnarchived = "project.unarchived",
  ProjectViewed = "project.viewed",
  ProtocolImported = "protocol.imported",
  ProtocolUpdated = "protocol.updated",
  ProtocolViewed = "protocol.viewed",
  ReportAddFilter = "report.add-filter",
  ReportClearFilters = "report.clear-filters",
  ReportDownloadCsv = "report.download-csv",
  ReportDownloadXlsx = "report.download-xlsx",
  ReportExportAsZip = "report.export-as-zip",
  ReportGenerate = "report.generate",
  ReportRemoveFilter = "report.remove-filter",
  SearchClicked = "search.clicked",
  SearchFacetSelect = "search.facet-select",
  SearchPageChange = "search.page-change",
  SearchQueried = "search.queried",
  TaskCompleted = "task.completed",
  TaskCreated = "task.created",
  TaskIncomplete = "task.incomplete",
  TaskListViewed = "task.list.viewed",
  TaskShowCompleted = "task.show-completed",
  TaskUpdated = "task.updated",
  TaskViewed = "task.viewed",
  TeamAccessCategoriesUpdated = "team.access-categories.updated",
  TeamOverviewUpdated = "team.overview.updated",
  TeamShared = "team.shared",
  TemplateProtocolCreated = "template-protocol.created",
  TemplateProtocolUpdated = "template-protocol.updated",
  TemplateProtocolViewed = "template-protocol.viewed",
  UserOrganizationCreated = "organizations.users.created",
  UserOrganizationUpdated = "organizations.users.updated",
  UserProfileEditPageViewed = "user.profile.edit.viewed",
  UserProfileInterestsModalViewed = "user.profile.interests.viewed",
  UserProfileInterestsUpdated = "user.profile.interests.updated",
  UserProfilePageViewed = "user.profile.viewed",
  UserProfileUpdated = "user.profile.updated",
  UserRemovedFromOrganization = "organizations.users.remove",
  UserResumeDownloaded = "resume.downloaded",
  UserSettingsNotificationsUpdated = "user.settings.notifications.updated",
  UserSettingsNotificationsViewed = "user.settings.notifications.viewed",
  UserSettingsPrivacyUpdated = "user.settings.privacy.updated",
  UserSignedUp = "user.signed-up",
  UserTemporaryFileUploaded = "resume.uploaded",
  WorkExperienceCreated = "work-experience.created",
  WorkExperienceDeleted = "work-experience.deleted",
  WorkExperienceEdit = "work-experience.edit",
  WorkExperienceEmployerCreated = "work-experience.employer.created",
  WorkExperienceEmployerNoSearchResult = "work-experience.employer.no-search-result",
  WorkExperienceUpdated = "work-experience.updated",
}
