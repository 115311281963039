import ITimestamps from "@/models/TimestampsInterface";
import ElectronicSignature, {
  ElectronicSignatureStatus
} from "@/models/ElectronicSignature";
import { Type } from "class-transformer";
import { IElectronicallySignable } from "@/models/IElectronicallySignable";

export default class NoteVersion
  implements ITimestamps, IElectronicallySignable {
  public id: number;
  public content = "";
  @Type(() => ElectronicSignature)
  public electronic_signatures: ElectronicSignature[] = [];
  public created_at: string | null = null;
  public updated_at: string | null = null;

  public get activeElectronicSignature(): ElectronicSignature | null {
    const sigs = this.electronic_signatures.filter(es =>
      [
        ElectronicSignatureStatus.Active,
        ElectronicSignatureStatus.Complete
      ].includes(es.status)
    );

    if (sigs.length > 0) {
      return sigs[0];
    }

    return null;
  }
}
