import { apiUrl } from "@/config/app";
import { PluginObject } from "vue";
import * as Sentry from "@sentry/vue";

export function publicDownloadUrl(id: number): string {
  if (typeof id !== "number") {
    Sentry.captureException(
      new Error(`publicDownloadUrl received a id of type ${typeof id}`)
    );
  }
  return `${apiUrl.getInstance()}/api/files/${id}/download`;
}

const plugin: PluginObject<any> = {
  install(Vue, options) {
    Vue.mixin({
      filters: {
        publicDownloadUrl,
      },
    });
  },
};

export default plugin;
