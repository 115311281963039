import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree
} from "vuex";
import { RootState } from "@/store";

export enum MutationTypes {
  SetStatusCode = "SetStatusCode",
  SetRedirect = "SetRedirect",
  ClearRedirect = "ClearRedirect",
  Reset = "Reset"
}

export interface IHttpState {
  statusCode: number;
  redirect: string | null;
}

const state: IHttpState = {
  statusCode: 200,
  redirect: null
};

const getters: GetterTree<IHttpState, RootState> = {
  isOk: state => state.statusCode >= 200 && state.statusCode <= 299,
  isClientError: state => state.statusCode >= 400 && state.statusCode <= 499,
  isServerError: state => state.statusCode >= 500 && state.statusCode <= 599,
  isNotFound: state => state.statusCode === 404,
  isForbidden: state => state.statusCode === 403
};

const actions: ActionTree<IHttpState, RootState> = {
  setStatusCode({ commit }: ActionContext<IHttpState, RootState>, statusCode) {
    commit(MutationTypes.SetStatusCode, statusCode);
  },
  setStatusNotFound({ commit }: ActionContext<IHttpState, RootState>) {
    commit(MutationTypes.SetStatusCode, 404);
  },
  setStatusOk({ commit }: ActionContext<IHttpState, RootState>) {
    commit(MutationTypes.SetStatusCode, 200);
  }
};

const mutations: MutationTree<IHttpState> = {
  [MutationTypes.SetStatusCode](state, statusCode) {
    state.statusCode = statusCode;
  },
  [MutationTypes.SetRedirect](state, redirectUrl) {
    state.redirect = redirectUrl;
  },
  [MutationTypes.ClearRedirect](state) {
    state.redirect = null;
  },
  [MutationTypes.Reset](state) {
    state.statusCode = 200;
  }
};

export const http: Module<IHttpState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
