import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/search",
    name: "search",
    component: () =>
      import(
        /* webpackChunkName: "route.search" */ "../views/SearchEverywhere.vue"
      ),
    meta: {
      auth: true
    }
  }
];

export default routes;
