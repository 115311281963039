import TimestampsInterface from "@/models/TimestampsInterface";
import { NotificationType } from "@/staticCollections/notificationTypes";

export interface INotification<T = Record<string, unknown>>
  extends TimestampsInterface {
  id: number;
  type: NotificationType;
  data: T;
  read_at: string | null;
}

export class Notification<T = Record<string, unknown>>
  implements INotification<T> {
  public id: number;
  public type: NotificationType;
  public data: T;
  public read_at: string | null;
  public created_at: string;
  public updated_at: string;
}
