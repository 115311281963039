import moment from "moment";

export default class FileModel {
  public id: number;
  public name: string;
  public mime_type: string;
  public size: number;
  public signed: boolean;
}

/**
 * If put into another file, this will create a circular dependency
 */
export class SignedFile extends FileModel {
  public url: string;
  public expires_at: string;

  get isExpired(): boolean {
    return moment().isAfter(this.expires_at);
  }
}
