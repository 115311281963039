import { getField, updateField } from "vuex-map-fields";
import { GetterTree, Module } from "vuex";
import { RootState } from "@/store";

export interface INewDatasetState {
  name: string | null;
  fileType: string | null;
}

const state: INewDatasetState = {
  name: null,
  fileType: null
};

const getters: GetterTree<INewDatasetState, RootState> = {
  getField
};

const actions = {};

const mutations = {
  updateField
};

export const newDataset: Module<INewDatasetState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
