import { AxiosPromise } from "axios";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass } from "@/api/lib";
import Project from "@/models/Project";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";

interface Params {
  page?: number;
  name?: string;
  sortBy?: string;
  order?: string;
}
export function list(
  organizationId: number,
  params: Params
): AxiosPromise<PaginationResponse<Project[]>> {
  return $http
    .get(`${prefix}/organizations/${organizationId}/projects`, { params })
    .then(response => paginatedResponseToClass(Project, response));
}
