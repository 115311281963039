import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType,
} from "@/reports/ReportModel";
import {
  ProjectLeaderLinkAccessor,
  ProjectLinkAccessor,
} from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["Tasks.completionRatio", "Tasks.overdueCount"],
  order: [
    ["Projects.startsAt", "asc"],
    ["Projects.endsAt", "asc"],
  ],
  dimensions: [
    "Projects.id",
    "Projects.name",
    "ProjectLeader.id",
    "ProjectLeader.name",
    "Projects.startsAt",
    "Projects.endsAt",
    "ProjectTagsList.list",
  ],
  segments: [
    "Projects.notDeleted",
    "ProjectLeader.notDeleted",
    "ProjectTagsList.notDeleted",
  ],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "ProjectLeader.name",
    label: "Employee",
  },
  {
    dimension: "ProjectTags.label",
    label: "Tags",
    multiple: true,
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Project",
    key: "Projects.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    width: 150,
  },
  {
    title: "Employee",
    key: "ProjectLeader.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLeaderLinkAccessor,
    width: 150,
  },
  {
    title: "Tags",
    key: "ProjectTagsList.list",
    type: ReportColumnType.Text,
  },
  {
    title: "Progress (%)",
    key: "Tasks.completionRatio",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Overdue Tasks",
    key: "Tasks.overdueCount",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Start Date",
    key: "Projects.startsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "End Date",
    key: "Projects.endsAt",
    type: ReportColumnType.Date,
  },
];

export const ProjectSummaryReportConfig: ReportConfiguration = {
  name: "Project Task Summary",
  section: "Demo",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Projects.startsAt",
    endsAt: "Projects.endsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Bottom,
};
