var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("special-navbar", { attrs: { "nav-class": "is-warning" } }),
      _vm._v(" "),
      _c("section", { staticClass: "hero is-warning is-fullheight" }, [
        _c("div", { staticClass: "hero-body" }, [
          _c("div", { staticClass: "container" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v("\n          404 - Page not found\n        "),
            ]),
            _vm._v(" "),
            _c("h2", { staticClass: "subtitle" }, [
              _vm._v(
                "\n          The resource your are looking for is missing or not available\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        return _vm.$router.back()
                      },
                    },
                  },
                  [
                    _c("fa-icon", {
                      staticClass: "icon",
                      attrs: { icon: "arrow-left" },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Back")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "button",
                    attrs: { to: { name: _vm.homeRoute } },
                  },
                  [
                    _c("fa-icon", {
                      staticClass: "icon",
                      attrs: { icon: "home" },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Home")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }