import { IItemRole } from "@/staticCollections/index";
import Dict from "@/staticCollections/dict";

export enum TeamPermissions {
  OnlyOfficeModule = "onlyoffice-module",
  ElectronicSignature = "electronic-signature-module",
  ProtocolsModule = "protocols-module",
}

const items: Record<TeamPermissions, IItemRole> = {
  [TeamPermissions.OnlyOfficeModule]: {
    order: 4,
    translationKey: "OnlyOffice Module",
    value: TeamPermissions.OnlyOfficeModule,
  },
  [TeamPermissions.ElectronicSignature]: {
    order: 7,
    translationKey: "Electronic Signature",
    value: TeamPermissions.ElectronicSignature,
  },
  [TeamPermissions.ProtocolsModule]: {
    order: 8,
    translationKey: "Protocols Module",
    value: TeamPermissions.ProtocolsModule,
  },
};

export default new Dict<TeamPermissions, IItemRole>(items);
