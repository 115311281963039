import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/datasets/:parent_id/create",
    name: "dataset_create",
    component: () =>
      import(
        /* webpackChunkName: "route.dataset.create" */ "../views/DatasetCreate.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/datasets/:id",
    name: "dataset",
    component: () =>
      import(/* webpackChunkName: "route.dataset" */ "../views/Dataset.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/datasets/:dataset_id/history",
    name: "dataset_history",
    component: () =>
      import(
        /* webpackChunkName: "route.dataset.history" */ "../views/DatasetHistory.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/datasets/:dataset_id/versions/:version_id",
    name: "dataset_version",
    component: () =>
      import(
        /* webpackChunkName: "route.datasets.version" */ "../views/DatasetVersion.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/office-premium",
    name: "office_premium",
    component: () =>
      import(
        /* webpackChunkName: "route.office-premium" */ "../views/OnlyOfficePremium.vue"
      ),
    meta: {
      auth: true
    }
  }
];

export default routes;
