type RequestFilter = {
  name: string;
  operator: string;
  value: string;
};

export type FilterableResults = {
  filterBy: RequestFilter[];
};

export function serializeFilters(filters: RequestFilter[]): string[] {
  return filters.map(
    filter => `${filter.name}${filter.operator}${filter.value}`
  );
}
