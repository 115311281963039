import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import { AxiosPromise } from "axios";
import Company from "@/models/Company";
import CreateFormRequest from "@/forms/requests/company/CreateFormRequest";

export function search(query = ""): AxiosPromise<Company[]> {
  return $http
    .get(`${prefix}/companies-search`, { params: { query } })
    .then(response => responseToClass(Company, response));
}

export function create(data: CreateFormRequest): AxiosPromise<Company> {
  return $http
    .post(`${prefix}/companies`, data)
    .then(response => responseToClass(Company, response));
}
