var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar",
      class: _vm.navClass,
      attrs: { role: "navigation", "aria-label": "main navigation" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "navbar-brand" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-item", attrs: { to: { name: _vm.home } } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/logo-full-white.png"),
                    alt: "Pillar Science Logo",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar-burger" }, [
      _c("span"),
      _vm._v(" "),
      _c("span"),
      _vm._v(" "),
      _c("span"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }