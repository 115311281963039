import { Auth0ClientOptions } from "@auth0/auth0-spa-js/src/global";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { clientFactory, getDefaultOptions } from "@/config/auth0";
import User from "@/models/User";
import PromiseExecutor from "@/types/promise";
import { ApiClient } from "@/api";
import { EventBus } from "@/services/eventBus";
import { ApplicationEvents } from "@/events/ApplicationEvents";
import { MutationTypes } from "@/store/modules/user";
import store from "@/store";
import * as Sentry from "@sentry/vue";
import { AMPLITUDE_VERSION_NAME } from "@/config/amplitude";
import ErrorHelper from "@/services/errorHelper";
import { configuration } from "@/config/dynamic";
import { Singleton } from "@/utils/Singleton";
import { LocalStorageHelper } from "@/utils/LocalStorageHelper";
import { CUBE_JS_TOKEN_STORAGE_KEY } from "@/reports/ReportModel";

export interface Credentials {
  email: string;
  password: string | null;
}

export interface EmailForm {
  email: string;
}

export interface ChangeForm {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export interface ResetForm extends ChangeForm {
  token: string;
}

export type OnReadyEvent = {
  token: string;
  client: Auth0Client;
};
export type OnUserAvailableEvent = { user: User | null; client: Auth0Client };

class Authentication {
  private _client: Promise<Auth0Client>;

  private _onReadyPromise: Promise<OnReadyEvent>;
  private _onReadyExecutors: PromiseExecutor<OnReadyEvent, any> | null = null;
  private _onUserAvailablePromise: Promise<OnUserAvailableEvent>;
  private _onUserAvailableExecutors: PromiseExecutor<
    OnUserAvailableEvent,
    any
  > | null = null;

  constructor(options: Auth0ClientOptions) {
    this._client = clientFactory(options);

    this._onReadyPromise = new Promise((resolve, reject) => {
      this._onReadyExecutors = { resolve, reject };
    });

    this._onUserAvailablePromise = new Promise((resolve, reject) => {
      this._onUserAvailableExecutors = { resolve, reject };
    });
  }

  onReady(): Promise<OnReadyEvent> {
    return this._onReadyPromise;
  }

  onUserAvailable(): Promise<OnUserAvailableEvent> {
    return this._onUserAvailablePromise;
  }

  initialize(): Promise<Auth0Client> {
    return this._client.then((client) => {
      return client.getTokenSilently().then(
        (token) => {
          this._onReadyExecutors?.resolve({
            token,
            client,
          });

          ApiClient.Auth.me().then(
            (response) => {
              const user = Object.assign(new User(), response.data);

              this._onUserAvailableExecutors?.resolve({ user, client });

              EventBus.$emit(ApplicationEvents.UserAvailable, user);

              store.commit(`user/${MutationTypes.SetUser}`, user);

              configuration.getInstance().then(config => {
                if (config.sentryDsn) {

                  Sentry.setTag("user_name", `${user.name} (${user.id})`);
                  Sentry.setUser({
                    id: String(user.id),
                    name: user.name,
                    email: user.name,
                    teams: user.teams.map((t) => `${t.id}-${t.name}`),
                  });

                  return import("@/services/amplitude").then((module) => {
                    module.default.setUserId(String(response.data.id));
                    module.default.setVersionName(AMPLITUDE_VERSION_NAME);
                  });
                }
              });
            },
            (error) => {
              ErrorHelper.from(error).updatePageStatusCode();
            }
          );

          return client;
        },
        (error) => {
          this._onReadyExecutors?.reject("Not logged in");
          this._onUserAvailableExecutors?.resolve({ user: null, client });

          return client;
        }
      );
    });
  }

  logout() {
    this._client.then((client) => {
      LocalStorageHelper.remove(CUBE_JS_TOKEN_STORAGE_KEY);

      client.logout({
        logoutParams: {
          returnTo: `${window.location.protocol}//${window.location.host}`,
        },
      });
    });
  }
}

export const authenticationPromise = new Singleton(() =>
  getDefaultOptions().then((defaults) => new Authentication(defaults))
);
