import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
} from "@/reports/ReportModel";
import { userLinkAccessorBuilder } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["ResearchAxes.list"],
  order: [["Users.lastName", "asc"]],
  dimensions: [
    "Users.lastName",
    "Users.firstName",
    "Users.email",
    "Users.universityName",
    "Users.facultyStatus",
    "Users.departmentalAffiliation",
    "Users.programAffiliation",
    "Users.orcidId",
    "Users.id",
  ],
  filters: [],
  segments: ["Users.notDeleted"],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Users.name",
    label: "User Name",
  },
  {
    dimension: "ResearchAxes.name",
    label: "Research Axis",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "First name",
    key: "Users.firstName",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder(),
  },
  {
    title: "Last name",
    key: "Users.lastName",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder(),
  },
  {
    title: "Email",
    key: "Users.email",
    type: ReportColumnType.Text,
  },
  {
    title: "Research Axes",
    key: "ResearchAxes.list",
    type: ReportColumnType.Text,
  },
  {
    title: "Faculty",
    key: "Users.facultyStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Department",
    key: "Users.departmentalAffiliation",
    type: ReportColumnType.Text,
  },
  {
    title: "Program",
    key: "Users.programAffiliation",
    type: ReportColumnType.Text,
  },
  {
    title: "ORCID ID",
    key: "Users.orcidId",
    type: ReportColumnType.Text,
  },
];

export const ResearchersReportConfig: ReportConfiguration = {
  name: "Researchers",
  section: "Cirris",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: false,
  sortColumns: extractSortColumnsFromQuery(query),
};
