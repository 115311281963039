import _Vue from "vue";
import svg from "@/directives/svg";

export default {
  install(Vue: typeof _Vue, options: any) {
    Vue.mixin({
      directives: {
        svg
      }
    });
  }
};
