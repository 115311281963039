import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType
} from "@/reports/ReportModel";
import { userLinkAccessorBuilder } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  order: [
    ["Projects.startsAt", "asc"],
    ["Projects.endsAt", "asc"]
  ],
  dimensions: ["Users.id", "Users.name", "Users.projectsCount"]
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Users.name",
    label: "Employee"
  }
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Employee",
    key: "Users.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder(),
    width: 150
  },
  {
    title: "Projects",
    key: "Users.projectsCount",
    type: ReportColumnType.Numeric
  }
];

export const UserSummaryReportConfig: ReportConfiguration = {
  name: "User Summary",
  section: "Demo",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Projects.startsAt",
    endsAt: "Projects.endsAt"
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Bottom
};
