var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-taginput",
        _vm._b(
          {
            attrs: {
              autocomplete: "",
              disabled: _vm.disabled,
              "allow-new": false,
              data: _vm.conceptAutocompleteList,
              loading: _vm.isFetching,
              field: "display_name",
              placeholder: _vm.$attrs.placeholder || "Search interests...",
            },
            on: { typing: _vm.handleSearch, input: _vm.conceptsChanged },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          },
          "b-taginput",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }