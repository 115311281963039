import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType,
} from "@/reports/ReportModel";
import {
  noteLinkAccessorBuilder,
  ProjectLinkAccessor,
  userLinkAccessorBuilder,
} from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: [],
  order: {
    "Notes.createdAt": "desc",
  },
  dimensions: [
    "NoteVersions.noteId",
    "NoteVersions.title",
    "Notes.authorId",
    "Notes.createdAt",
    "Users.id",
    "Users.name",
    "Projects.id",
    "Projects.name",
  ],
  segments: [
    "Projects.notArchived",
    "NoteVersions.head",
    "Notes.notDeleted",
    "Users.notDeleted",
    "Projects.notDeleted",
  ],
  ungrouped: true,
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "NoteVersions.signatureStatus",
    label: "Signature Status",
    defaultValue: "Unsigned",
    multiple: true,
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Title",
    key: "NoteVersions.title",
    type: ReportColumnType.Link,
    linkAccessor: noteLinkAccessorBuilder("NoteVersions.noteId"),
    width: 200,
  },
  {
    title: "Date",
    key: "Notes.createdAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Project",
    key: "Projects.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    width: 200,
  },
  {
    title: "Author",
    key: "Users.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder(),
    width: 200,
  },
];

export const Notes: ReportConfiguration = {
  name: "Notes Signature Status",
  section: "Notes",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Notes.createdAt",
    endsAt: "Notes.createdAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
};
