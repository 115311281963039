var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "modal-background" }),
    _vm._v(" "),
    _c("div", { staticClass: "modal-card", staticStyle: { width: "auto" } }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c("ul", [
            _c(
              "li",
              { staticClass: "pb-2" },
              [
                _c("fa-icon", { attrs: { icon: "check-circle", size: "lg" } }),
                _vm._v(
                  "\n          We will use these to suggest matches for you of relevant\n          opportunities from our university partners.\n        "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "py-2" },
              [
                _c("fa-icon", { attrs: { icon: "check-circle", size: "lg" } }),
                _vm._v(
                  "\n          You can enter as many as you want but we'll need at least 5 to get\n          started with matches.\n        "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isIndustryMember
              ? _c(
                  "li",
                  { staticClass: "py-2" },
                  [
                    _c("fa-icon", {
                      attrs: { icon: "check-circle", size: "lg" },
                    }),
                    _vm._v(
                      "\n          Your data is confidential. We won't display this information to\n          other users on your public profile. Learn more in our\n          "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: {
                          href: "https://pillar.science/privacy-policy",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Privacy Policy")]
                    ),
                    _vm._v(".\n        "),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("hr", { staticClass: "dropdown-divider mt-2 mb-4" }),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "input-required",
              attrs: {
                label: "Please select at least 5 interests",
                message: _vm.bMessage(_vm.$v.interests, {
                  field: "Interests",
                  minLengthArray: "5",
                  maxLengthArray: "100",
                }),
                type: _vm.bType(_vm.$v.interests.$error),
              },
            },
            [
              _c("interests-autocomplete", {
                attrs: { placeholder: "e.g. semiconductor" },
                model: {
                  value: _vm.interests,
                  callback: function ($$v) {
                    _vm.interests = $$v
                  },
                  expression: "interests",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button is-primary is-saving",
            class: { "is-loading": _vm.loading },
            attrs: { disabled: _vm.$v.$error || _vm.loading },
            on: { click: _vm.handleUpdateUser },
          },
          [_vm._v("\n        Save\n      ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("What are your research interests?"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }