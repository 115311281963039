import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { AxiosPromise } from "axios";
import Formation from "@/models/Formation";
import { responseToClass } from "@/api/lib";

export function remove(formationId: number): AxiosPromise<void> {
  return $http.delete(`${prefix}/formations/${formationId}`);
}

export function update(
  formationId: number,
  formation: Formation
): AxiosPromise<Formation> {
  return $http
    .put(`${prefix}/formations/${formationId}`, formation)
    .then(response => responseToClass(Formation, response));
}
