import AccessCategory from "@/models/AccessCategory";
import { AxiosPromise } from "axios";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";

export function list(teamId: number): AxiosPromise<AccessCategory[]> {
  return $http
    .get(`${prefix}/teams/${teamId}/access-categories`)
    .then(response => responseToClass(AccessCategory, response));
}

export function available(teamId: number): AxiosPromise<AccessCategory[]> {
  return $http
    .get(`${prefix}/teams/${teamId}/access-categories/available`)
    .then(response => responseToClass(AccessCategory, response));
}

export function synchronize(
  teamId: number,
  accessCategories: AccessCategory[]
): AxiosPromise<AccessCategory[]> {
  const ids = accessCategories.map(ac => ac.id);

  return $http
    .put(`${prefix}/teams/${teamId}/access-categories/synchronize`, {
      accessCategories: ids
    })
    .then(response => responseToClass(AccessCategory, response));
}
