import { AxiosPromise } from "axios";
import User from "@/models/User";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import UserModel from "@/models/User";
import Dataset from "@/models/Dataset";

export function update(
  datasetId: number,
  author: User,
  isMain = false
): AxiosPromise<Dataset> {
  return $http
    .put(`${prefix}/datasets/${datasetId}/authors/${author.id}`, {
      is_main_author: isMain
    })
    .then(response => responseToClass(Dataset, response));
}

export function remove(
  datasetId: number,
  authorId: number
): AxiosPromise<Dataset> {
  return $http
    .delete(`${prefix}/datasets/${datasetId}/authors/${authorId}`)
    .then(response => responseToClass(Dataset, response));
}

export function searchAvailableUsers(
  datasetId: number,
  query: string
): AxiosPromise<Dataset> {
  return $http
    .get(`${prefix}/datasets/${datasetId}/authors/users-available`, {
      params: {
        query
      }
    })
    .then(response => responseToClass(UserModel, response));
}
