import $http from "@/services/Http";
import Dataset, { OrderedDataset } from "@/models/Dataset";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { responseToClass } from "@/api/lib";
import { prefix } from "@/api/config";
import * as DatasetVersion from "@/api/namespaces/dataset/datasetVersions";
import * as Protocol from "@/api/namespaces/dataset/protocol";
import * as Comment from "@/api/namespaces/dataset/comment";
import * as Metadata from "@/api/namespaces/dataset/metadata";
import * as Author from "@/api/namespaces/dataset/authors";
import * as DatasetCategory from "@/api/namespaces/dataset/datasetCategory";
import SignedUrlResponse from "@/interfaces/responses/SignedUrlInterface";
import DatasetSignature from "@/interfaces/signatures/DatasetSignature";
import DatasetWithVersions from "@/models/DatasetWithVersions";

export { Comment, DatasetVersion, Metadata, Protocol, Author, DatasetCategory };

export function loadHistory(
  datasetId: number,
  page?: number
): AxiosPromise<DatasetWithVersions> {
  return $http
    .get(`${prefix}/datasets/${datasetId}/history`, { params: { page } })
    .then(response => responseToClass(DatasetWithVersions, response));
}

export function show(datasetId: number): AxiosPromise<Dataset> {
  return $http
    .get(`${prefix}/datasets/${datasetId}`)
    .then(response => responseToClass(Dataset, response));
}

export function upload(
  datasetId: number,
  data: FormData,
  config: AxiosRequestConfig = {}
): AxiosPromise<Dataset> {
  return $http
    .post(`${prefix}/datasets/${datasetId}/upload`, data, config)
    .then(response => responseToClass(Dataset, response));
}

export function update(
  datasetId: number,
  data: Record<string, unknown>,
  config?: AxiosRequestConfig
): AxiosPromise<Dataset> {
  return $http
    .post(`${prefix}/datasets/${datasetId}/update`, data, config)
    .then(response => responseToClass(Dataset, response));
}

export function children(datasetId: number): AxiosPromise<OrderedDataset[]> {
  return $http
    .get(`${prefix}/datasets/${datasetId}/children`)
    .then(response => responseToClass(OrderedDataset, response));
}

export interface ICreateRequest {
  name: string;
  mime_type: string;
  content?: any;
  template: "xlsx" | "docx" | "pptx" | "url";
}

export function create(
  parentId: number,
  data: ICreateRequest
): AxiosPromise<Dataset> {
  return $http
    .post(`${prefix}/datasets/${parentId}/create`, data)
    .then(response => responseToClass(Dataset, response));
}

export function markAsDownloaded(datasetId: number): AxiosPromise<void> {
  return $http.post(`${prefix}/datasets/${datasetId}/mark-as-downloaded`);
}

export function markAsViewed(datasetId: number): AxiosPromise<void> {
  return $http.post(`${prefix}/datasets/${datasetId}/mark-as-viewed`);
}

export function signedUrl(
  datasetId: number,
  config?: AxiosRequestConfig
): AxiosPromise<SignedUrlResponse<DatasetSignature>> {
  // Do not use responseToClass as generics are not handle: https://github.com/typestack/class-transformer#working-with-generics
  return $http.post(`${prefix}/datasets/${datasetId}/signed-url`, {}, config);
}

export function moveTo(
  datasetsToMove: number[],
  targetDatasetId: number
): AxiosPromise<Dataset> {
  return $http
    .put(`${prefix}/datasets/move-to/${targetDatasetId}`, {
      datasets: datasetsToMove
    })
    .then(response => responseToClass(Dataset, response));
}

export function remove(datasetId: number): AxiosPromise<void> {
  return $http.delete(`${prefix}/datasets/${datasetId}`);
}

export function createDirectory(
  datasetId: number,
  data: Record<"name", string>
): AxiosPromise<Dataset> {
  return $http
    .post(`${prefix}/datasets/${datasetId}/create-directory`, data)
    .then(response => responseToClass(Dataset, response));
}

export function ancestors(
  datasetId: number,
  withSelf = true
): AxiosPromise<OrderedDataset[]> {
  return $http
    .get(`${prefix}/datasets/${datasetId}/ancestors`)
    .then(response => {
      if (!withSelf) {
        response.data.pop();
      }

      return responseToClass(OrderedDataset, response);
    });
}

export function zip(
  datasetIds: number[]
): AxiosPromise<{ datasets_count: number; locked_datasets_count: number }> {
  return $http.post(`${prefix}/datasets/zip`, {
    datasets: datasetIds
  });
}
