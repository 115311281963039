import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { AxiosPromise, AxiosResponse } from "axios";
import { plainToClass } from "class-transformer";
import Dataset from "@/models/Dataset";
import Protocol from "@/models/Protocol";
import Project from "@/models/Project";
import User from "@/models/User";
import ClauseForm from "@/components/search/clauses/ClauseForm";
import Note from "@/models/Note";
import Task from "@/models/Task";

export type IndexedModels = Project | Dataset | Protocol | User | Task | Note;

export enum SearchResultTypes {
  Dataset = "Dataset",
  Project = "Project",
  Protocol = "Protocol",
  Link = "Link",
  User = "User",
  Note = "Note",
  Task = "Task",
}

export type SearchResult<T extends IndexedModels = IndexedModels> = {
  highlight: string;
  type: SearchResultTypes;
  resource: T;
  score: number;
};

export type FacetsDefinition = FacetDefinition[];

export type FacetDefinition = {
  buckets: FacetRowDefinition[];
  key: string;
  label: string;
};

export type FacetRowDefinition = {
  label: string;
  value: string;
  count: number;
  selected: boolean;
};

export type SearchResponse = {
  aggregations: FacetsDefinition;
  hits: SearchResult[];
  max_score: number;
  page: number;
  total: number;
  per_page: number;
};

function searchResultToClass(
  response: AxiosResponse<SearchResponse>
): AxiosResponse<SearchResponse> {
  response.data.hits.forEach((resource: SearchResult) => {
    switch (resource.type) {
      case SearchResultTypes.Dataset:
        resource.resource = plainToClass(Dataset, resource.resource);
        break;
      case SearchResultTypes.Protocol:
        resource.resource = plainToClass(Protocol, resource.resource);
        break;
      case SearchResultTypes.Project:
        resource.resource = plainToClass(Project, resource.resource);
        break;
      case SearchResultTypes.User:
        resource.resource = plainToClass(User, resource.resource);
        break;
      case SearchResultTypes.Task:
        resource.resource = plainToClass(Task, resource.resource);
        break;
      case SearchResultTypes.Note:
        resource.resource = plainToClass(Note, resource.resource);
        break;
    }
  });

  return response;
}

export type SearchAggregations = Record<string, string[]>;

export function everywhere(
  query: string,
  clauses: ClauseForm[] = [],
  aggregations: SearchAggregations,
  limit: number | null = null,
  page = 1,
  signal?: AbortSignal
): AxiosPromise<SearchResponse> {
  return $http
    .post(
      `${prefix}/v2/search`,
      {
        query,
        clauses,
        aggregations,
        limit,
        page,
      },
      {
        signal,
      }
    )
    .then(searchResultToClass);
}
