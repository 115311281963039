import { AxiosResponse } from "axios";
import { ResponseConstructor } from "@/interfaces/responses/ResponseConstructor";
import { plainToClass } from "class-transformer";
import { ClassType } from "class-transformer/ClassTransformer";
import CursorPaginatedResponse from "@/interfaces/responses/CursorPaginatedResponse";
import PaginationResponse, {
  PaginatedResource
} from "@/interfaces/responses/PaginationResponse";

export function transformResponse<T, U>(
  response: AxiosResponse<T>,
  constructor: ResponseConstructor<T, U>
): AxiosResponse<U> {
  return Object.assign({}, response, {
    data: new constructor(response.data)
  });
}

export function responseToClass<T>(
  cls: ClassType<T>,
  response: AxiosResponse<T>
): AxiosResponse<T>;
export function responseToClass<T>(
  cls: ClassType<T>,
  response: AxiosResponse<T[]>
): AxiosResponse<T[]>;

export function responseToClass<T>(
  cls: ClassType<T>,
  response: AxiosResponse<T | T[]>
): AxiosResponse<T | T[]> {
  response.data = plainToClass(cls, response.data);

  return response;
}

export function cursorPaginatedToClass<T>(
  cls: ClassType<T>,
  response: AxiosResponse<CursorPaginatedResponse<T>>
): AxiosResponse<CursorPaginatedResponse<T>>;
export function cursorPaginatedToClass<T>(
  cls: ClassType<T[]>,
  response: AxiosResponse<CursorPaginatedResponse<T[]>>
): AxiosResponse<CursorPaginatedResponse<T[]>>;

export function cursorPaginatedToClass<T>(
  cls: ClassType<T>,
  response: AxiosResponse<CursorPaginatedResponse<T | T[]>>
): AxiosResponse<CursorPaginatedResponse<T | T[]>> {
  response.data.data = plainToClass(cls, response.data.data);

  return response;
}

export function paginatedResponseToClass<T>(
  cls: ClassType<T>,
  response: AxiosResponse<PaginationResponse<T>>
): AxiosResponse<PaginationResponse<T>>;
export function paginatedResponseToClass<T>(
  cls: ClassType<T[]>,
  response: AxiosResponse<PaginationResponse<T[]>>
): AxiosResponse<PaginationResponse<T[]>>;

export function paginatedResponseToClass(
  cls: ClassType<unknown>,
  response: AxiosResponse<PaginationResponse<unknown>>
): AxiosResponse<PaginationResponse<unknown>> {
  response.data.data = plainToClass(cls, response.data.data);

  return response;
}

/**
 * When paginating Laravel Resource (usually V2 endpoints)
 */
export function paginatedResourceToClass<T>(
  cls: ClassType<T>,
  response: AxiosResponse<PaginatedResource<T>>
): AxiosResponse<PaginatedResource<T>>;
export function paginatedResourceToClass<T>(
  cls: ClassType<T[]>,
  response: AxiosResponse<PaginatedResource<T[]>>
): AxiosResponse<PaginatedResource<T[]>>;

export function paginatedResourceToClass(
  cls: ClassType<unknown>,
  response: AxiosResponse<PaginatedResource<unknown>>
): AxiosResponse<PaginatedResource<unknown>> {
  response.data.data = plainToClass(cls, response.data.data);

  return response;
}
