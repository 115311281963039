var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "hero is-info is-fullheight" }, [
      _c("div", { staticClass: "hero-head" }, [
        _c("nav", { staticClass: "navbar" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "navbar-brand" }, [
              _c("a", { staticClass: "navbar-item" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/logo-full-white.png"),
                    alt: "Logo",
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "hero-body" }, [
        _c("div", { staticClass: "container has-text-centered" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v("\n        Pillar Science\n      "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "subtitle" }, [
            _vm._v("\n        Will be back soon after maintenance\n      "),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }