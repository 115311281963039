import IModel from "@/models/IModel";
import ITimestamps from "@/models/TimestampsInterface";
import { IOrdered } from "@/interfaces/behavior/IOrdered";
import User from "@/models/User";
import { Type } from "class-transformer";
import { ESignatoryStatus } from "@/staticCollections/signatoryStatus";

export const computeStatus = (
  sig: Signatory,
  signatories: readonly Signatory[]
): ESignatoryStatus => {
  if (sig.action === SignatoryActions.Accept) {
    return ESignatoryStatus.Signed;
  } else if (sig.action === SignatoryActions.Reject) {
    return ESignatoryStatus.Rejected;
  } else {
    const prev = signatories.find(s => s.order === sig.order - 1);

    if (!prev || prev.action_at !== null) {
      return ESignatoryStatus.InProgress;
    }
  }

  return ESignatoryStatus.Pending;
};

export enum SignatoryActions {
  Accept = "accept",
  Reject = "reject"
}

export default class Signatory implements IModel, ITimestamps, IOrdered {
  id: number;
  @Type(() => User)
  user: User;
  order: number;
  action: SignatoryActions | null = null;
  action_at: string | null = null;
  created_at: string | null = null;
  updated_at: string | null = null;
  status?: ESignatoryStatus;
}
