import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResourceToClass, responseToClass } from "@/api/lib";
import Project from "@/models/Project";
import { AxiosPromise } from "axios";
import { Roles } from "@/staticCollections/projectRoles";
import { AvailableRoleResponse } from "@/api/namespaces/user/team";
import { PaginatedResource } from "@/interfaces/responses/PaginationResponse";
import { SortableResults } from "@/api/sortable";
import { FilterableResults, serializeFilters } from "@/api/filterable";
import { LimitableResults } from "@/api/limitable";
import Team from "@/models/Team";

export type IProjectListOptions = SortableResults & {
  roles: Roles[];
  page: number;
  project: string | null;
  name: string | null;
  team_id: number | null;
  tags: readonly string[];
  access_category_ids: number[];
  status: "active" | "archived" | "all";
};

export function defaultProjectListOptions(): IProjectListOptions {
  return {
    roles: [],
    page: 1,
    project: null,
    name: null,
    team_id: null,
    tags: [],
    access_category_ids: [],
    status: "active"
  };
}

export function convertProjectListOptionsToV2(
  options: IProjectListOptions
): ProjectListOptionsV2 {
  const ret: ProjectListOptionsV2 = {
    filterBy: []
  };

  if (options.roles?.length) {
    ret.filterBy.push({
      name: "roles",
      operator: "=",
      value: options.roles.join(",")
    });
  }

  if (options.name) {
    ret.filterBy.push({
      name: "query",
      operator: "~=",
      value: options.name
    });
  }

  if (options.status !== null) {
    ret.filterBy.push({
      name: "active",
      operator: "=",
      value: options.status
    });
  }

  if (options.tags?.length) {
    ret.filterBy.push({
      name: "tags",
      operator: "=",
      value: options.tags.join(",")
    });
  }

  if (options.team_id) {
    ret.filterBy.push({
      name: "teamId",
      operator: "=",
      value: options.team_id.toString()
    });
  }

  if (options.access_category_ids?.length) {
    ret.filterBy.push({
      name: "accessCategoryIds",
      operator: "=",
      value: options.access_category_ids.join(",")
    });
  }

  ret.page = options.page;
  ret.sortBy = options.sortBy;
  ret.orderBy = options.orderBy;

  return ret;
}

export type ProjectListOptionsV2 = SortableResults &
  LimitableResults &
  FilterableResults & {
    project_participation_status?: EProjectParticipationStatus;
    included_project_sources?: EProjectSources[];
    subtracted_project_sources?: EProjectSources[];
    compare_with_user_id?: number;
  };

export enum EProjectQueryPivotStrategy {
  PROJECT_USER = "project_user",
  PROJECT_PARTICIPATIONS = "project_participations"
}

export enum EProjectArchivalStatus {
  ACTIVE = "active",
  ARCHIVED = "archived",
  ALL = "all"
}

export enum EProjectParticipationStatus {
  ONGOING = "ongoing",
  FORMER = "former",
  ALL = "all"
}

export enum EProjectSources {
  DIRECT_ACCESS = "directAccess",
  TEAM_ADMINISTRATOR = "teamAdministrator",
  THROUGH_ACCESS_CATEGORIES = "throughAccessCategories"
}

/**
 * This will list all direct access and shared via AC projects without pagination
 * @param userId
 * @param params
 */
export function list(
  userId: number,
  params: IProjectListOptions = {}
): AxiosPromise<Project[]> {
  return $http
    .get(`${prefix}/users/${userId}/projects`, { params })
    .then(response => responseToClass(Project, response));
}

export function show(userId: number, projectId: number): AxiosPromise<Project> {
  return $http
    .get(`${prefix}/users/${userId}/projects/${projectId}`)
    .then(response => responseToClass(Project, response));
}

export function availableRoles(
  userId: number,
  projectId: number,
  currentRole: Roles = Roles.Manager
): AxiosPromise<AvailableRoleResponse> {
  return $http
    .get(`${prefix}/users/${userId}/projects/${projectId}/available-roles`, {
      params: { currentRole }
    })
    .then(response => responseToClass(AvailableRoleResponse, response));
}

export function listV2(
  userId: number,
  params: ProjectListOptionsV2 = {
    filterBy: []
  }
): AxiosPromise<PaginatedResource<Project[]>> {
  const serializedFilters = params.filterBy.map(
    filter => `${filter.name}${filter.operator}${filter.value}`
  );

  return $http
    .get(`${prefix}/v2/users/${userId}/projects`, {
      params: Object.assign({}, params, {
        filterBy: serializeFilters(params.filterBy)
      })
    })
    .then(response => paginatedResourceToClass(Project, response));
}

export function searchTeamViaProjects(
  userId: number,
  query: string
): AxiosPromise<PaginatedResource<Team[]>> {
  return $http
    .get(`${prefix}/users/${userId}/projects/teams`, {
      params: { q: query }
    })
    .then(response => paginatedResourceToClass(Team, response));
}
