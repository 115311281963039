import $http from "@/services/Http";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { prefix } from "@/api/config";

export function initUpload(
  data: {
    uuid: string;
    source: string;
    parent_resource_id: number | null;
    resource_type: string;
    resource_id: number | null;
    name: string;
    size: number;
    mime_type: string;
  },
  config: AxiosRequestConfig = {}
): AxiosPromise<{ presigned_url: string }> {
  return $http
    .post(`${prefix}/s3/init-upload`, data, config)
    .then(response => response);
}

export function upload(
  url: string,
  data: File,
  config: AxiosRequestConfig = {}
): AxiosPromise<unknown> {
  return $http
    .put(
      url,
      data,
      Object.assign({}, config, {
        headers: {
          "Content-Type": data.type
        }
      })
    )
    .then(response => response);
}
