var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("special-navbar", { attrs: { "nav-class": "is-danger" } }),
      _vm._v(" "),
      _c("section", { staticClass: "hero is-danger is-fullheight" }, [
        _c("div", { staticClass: "hero-body" }, [
          _c("div", { staticClass: "container" }, [
            _c("h1", { staticClass: "title" }, [_vm._v("500 - Server Error")]),
            _vm._v(" "),
            _c("h2", { staticClass: "subtitle" }, [
              _vm._v("An unexpected error occurred"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                { staticClass: "button", on: { click: _vm.logout } },
                [
                  _c("fa-icon", {
                    staticClass: "icon",
                    attrs: { icon: "sign-out" },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Logout")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button",
                  attrs: { href: "mailto:support@pillar.science" },
                },
                [
                  _c("fa-icon", {
                    staticClass: "icon",
                    attrs: { icon: "question-circle" },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Contact support")]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }