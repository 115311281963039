import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import SignedUrlResponse from "@/interfaces/responses/SignedUrlInterface";
import DatasetVersionSignature from "@/interfaces/signatures/DatasetVersionSignature";
import { responseToClass } from "@/api/lib";
import DatasetVersion from "@/models/DatasetVersion";

export function show(
  datasetId: number | string,
  datasetVersionId: number | string
): AxiosPromise<DatasetVersion> {
  return $http
    .get(`${prefix}/datasets/${datasetId}/dataset-versions/${datasetVersionId}`)
    .then((response) => responseToClass(DatasetVersion, response));
}

export function signedUrl(
  datasetId: number,
  versionId: number,
  config?: AxiosRequestConfig
): AxiosPromise<SignedUrlResponse<DatasetVersionSignature>> {
  return $http.post(
    `${prefix}/datasets/${datasetId}/dataset-versions/${versionId}/signed-url`,
    {},
    config
  );
  // Do not use responseToClass as generics are not handle: https://github.com/typestack/class-transformer#working-with-generics
}

export function store(datasetId: number, form: FormData, config = {}) {
  return $http.post(
    `${prefix}/datasets/${datasetId}/dataset-versions`,
    form,
    config
  );
}
