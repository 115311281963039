import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/projects/create",
    name: "project_create_wizard",
    component: () =>
      import(
        /* webpackChunkName: "route.project.create.wizard" */ "../views/ProjectCreateWizard.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: () =>
      import(/* webpackChunkName: "route.projects" */ "../views/Projects.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "route.news" */ "../views/News.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/projects/:id",
    name: "project",
    component: () =>
      import(/* webpackChunkName: "route.project" */ "../views/Project.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "overview",
        name: "project_overview",
        component: () =>
          import(
            /* webpackChunkName: "route.project.overview" */ "../views/ProjectOverview.vue"
          ),
        meta: {
          auth: true,
          tags: ["overview"],
          breadcrumbs: "Overview",
        },
      },
      {
        path: "data",
        name: "project_data",
        component: () =>
          import(
            /* webpackChunkName: "route.project.data" */ "../views/ProjectData.vue"
          ),
        meta: {
          auth: true,
          tags: ["data"],
          breadcrumbs: "Data",
        },
      },
      {
        path: "protocols",
        name: "project_protocols",
        component: () =>
          import(
            /* webpackChunkName: "route.project.protocols" */ "../views/ProjectProtocols.vue"
          ),
        meta: {
          auth: true,
          tags: ["protocols"],
          breadcrumbs: "Methods",
        },
      },
      {
        path: "notes",
        name: "project_notes",
        component: () =>
          import(
            /* webpackChunkName: "route.project.notes" */ "../views/ProjectNotes.vue"
          ),
        meta: {
          auth: true,
          tags: ["notes"],
          breadcrumbs: "Notes",
        },
      },
      {
        path: "import-protocols",
        name: "project_import_protocols",
        component: () =>
          import(
            /* webpackChunkName: "route.project.protocols.import" */ "../views/ProjectImportProtocol.vue"
          ),
        meta: {
          auth: true,
          tags: ["protocols"],
          breadcrumbs: "Import Methods",
        },
      },
      {
        path: "audit_trail",
        name: "project_audit_trail",
        component: () =>
          import(
            /* webpackChunkName: "route.project.report" */ "../views/ProjectReport.vue"
          ),
        meta: {
          auth: true,
          tags: ["audit_trail"],
          breadcrumbs: "Audit Trail",
        },
      },
      {
        path: "settings",
        name: "project_settings",
        component: () =>
          import(
            /* webpackChunkName: "route.project.settings" */ "../views/ProjectSettings.vue"
          ),
        meta: {
          auth: true,
          tags: ["settings"],
          breadcrumbs: "Settings",
        },
      },
      {
        path: "sharing",
        name: "project_sharing",
        component: () =>
          import(
            /* webpackChunkName: "route.project.sharing" */ "../views/ProjectSharing.vue"
          ),
        meta: {
          auth: true,
          tags: ["sharing"],
          breadcrumbs: "Sharing",
        },
      },
      {
        path: "tasks",
        name: "project_tasks",
        component: () =>
          import(
            /* webpackChunkName: "route.project.tasks" */ "../views/ProjectTasks.vue"
          ),
        meta: {
          auth: true,
          tags: ["tasks"],
          breadcrumbs: "Tasks",
        },
      },
      {
        path: "funding",
        name: "project_funding",
        component: () =>
          import(
            /* webpackChunkName: "route.project.funding" */ "../views/ProjectFunding.vue"
          ),
        meta: {
          auth: true,
          tags: ["funding"],
          breadcrumbs: "Funding",
        },
      },
    ],
  },
];

export default routes;
