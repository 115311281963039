import Team from "@/models/Team";
import { Type } from "class-transformer";
import OrganizationReport from "@/models/OrganizationReport";
import { TeamPermissions } from "@/staticCollections/teamPermissions";

export enum OrganizationPermissions {
  ViewProjectList = "view-project-list",
  SelfSignupInProjects = "self-signup-in-projects",
  CreateProject = "create-project",
}

export default class Organization {
  public id: number;
  public hash: string;
  public name = "";
  public auth0_organization_id?: string;
  @Type(() => Team)
  public teams: Team[] = [];
  public default_storage_location?: string = "";
  public permissions: OrganizationPermissions[] = [];
  public default_team_permissions: TeamPermissions[] = [];
  @Type(() => OrganizationReport)
  public team_reports: OrganizationReport[] = [];
  @Type(() => OrganizationReport)
  public organization_reports: OrganizationReport[] = [];
  public src_features: boolean;
  public unshare_when_archived: boolean;
  public allow_users_edit_profile?: boolean = true;
  public allow_users_additional_private_fields?: boolean = false;
  public allow_users_available_for_hire_internship_dates_fields?: boolean =
    false;
  public allow_users_access_work_experiences?: boolean = false;
  public grant_access_to_any_team_member_to_reports?: boolean = false;
  public activate_dataset_categories?: boolean = false;
  public support_url?: string;
  public support_api_key?: string;
  public deleted_at: string | null = null;
  public deactivated_at: string | null = null;
  min_nb_signatories: number | null = null;
  max_nb_signatories: number | null = null;
}
