import moment from "moment";
import { DATETIME_FORMAT } from "@/config/date";

/**
 * Format ISO 8601 should be used when sending a date to the backend
 * @param form
 * @param fields
 * @param format
 */
export function transformDateToString<T>(
  form: T,
  fields: Array<keyof T>,
  format = "ISO_8601"
): T {
  fields
    .filter(field => form[field] instanceof Date)
    .forEach(
      field =>
        (form[field] =
          format === "ISO_8601"
            ? form[field].toISOString()
            : moment(form[field]).format(format))
    );

  return form;
}

export function transformDateToUTC<T>(
  form: T,
  fields: Array<keyof T>,
  format = DATETIME_FORMAT
): T {
  fields
    .filter(field => form[field] instanceof Date)
    .forEach(field => {
      form[field] = moment(form[field])
        .utc()
        .format(format);
    });

  return form;
}
