import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { SignedFile } from "@/models/File";
import { responseToClass } from "@/api/lib";
import { publicDownloadUrl } from "@/plugins/download";

export function sign(fileId: number, data: any = {}): AxiosPromise<SignedFile> {
  return $http
    .post(`${prefix}/files/${fileId}/sign`, data)
    .then((response) => responseToClass(SignedFile, response));
}

export function remove(fileId: number): AxiosPromise {
  return $http.delete(`${prefix}/files/${fileId}`);
}

export function download(
  fileId: number,
  options: AxiosRequestConfig
): AxiosPromise {
  return $http.get(publicDownloadUrl(fileId), options);
}
