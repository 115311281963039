import ITimestamps from "@/models/TimestampsInterface";

export default class University implements ITimestamps {
  public id: number;
  public name: string;
  public country_code: string;
  public country: string;
  public domains: string[];
  public web_pages: string[];
}
