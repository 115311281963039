import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass } from "@/api/lib";
import AccessCategory from "@/models/AccessCategory";
import { AxiosPromise } from "axios";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";

export function searchViaUserProjects(
  userId: number,
  query: string,
  excludedIds: number[]
): AxiosPromise<PaginationResponse<AccessCategory[]>> {
  return $http
    .get(`${prefix}/users/${userId}/projects/access-categories`, {
      params: { q: query, excludedIds }
    })
    .then(response => paginatedResponseToClass(AccessCategory, response));
}
