import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { AxiosPromise, AxiosRequestConfig } from "axios";

type TokenQueryParamsOrg = {
  organizationId: string;
};

type TokenQueryParamsTeam = {
  teamId: string;
};

export type TokenQueryParams = TokenQueryParamsOrg | TokenQueryParamsTeam;

export function token(
  userId: number,
  queryParams: TokenQueryParams,
  config?: AxiosRequestConfig
): AxiosPromise<{ token: string }> {
  const queryString = new URLSearchParams(queryParams).toString();
  return $http.get(
    `${prefix}/users/${userId}/cubejs/token?${queryString}`,
    config
  );
}
