

















import NotFound from "@/views/404.vue";
import Forbidden from "@/views/403.vue";
import ServerError from "@/views/500.vue";
import Component, { mixins } from "vue-class-component";
import { namespace } from "vuex-class";
import DeploymentMixin from "@/mixins/DeploymentMixin";
import User from "@/models/User";
import { Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { loadResource } from "@/utils/Dom";
import { IUserSupportConfig } from "@/store/modules/user";
import UserInterestsModal from "@/components/common/UserInterestsModal.vue";
import { configuration } from "@/config/dynamic";

const http = namespace("http");
const user = namespace("user");

@Component({
  components: {
    UserInterestsModal,
    NotFound,
    Forbidden,
    ServerError,
  },
})
export default class App extends mixins(DeploymentMixin) {
  @http.Getter("isOk") isOk!: boolean;
  @http.Getter("isNotFound") isNotFound!: boolean;
  @http.Getter("isForbidden") isForbidden!: boolean;
  @http.Getter("isServerError") isServerError!: boolean;
  @user.Getter("supportConfig") supportConfig!: IUserSupportConfig;
  @user.State("user") user!: User;
  @http.State("errorMessage") errorMessage!: string | null;
  requestUserInterests = false;

  mounted(): void {
    this.listenForUpdate();
  }

  setupZendeskSnippet(supportApiKey: string): void {
    loadResource(
      "https://static.zdassets.com/ekr/snippet.js?key=" + supportApiKey,
      "ze-snippet",
      "js",
      "body"
    );
  }
  @Watch("$route")
  onRouteChange(_value: Route, _oldValue: Route): void {
    window.dispatchEvent(
      new CustomEvent<{ href: string }>("vueAppURLChanged", {
        detail: { href: window.location.href },
      })
    ); // Required for react route sync
    if (!document) {
      return;
    }

    if (["dataset"].includes(this.$route.name)) {
      document.body.classList.add("hide-zendesk-help");
    } else {
      document.body.classList.remove("hide-zendesk-help");
    }
  }
  @Watch("userReady")
  onUserReadyChange(value: User): void {
    this.setupZendeskSnippet(this.supportConfig.supportApiKey);

    configuration.getInstance().then((config) => {
      const collectInterestsForUsers = config.collectInterestsForUsers
        ? config.collectInterestsForUsers
            .split(",")
            ?.map((u: string) => parseInt(u))
        : [];

      //Request user to input its interests
      if (
        value.interests_count === 0 &&
        collectInterestsForUsers.includes(value.id)
      ) {
        this.requestUserInterests = true;
      }
    });
  }
  get userReady(): boolean {
    const auth = this.$route.meta["auth"] || false;

    return !auth || (auth && this.user);
  }
  get pathname(): string {
    return window.location.pathname;
  }
}
