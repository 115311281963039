import _ from "lodash";

export function strEqualsInsensitive(
  str1: string | number | null | undefined,
  str2: string | number | null | undefined
): boolean {
  if (typeof str1 === "string") {
    str1 = str1.toLowerCase();
  }

  if (typeof str2 === "string") {
    str2 = str2.toLowerCase();
  }

  return str1 === str2;
}

export const searchTextInText = (
  needle: string | number | null | undefined,
  haystack: string | number | null | undefined
): boolean => {
  if (typeof needle !== "string" || typeof haystack !== "string") {
    return false;
  }
  return _.deburr(haystack)
    .toLowerCase()
    .includes(_.deburr(needle).toLowerCase());
};

export function strRandom(length = 12): string {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

// https://stackoverflow.com/a/20426113/1114113
export function stringifyNumber(n: number): string {
  if (n >= 100) {
    return String(n);
  }

  const special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth"
  ];
  const deca = [
    "twent",
    "thirt",
    "fort",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet"
  ];

  if (n < 20) {
    return special[n];
  } else if (n % 10 === 0) {
    return deca[Math.floor(n / 10) - 2] + "ieth";
  }

  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
}
