import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import Organization from "@/models/Organization";
import { AxiosPromise } from "axios";
import $http from "@/services/Http";

export function show(
  userId: number,
  organizationId: number
): AxiosPromise<Organization> {
  return $http
    .get(`${prefix}/users/${userId}/organizations/${organizationId}`)
    .then(response => responseToClass(Organization, response));
}
