import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import Note from "@/models/Note";
import { AxiosPromise } from "axios";

export function show(noteId: number): AxiosPromise<Note> {
  return $http
    .get(`${prefix}/notes/${noteId}`)
    .then(response => responseToClass(Note, response));
}

export function update(noteId: number, data: unknown): AxiosPromise<Note> {
  return $http
    .put(`${prefix}/notes/${noteId}`, data)
    .then(response => responseToClass(Note, response));
}

export function remove(noteId: number): AxiosPromise<Note> {
  return $http
    .delete(`${prefix}/notes/${noteId}`)
    .then(response => responseToClass(Note, response));
}
