import moment from "moment";
import { PluginObject } from "vue";
import { DATE_FORMAT } from "@/config/date";

enum LocalizedFormats {
  l = "L",
  ll = "LL",
  lll = "LLL",
  llll = "LLLL",
  l_short = "l",
  ll_short = "ll",
  lll_short = "lll",
  llll_short = "llll"
}

export enum StrictFormats {
  Time = "LT",
  Date = "ll",
  DateTime = "lll",
  DateMonthYear = "YYYY-MM",
  CompactDayMonth = "MMM D",
  CompactDayMonthYear = "MMM D, YYYY",
  Year = "YYYY"
}

function localizedFormat(datestring: string, format: LocalizedFormats) {
  if (!datestring) {
    return "Invalid date";
  }

  const m = moment(datestring);

  return m.isValid() ? m.format(format) : "Invalid date";
}

export function humanReadable(datestring: string): string {
  const m = moment(datestring);
  return m.format("LL") + " " + m.format("LT");
}

export function format(
  datestring: string | Date,
  format: StrictFormats
): string | null {
  if (!datestring) {
    return null;
  }

  const m = moment(datestring);

  return m.isValid() ? m.format(format) : "Invalid date";
}

export function moment_my(datestring: string): string {
  if (!datestring) {
    return "Invalid date";
  }

  const m = moment(datestring);

  return m.isValid()
    ? moment.months(m.month()) + " " + m.year()
    : "Invalid date";
}

export function date_part(datestring: string): string | null {
  if (!datestring) {
    return null;
  }

  const m = moment(datestring);

  return m.isValid() ? m.format(DATE_FORMAT) : null;
}

export function time_part(datestring: string): string | null {
  if (!datestring) {
    return null;
  }

  const m = moment(datestring);

  return m.isValid() ? m.format("HH:mm") : null;
}

export function moment_from(datestring: string): string | null {
  if (!datestring) {
    return null;
  }

  const m = moment(datestring);

  return m.isValid() ? m.fromNow() : null;
}

export function year_month(datestring: string | Date): string | null {
  return format(datestring, StrictFormats.DateMonthYear);
}

export function year_aware_day_date(datestring: string | Date): string | null {
  return format(
    datestring,
    moment(datestring).isSame(new Date(), "year")
      ? StrictFormats.CompactDayMonth
      : StrictFormats.CompactDayMonthYear
  );
}

const plugin: PluginObject<any> = {
  install(Vue, options: any) {
    Vue.mixin({
      filters: {
        humanReadable,
        moment_my,
        date_part,
        time_part,
        moment_from,
        year_month,
        format,
        year_aware_day_date
      }
    });
  }
};

export default plugin;
