import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass, responseToClass } from "@/api/lib";
import AccessCategory from "@/models/AccessCategory";
import { AxiosPromise } from "axios";
import CreateViewModel from "@/forms/models/organization/accessCategory/CreateViewModel";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";

export interface IAccessCategoryListOptions {
  page?: number;
  archived?: number;
  abbreviation?: string;
  name?: string;
  sortBy?: string;
  order?: string;
}
export function list(
  organizationId: number,
  params: IAccessCategoryListOptions
): AxiosPromise<PaginationResponse<AccessCategory[]>> {
  return $http
    .get(`${prefix}/organizations/${organizationId}/access-categories`, {
      params
    })
    .then(response => paginatedResponseToClass(AccessCategory, response));
}

export function create(
  organizationId: number,
  data: CreateViewModel
): AxiosPromise<AccessCategory> {
  return $http
    .post(`${prefix}/organizations/${organizationId}/access-categories`, data)
    .then(response => responseToClass(AccessCategory, response));
}
