/**
 * Parses a Laravel api error response
 */

import store from "@/store";
import { AxiosError } from "axios";
import { MutationTypes } from "@/store/modules/http";

export default class ErrorHelper {
  public static from(error: AxiosError): ErrorHelper {
    return new this(error);
  }

  public static clear(): void {
    store.commit(MutationTypes.Reset);
  }
  private error: AxiosError;

  constructor(error: AxiosError) {
    this.error = error;
  }

  public has(field: string): boolean {
    if (!this.isUnprocessable()) {
      return false;
    }

    if (!this.error.response) {
      return false;
    }

    return typeof this.error.response.data.error.errors[field] !== "undefined";
  }

  /**
   * @param fields array
   */
  public any(fields: string[]): string {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (this.has(field)) {
        return this.get(field)[0];
      }
    }

    return "";
  }

  public get(field: string): Record<string, unknown>[] {
    if (!this.has(field)) {
      return [];
    }

    if (!this.error.response) {
      return [];
    }

    return this.error.response.data.error.errors[field];
  }

  public first(field: string): string | null {
    const errors = this.get(field);

    return errors[0] || null;
  }

  public status(status: number): boolean {
    return this.getStatusCode() === status;
  }

  public getStatusCode(): number | null {
    if (!this.error.response) {
      return null;
    }

    return this.error.response.status || null;
  }

  public isUnauthorized(): boolean {
    return this.status(401);
  }

  public isForbidden(): boolean {
    return this.status(403);
  }

  public isNotFound(): boolean {
    return this.status(404);
  }

  public isUnprocessable(): boolean {
    return this.status(422);
  }

  /**
   * @returns {boolean} true if status was updated, false if not
   */
  public updatePageStatusCode(error: AxiosError | null = null): boolean {
    const statusCode = this.getStatusCode();

    if (statusCode) {
      store.dispatch("http/setStatusCode", this.getStatusCode());

      return true;
    }

    return false;
  }
}
