import IModel from "@/models/Base";
import ITimestamps from "@/models/TimestampsInterface";
import User from "@/models/User";
import { plainToClass, Transform, Type } from "class-transformer";
import Team from "@/models/Team";
import Project from "@/models/Project";
import ProtocolVersion from "@/models/ProtocolVersion";
import { IDisplayName } from "@/models/DisplayNameInterface";

export enum OwningResourceTypes {
  Team = "App\\Models\\Team",
  Project = "App\\Models\\Project"
}

export default class Protocol implements IModel, ITimestamps, IDisplayName {
  public id: number;
  public content: string;
  public excerpt: string;
  public name: string;
  public created_at: string;
  public updated_at: string;
  public tags: string[] = [];
  @Type(() => User)
  public user: User;
  public forks_count: number;
  @Type(() => Protocol)
  public forks: Protocol[] = [];
  @Transform(
    ({ value, obj }) => {
      switch (obj.owning_resource_type) {
        case OwningResourceTypes.Team:
          return plainToClass(Team, value);
        case OwningResourceTypes.Project:
          return plainToClass(Project, value);
        default:
          return null;
      }
    },
    { toClassOnly: true }
  )
  public owning_resource: Team | Project;
  public owning_resource_id: number;
  public owning_resource_type: OwningResourceTypes;
  @Type(() => ProtocolVersion)
  public versions?: ProtocolVersion[];
  public document_number: string | null = null;
  public version_name: string | null = null;
  @Type(() => ProtocolVersion)
  public current_version: ProtocolVersion | null = null;

  public canDelete(): boolean {
    return this.forks.length === 0 || this.forks_count === 0;
  }

  public get isProjectProtocol(): boolean {
    return this.owning_resource_type === "App\\Models\\Project";
  }

  public get isTeamProtocol(): boolean {
    return this.owning_resource_type === "App\\Models\\Team";
  }

  getDisplayName(): string {
    return this.name;
  }
}
