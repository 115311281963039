import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass, responseToClass } from "@/api/lib";
import User from "@/models/User";
import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import UpdateFormRequest from "@/forms/requests/organization/user/UpdateFormRequest";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";
interface Params {
  page?: number;
  userName?: string;
  sortBy?: string;
  order?: string;
}
export function list(
  organizationId: number,
  params: Params
): AxiosPromise<PaginationResponse<User[]>> {
  return $http
    .get(`${prefix}/organizations/${organizationId}/users`, { params })
    .then(response => paginatedResponseToClass(User, response));
}

export function show(
  organizationId: number,
  userId: number,
  config: AxiosRequestConfig = {}
): AxiosPromise<User> {
  return $http
    .get(`${prefix}/organizations/${organizationId}/users/${userId}`, config)
    .then(response => responseToClass(User, response) as AxiosResponse<User>);
}

export function update(
  organizationId: number,
  userId: number,
  data: UpdateFormRequest,
  config: AxiosRequestConfig = {}
): AxiosPromise<User> {
  return $http
    .put(
      `${prefix}/organizations/${organizationId}/users/${userId}`,
      data,
      config
    )
    .then(response => responseToClass(User, response));
}

export function updateImage(
  organizationId: number,
  userId: number,
  image: File
): AxiosPromise<void> {
  const form = new FormData();

  form.append("_method", "PUT");
  form.append("image", image);

  return $http.post(
    `${prefix}/organizations/${organizationId}/users/${userId}/profile-image`,
    form
  );
}

export function remove(
  organizationId: number,
  userId: number
): AxiosPromise<User> {
  return $http
    .delete(`${prefix}/organizations/${organizationId}/users/${userId}`)
    .then(response => responseToClass(User, response));
}
