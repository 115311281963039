import { AxiosPromise } from "axios";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { DefTableRecord, TypeDefinition } from "@/store/modules/definitions";

export enum EDefinitionTable {
  Gender = "gender",
  Pronoun = "pronoun",
  Race = "race",
  Visa = "visa",
  ExportControlStatus = "export_control_status",
  Country = "country",
  LawfulPermanentResidencyStatus = "lawful_permanent_residency_status",
  EmploymentType = "employment_type",
  UserCategory = "user_category",
  DegreeField = "degree_field",
  Degree = "degree"
}

export function availableOptions(
  option: TypeDefinition
): AxiosPromise<DefTableRecord[]> {
  return $http.get(`${prefix}/options`, {
    params: { option }
  });
}

export function search(
  option: TypeDefinition,
  query: string
): AxiosPromise<DefTableRecord[]> {
  return $http.get(`${prefix}/options/search`, {
    params: { option, query }
  });
}
