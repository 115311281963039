import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "route.settings" */ "../views/Settings.vue"),
    meta: {
      auth: true
    },
    children: [
      {
        path: "notifications",
        name: "settings_notifications",
        component: () =>
          import(
            /* webpackChunkName: "route.settings.notifications" */ "../views/SettingsNotifications.vue"
          ),
        meta: {
          auth: true,
          tags: ["notifications"],
          breadcrumbs: "Notifications"
        }
      },
      {
        path: "privacy",
        name: "settings_privacy",
        component: () =>
          import(
            /* webpackChunkName: "route.settings.privacy" */ "../views/SettingsPrivacy.vue"
          ),
        meta: {
          auth: true,
          tags: ["privacy"],
          breadcrumbs: "Privacy"
        }
      }
    ]
  }
];

export default routes;
