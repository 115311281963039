import { Auth0ClientOptions } from "@auth0/auth0-spa-js/src/global";
import { RawLocation } from "vue-router";
import { Auth0Client, CacheLocation } from "@auth0/auth0-spa-js";
import _ from "lodash";
import { configuration } from "@/config/dynamic";

export type AppState = {
  targetUrl?: string | RawLocation;
};

export function isAuth0Callback(): boolean {
  return ["code=", "state="].every((subject) =>
    window.location.search.includes(subject)
  );
}

export function isAuth0ErrorCallback(): boolean {
  return ["error=", "error_description=", "state="].every((subject) =>
    window.location.search.includes(subject)
  );
}

function isCacheLocation(location: unknown): location is CacheLocation {
  return (
    typeof location === "string" &&
    ["localstorage", "memory"].includes(location)
  );
}

export function clientFactory(
  options: Partial<Auth0ClientOptions> = {}
): Promise<Auth0Client> {
  return getDefaultOptions().then(
    (defaults) => new Auth0Client(_.merge(defaults, options))
  );
}

export function getRedirectUri(): string {
  return `${window.location.protocol}//${window.location.host}/callback`;
}

export function getDefaultOptions(): Promise<Auth0ClientOptions> {
  return configuration.getInstance().then((config) => {
    return {
      authorizationParams: {
        audience: config.auth0Audience,
        redirect_uri: getRedirectUri(),
      },
      useRefreshTokens: true,
      domain: config.auth0Domain,
      clientId: config.auth0ClientId,
      cacheLocation: isCacheLocation(config.auth0CacheLocation)
        ? config.auth0CacheLocation
        : "localstorage",
    };
  });
}
