import { AxiosPromise } from "axios";
import Dataset from "@/models/Dataset";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass, responseToClass } from "@/api/lib";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";

interface IPaginationParams {
  page?: number;
}

export function list(userId: number, limit = 50): AxiosPromise<Dataset[]> {
  return $http
    .get(`${prefix}/users/${userId}/datasets`, {
      data: {
        limit
      }
    })
    .then(response => responseToClass(Dataset, response));
}

export function listDatasetsInCommon(
  userId: number,
  targetUserid: number,
  params: IPaginationParams
): AxiosPromise<PaginationResponse<Dataset[]>> {
  return $http
    .get(`${prefix}/users/${userId}/datasets/list-in-common`, {
      params: { ...params, compare_with_user_id: targetUserid }
    })
    .then(response => paginatedResponseToClass(Dataset, response));
}
