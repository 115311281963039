import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { IUserState, user } from "./modules/user";
import { http, IHttpState } from "./modules/http";
import { IProjectState, project } from "./modules/project";
import { ITeamState, team } from "./modules/team";
import { INewDatasetState, newDataset } from "./modules/newDataset";
import { datasetView, IDatasetViewState } from "@/store/modules/datasetView";
import { application, IApplicationState } from "@/store/modules/application";
import { downloads, IDownloadState } from "@/store/modules/downloads";
import { IOrganizationState, organization } from "@/store/modules/organization";
import { IMentionState, mention } from "@/store/modules/mention";
import { definitions, DefinitionsState } from "@/store/modules/definitions";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export interface RootState {
  application: IApplicationState;
  datasetView: IDatasetViewState;
  downloads: IDownloadState;
  definitions: DefinitionsState;
  http: IHttpState;
  newDataset: INewDatasetState;
  organization: IOrganizationState;
  project: IProjectState;
  team: ITeamState;
  user: IUserState;
  mention: IMentionState;
}

const store: StoreOptions<RootState> = {
  modules: {
    application,
    datasetView,
    downloads,
    definitions,
    http,
    newDataset,
    organization,
    project,
    team,
    user,
    mention,
  },
  strict: debug,
};

export default new Vuex.Store<RootState>(store);
