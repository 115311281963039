import ITimestamps from "@/models/TimestampsInterface";
import IAsset from "@/models/AssetInterface";
import { IOrdered } from "@/interfaces/behavior/IOrdered";
import DatasetVersion from "@/models/DatasetVersion";
import Project from "@/models/Project";
import { Type } from "class-transformer";
import User from "@/models/User";
import FileModel from "@/models/File";
import DatasetCategory from "@/models/DatasetCategory";
import { EDatasetApplicationType } from "@/staticCollections/datasetApplicationTypeIcons";
import Task from "@/models/Task";
import { IDisplayName } from "@/models/DisplayNameInterface";

export enum DatasetType {
  Dataset = "dataset",
  Directory = "directory",
}

export default class Dataset implements IAsset, ITimestamps, IDisplayName {
  public id: number;
  public hash: string;
  @Type(() => User)
  public authors: User[];
  public parent_id: number;
  @Type(() => Dataset)
  public children: Dataset[];
  public type: DatasetType;
  public application_type: EDatasetApplicationType;
  @Type(() => DatasetVersion)
  public current_version: DatasetVersion;
  @Type(() => DatasetVersion)
  public versions?: DatasetVersion[];
  @Type(() => FileModel)
  public metadata_file: FileModel;
  public project: Project;
  public mime_type: string;
  public name: string;
  public description: string | null = null;
  @Type(() => DatasetCategory)
  public category: DatasetCategory | null = null;
  public size: number;
  public created_at: string;
  public updated_at: string;
  @Type(() => User)
  public creator: User;
  public deleted_at: string;
  public is_locked: boolean;
  @Type(() => User)
  public users: User[];
  @Type(() => Task)
  public tasks: Task[];

  get mainAuthor(): User | null {
    return this.authors && this.authors.length > 0 ? this.authors[0] : null;
  }

  getDisplayName(): string {
    return this.name;
  }
}

export class OrderedDataset extends Dataset implements IOrdered {
  order: number;
}
// export type OrderedDatasetInterface = Dataset & OrderedInterface;
