























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { configuration } from "@/config/dynamic";

@Component
export default class SpecialNavbar extends Vue {
  @Prop({ type: String }) navClass!: string;
  home = "";
  mounted(): void {
    configuration
      .getInstance()
      .then(config => (this.home = config.homeRoute || "news"));
  }
}
