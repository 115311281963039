import User from "@/models/User";
import { Type } from "class-transformer";

export default class Comment {
  id: number;
  content: string | null;
  @Type(() => User)
  user: User;
  loading: boolean;
}
