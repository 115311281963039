import IMetadata from "@/models/MetadataInterface";
import { AxiosPromise } from "axios";
import $http from "@/services/Http";
import { prefix } from "@/api/config";

export function store(
  datasetId: number,
  data: IMetadata
): AxiosPromise<IMetadata> {
  return $http.post(`${prefix}/datasets/${datasetId}/metadata`, data);
}
