import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { AxiosPromise, AxiosRequestConfig } from "axios";

export interface IOpenRequestOptions {
  version: number | null;
  historyMode: number;
  readonly: boolean;
}

export function openRequest(
  datasetId: number | string,
  versionId: number | string | null = null,
  options: IOpenRequestOptions,
  config?: AxiosRequestConfig
): AxiosPromise<Record<string, unknown>> {
  const params: IOpenRequestOptions = Object.assign(
    {
      historyMode: false,
    },
    options
  );

  if (versionId) {
    params.version = versionId;
  }

  params.historyMode = params.historyMode ? 1 : 0;

  return $http.get(
    `${prefix}/datasets/${datasetId}/open-request`,
    Object.assign(config, { params })
  );
}
