import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import AccessCategory from "@/models/AccessCategory";
import { AxiosPromise } from "axios";

export function list(projectId: number): AxiosPromise<AccessCategory[]> {
  return $http
    .get(`${prefix}/projects/${projectId}/access-categories`)
    .then(response => responseToClass(AccessCategory, response));
}

export function available(projectId: number): AxiosPromise<AccessCategory[]> {
  return $http
    .get(`${prefix}/projects/${projectId}/access-categories/available`)
    .then(response => responseToClass(AccessCategory, response));
}

export function synchronize(
  projectId: number,
  accessCategories: AccessCategory[]
): AxiosPromise<AccessCategory[]> {
  const ids = accessCategories.map(ac => ac.id);

  return $http
    .put(`${prefix}/projects/${projectId}/access-categories/synchronize`, {
      accessCategories: ids
    })
    .then(response => responseToClass(AccessCategory, response));
}
