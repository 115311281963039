import User from "@/models/User";
import { Type } from "class-transformer";

export class JoinInvitation {
  public expires_at: string;
  public token?: string;
  @Type(() => User)
  public user: User;
  @Type(() => User)
  public originator: User;
  public created_at: string;
  public status: "active" | "consumed" | "expired";
  public redirect_url: string | null = null;
}
