import { RouteConfig } from "vue-router";
import { ReportConfiguration, ReportScope } from "@/reports/ReportModel";
import * as _ from "lodash";
import { reportConfigurations } from "@/reports/ReportConfigurations";
import { Component } from "vue";
import OrganizationReport from "@/models/OrganizationReport";

const scopeToComponentMap: Record<ReportScope, () => Component> = {
  [ReportScope.Organization]: () =>
    import("../reports/organization/OrganizationDynamicReport.vue"),
  [ReportScope.Team]: () => import("../reports/team/TeamDynamicReport.vue")
};

export const reportConfigToRoute = (
  config: ReportConfiguration,
  scope: ReportScope
): RouteConfig => {
  return {
    path: `${_.kebabCase(config.scope)}_${_.kebabCase(
      config.section
    )}_${_.kebabCase(config.name)}`,
    name: `${_.kebabCase(config.scope)}_${_.kebabCase(
      config.section
    )}_${_.kebabCase(config.name)}`,
    component: scopeToComponentMap[scope],
    props: () => ({ config }),
    meta: {
      auth: true,
      tags: `${_.kebabCase(config.scope)}_${_.kebabCase(
        config.section
      )}_${_.kebabCase(config.name)}`,
      breadcrumbs: config.name
    }
  };
};

export const reportConfigsToRoutes = (scope: ReportScope): RouteConfig[] => {
  return _.chain(reportConfigurations)
    .filter({ scope })
    .map(config => reportConfigToRoute(config, scope))
    .value();
};

export const reportsConfigsToDynamicNavigationConfig: (
  scope: ReportScope,
  reports: OrganizationReport[]
) => DynamicNavigationSection[] = (scope, reports) => {
  return _.chain(reportConfigurations)
    .filter({ scope })
    .filter(config => {
      return (
        _.find(reports, report => {
          return report.name === config.name;
        }) !== undefined
      );
    })
    .groupBy("section")
    .map((items, key) => ({
      label: key,
      name: _.kebabCase(key),
      links: items.map(item => ({
        label: item.name,
        name: `${_.kebabCase(item.scope)}_${_.kebabCase(key)}_${_.kebabCase(
          item.name
        )}`
      }))
    }))
    .value();
};

export interface DynamicNavigationSection {
  label: string;
  name: string;
  links: DynamicNavigationLink[];
}

export interface DynamicNavigationLink {
  label: string;
  name: string;
}
