import { AxiosPromise } from "axios";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import { JoinInvitation } from "@/models/JoinInvitation";

export function show(token: string): AxiosPromise<JoinInvitation> {
  return $http
    .get(`${prefix}/invitations/${token}`)
    .then(response => responseToClass(JoinInvitation, response));
}

export function consume(
  token: string,
  data: Record<string, unknown>
): AxiosPromise<void> {
  return $http.post(`${prefix}/invitations/${token}/consume`, data);
}
