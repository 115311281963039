import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  FilterVariables,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType,
} from "@/reports/ReportModel";
import { userLinkAccessorBuilder } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["MvStudents.countryList"],
  order: {
    "MvStudents.WeEndsAt": "desc",
  },
  filters: [
    {
      member: "MvStudents.WeCompanyTeamId",
      operator: "equals",
      values: [FilterVariables.TeamId],
    },
  ],
  dimensions: [
    "MvStudents.id", // Required for LinkAccessor
    "MvStudents.name",
    "MvStudents.universityName",
    "MvStudents.visa",
    "MvStudents.exportControlStatus",
    "MvStudents.WeCompanyName",
    "MvStudents.WeCompanyCategory",
    "MvStudents.WeStartsAt",
    "MvStudents.WeEndsAt",
    "MvStudents.WeDegreeDegree",
    "MvStudents.WeDegreeField",
    "MvStudents.WeDegreeGraduationDate",
    "MvStudents.country",
  ],
  segments: ["MvStudents.onlyHires", "MvStudents.notDeleted"],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "MvStudents.name",
    label: "User Name",
  },
  {
    dimension: "MvStudents.country",
    label: "Citizenship",
  },
  {
    dimension: "MvStudents.visa",
    label: "US Visa Status",
  },
  {
    label: "Export Control Status",
    dimension: "MvStudents.exportControlStatus",
  },
  {
    dimension: "MvStudents.WeDegreeDegree",
    label: "Degree Type",
  },
  {
    dimension: "MvStudents.WeDegreeField",
    label: "Degree Field",
  },
  {
    dimension: "MvStudents.BusinessEntity",
    label: "Business Entity",
  },
  {
    dimension: "MvStudents.Center",
    label: "Center",
  },
  {
    dimension: "MvStudents.Theme",
    label: "Theme",
  },
  {
    dimension: "MvStudents.Thrust",
    label: "Thrust",
  },
  {
    dimension: "MvStudents.Subthrust",
    label: "Subthrust",
  },
  {
    dimension: "MvStudents.Area",
    label: "Science Area",
  },
  {
    dimension: "MvStudents.SeismicShift",
    label: "Seismic Shift",
  },
  {
    dimension: "MvStudents.ResearchScholar",
    label: "Research Scholar",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Student ID",
    key: "MvStudents.id",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Student name",
    key: "MvStudents.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder("MvStudents.id"),
  },
  {
    title: "Student University",
    key: "MvStudents.universityName",
    type: ReportColumnType.Text,
  },
  {
    title: "Hire Organization",
    key: "MvStudents.WeCompanyName",
    type: ReportColumnType.Text,
  },
  {
    title: "Hire Date (From)",
    key: "MvStudents.WeStartsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Hire Date (To)",
    key: "MvStudents.WeEndsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Hire Category",
    key: "MvStudents.WeCompanyCategory",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Type",
    key: "MvStudents.WeDegreeDegree",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Field",
    key: "MvStudents.WeDegreeField",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Graduation Date",
    key: "MvStudents.WeDegreeGraduationDate",
    type: ReportColumnType.Date,
  },
  {
    title: "Citizenships",
    key: "MvStudents.countryList",
    type: ReportColumnType.Text,
  },
  {
    title: "Visa",
    key: "MvStudents.visa",
    type: ReportColumnType.Text,
  },
  {
    title: "US Visa status",
    key: "MvStudents.visa",
    type: ReportColumnType.Text,
  },
  {
    title: "Export Control Status",
    key: "MvStudents.exportControlStatus",
    type: ReportColumnType.Text,
  },
];

export const FirstHires452MReportConfig: ReportConfiguration = {
  name: "First Hires (452M)",
  section: "Student",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "MvStudents.WeEndsAt",
    endsAt: "MvStudents.WeEndsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
};
