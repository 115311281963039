import DefinitionTable from "@/models/DefinitionTable";

export default class UserCategory extends DefinitionTable {}

export enum UserCategories {
  Student = "student",
  Professor = "professor",
  Employee = "employee"
}

export const PostdoctoralResearcher = "postdoctoral_researcher";
