export class LocalStorageHelper {
  static get(key: string): Record<string, unknown> | undefined {
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    } else {
      return undefined;
    }
  }

  static put(key: string, item: unknown): void {
    localStorage.setItem(key, JSON.stringify(item));
  }

  static remove(key: string): void {
    localStorage.removeItem(key);
  }

  static clear(): void {
    localStorage.clear();
  }
}
