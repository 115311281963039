import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import Task from "@/models/Task";
import { AxiosPromise, AxiosResponse } from "axios";
import UpdateFormRequest from "@/forms/requests/task/UpdateFormRequest";

export function remove(taskId: number): AxiosPromise<Task> {
  return $http
    .delete(`${prefix}/tasks/${taskId}`)
    .then(response => responseToClass(Task, response) as AxiosResponse<Task>);
}

/**
 * @param taskId
 * @param complete set to false to mark as incomplete instead
 */
export function markAsComplete(
  taskId: number,
  complete = true
): AxiosPromise<Task> {
  return $http
    .put(`${prefix}/tasks/${taskId}/mark-completed`, { complete })
    .then(response => responseToClass(Task, response) as AxiosResponse<Task>);
}

export function update(
  taskId: number,
  data: UpdateFormRequest
): AxiosPromise<Task> {
  return $http
    .put(`${prefix}/tasks/${taskId}`, data)
    .then(response => responseToClass(Task, response) as AxiosResponse<Task>);
}
