import $http from "@/services/Http";
import { responseToClass } from "@/api/lib";
import { AxiosPromise } from "axios";
import Protocol from "@/models/Protocol";
import { prefix } from "@/api/config";
import * as Attachment from "@/api/namespaces/protocol/attachment";
import * as ProtocolVersion from "@/api/namespaces/protocol/protocolVersion";

export { Attachment, ProtocolVersion };

export function list(type: "team" | "project") {
  return $http
    .get(`${prefix}/protocols`, { params: { type } })
    .then(response => responseToClass(Protocol, response));
}

export function show(protocolId: number): AxiosPromise<Protocol> {
  return $http
    .get(`${prefix}/protocols/${protocolId}`)
    .then(response => responseToClass(Protocol, response));
}

export function update(protocolId: number, data) {
  return $http.put(`${prefix}/protocols/${protocolId}`, data);
}

export function tags(): AxiosPromise<string[]> {
  return $http.get(`${prefix}/protocol-tags`);
}

export function remove(protocolId: number): AxiosPromise<void> {
  return $http.delete(`${prefix}/protocols/${protocolId}`);
}
