import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/submit-project-results",
    name: "publication_create",
    component: () =>
      import(
        /* webpackChunkName: "route.publication-form.create" */ "../views/Publication.vue"
      ),
    meta: {
      auth: true,
      breadcrumbs: "Add a Dataset"
    }
  },
  {
    path: "/projects/:project_id/submit-project-results",
    name: "project_publication_create",
    component: () =>
      import(
        /* webpackChunkName: "route.publication-form.create" */ "../views/Publication.vue"
      ),
    meta: {
      auth: true,
      breadcrumbs: "Add a Dataset"
    }
  }
];

export default routes;
