import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import Mentionable from "@/models/Mentionable";
import { AxiosPromise } from "axios";

export function tags(tags: string[]): AxiosPromise<Mentionable[]> {
  return $http
    .post(`${prefix}/mentions/tags`, { tags })
    .then(response => responseToClass(Mentionable, response));
}
