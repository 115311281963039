import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import * as AccessCategory from "@/api/namespaces/organization/accessCategory";
import * as DatasetCategory from "@/api/namespaces/organization/datasetCategory";
import * as Project from "@/api/namespaces/organization/project";
import * as ProjectParticipation from "@/api/namespaces/organization/projectParticipation";
import * as ResearchAxis from "@/api/namespaces/organization/researchAxis";
import * as Team from "@/api/namespaces/organization/team";
import * as User from "@/api/namespaces/organization/user";

export {
  AccessCategory,
  DatasetCategory,
  Project,
  ProjectParticipation,
  ResearchAxis,
  Team,
  User,
};

export function metrics<T extends EMetrics>(
  organizationId: number,
  params: MetricsFormRequest<T>,
  config: AxiosRequestConfig = {}
): AxiosPromise<MetricsReturnType<T>> {
  return $http
    .get(
      `${prefix}/organizations/${organizationId}/metrics`,
      Object.assign({}, config, { params })
    )
    .then((response) => {
      if (config.responseType === "blob") {
        return response;
      }

      return metricsResponseHandler(params.metric, response);
    });
}
