import * as AccessCategory from "@/api/namespaces/project/accessCategory";
import * as Customer from "@/api/namespaces/project/customer";
import * as Dataset from "@/api/namespaces/project/dataset";
import * as DatasetCategory from "@/api/namespaces/project/datasetCategory";
import * as Note from "@/api/namespaces/project/note";
import * as Protocol from "@/api/namespaces/project/protocol";
import * as Report from "@/api/namespaces/project/report";
import * as Task from "@/api/namespaces/project/task";
import * as User from "@/api/namespaces/project/user";
import $http from "@/services/Http";
import { responseToClass } from "@/api/lib";
import { prefix } from "@/api/config";
import { AxiosPromise } from "axios";
import Mentionable, { EMentionableType } from "@/models/Mentionable";
import Project from "@/models/Project";

export {
  AccessCategory,
  Customer,
  Dataset,
  DatasetCategory,
  Note,
  Protocol,
  Report,
  Task,
  User,
};

export function searchMentionables(
  projectId: number,
  query = "",
  filter: EMentionableType[] = []
): AxiosPromise<Mentionable> {
  return $http
    .get(`${prefix}/projects/${projectId}/search-mentionables`, {
      params: { query, filter },
    })
    .then((response) => responseToClass(Mentionable, response));
}

export function show(projectId: number): AxiosPromise<Project> {
  return $http
    .get(`${prefix}/projects/${projectId}`)
    .then((response) => responseToClass(Project, response));
}

export function update(
  projectId: number,
  data: Partial<Project>
): AxiosPromise<Project> {
  return $http
    .put(`${prefix}/projects/${projectId}`, data)
    .then((response) => responseToClass(Project, response));
}

export function archive(
  projectId: number,
  archive = true
): AxiosPromise<Project> {
  return $http
    .post(`${prefix}/projects/${projectId}/archive`, { archive })
    .then((response) => responseToClass(Project, response));
}

export function remove(projectId: number): AxiosPromise<Project> {
  return $http
    .delete(`${prefix}/projects/${projectId}`)
    .then((response) => responseToClass(Project, response));
}

export function tags(): AxiosPromise<string[]> {
  return $http.get(`${prefix}/project-tags`);
}
