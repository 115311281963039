import { Type } from "class-transformer";
import Company from "@/models/Company";

export default class WorkExperience {
  public id = 0;
  public title: string | null = null;
  @Type(() => Date)
  public starts_at: Date | null = null;
  @Type(() => Date)
  public ends_at: Date | null = null;
  public employment_type_code: string | null = null;
  public employer: Company | null = null;
  public description: string | null = null;
}
