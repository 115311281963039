import { RouteConfig } from "vue-router";
import { TeamRolesEnum } from "@/staticCollections/teamRoles";
import { reportConfigsToRoutes } from "@/reports/ReportNavUtils";
import { ReportScope } from "@/reports/ReportModel";

const routes: RouteConfig[] = [
  {
    path: "/teams/:id",
    name: "team",
    component: () =>
      import(/* webpackChunkName: "route.team" */ "../views/Team.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "overview",
        name: "team_overview",
        component: () =>
          import(
            /* webpackChunkName: "route.team.overview" */ "../views/TeamOverview.vue"
          ),
        meta: {
          auth: true,
          tags: ["team_overview"],
          breadcrumbs: "Overview",
        },
      },
      {
        path: "tags",
        name: "team_tags",
        component: () =>
          import(
            /* webpackChunkName: "route.team.tags" */ "../views/TeamTags.vue"
          ),
        meta: {
          auth: true,
          tags: ["team_tags"],
          breadcrumbs: "Tags",
        },
      },
      {
        path: "users",
        name: "team_users",
        component: () =>
          import(
            /* webpackChunkName: "route.team.users" */ "../views/TeamUsers.vue"
          ),
        meta: {
          auth: true,
          tags: ["team_users"],
          breadcrumbs: "Users",
        },
      },
      {
        path: "settings",
        name: "team_settings",
        component: () =>
          import(
            /* webpackChunkName: "route.team.settings" */ "../views/TeamSettings.vue"
          ),
        meta: {
          auth: true,
          tags: ["team_settings"],
          breadcrumbs: "Settings",
          requiredTeamRoles: [TeamRolesEnum.Admin],
        },
      },
      ...reportConfigsToRoutes(ReportScope.Team),
    ],
  },
];

export default routes;
