import $http from "@/services/Http";
import { prefix } from "@/api/config";
import * as Comment from "@/api/namespaces/dataset/protocol/comment";
import { AxiosPromise } from "axios";

export { Comment };

export function detach(
  datasetId: number,
  protocolId: number
): AxiosPromise<void> {
  return $http.delete(
    `${prefix}/datasets/${datasetId}/protocols/${protocolId}`
  );
}
