export default class UpdateFormRequest {
  public first_name: string | null = null;
  public last_name: string | null = null;
  public email: string | null = null;
  public enroll_2fa = false;
  public permissions: string[] = [];
  public allow_edit_profile_photo = false;
  public work_email: string | null = null;
  public work_phone: string | null = null;
  public professional_title: string | null = null;
  public faculty_status: string | null = null;
  public university_affiliation_id: number | null = null;
  public faculty_affiliation: string | null = null;
  public departmental_affiliation: string | null = null;
  public program_affiliation: string | null = null;
  public other_functions: string[] | null = [];
  public short_description: string | null = null;
  public office_no: string | null = null;
  public google_scholar_id: string | null = null;
  public research_gate_id: string | null = null;
  public orcid_id: string | null = null;
  public linkedin_id: string | null = null;
  public linkedin_page_confirmed: boolean | null = null;
  public organization_page_url: string | null = null;
  public personal_page_url: string | null = null;
  public expires_at: string | null = null;
  public research_axes: string[] = [];
  public gender_code: string | null = null;
  public pronoun_code: string | null = null;
  public race_code: string | null = null;
  public visa_code: string | null = null;
  public category_code: string | null = null;
  public export_control_status_code: string | null = null;
  public citizenship: string[] | null = null;
  public lawful_permanent_residency_status_code: string | null = null;
  public available_for_hire_date: Date | null = null;
  public available_for_internship_date: Date | null = null;
  public onboarding_completed = false;
  public agreement_personal_data = false;
  public openalex_id: string | null = null;
  public interests: string[] = [];
}
