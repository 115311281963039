import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import DatasetCategory from "@/models/DatasetCategory";
import { AxiosPromise } from "axios";
import CreateViewModel from "@/forms/models/organization/datasetCategory/CreateViewModel";

export function update(
  datasetCategoryId: number,
  data: CreateViewModel
): AxiosPromise<DatasetCategory> {
  return $http
    .put(`${prefix}/dataset-categories/${datasetCategoryId}`, data)
    .then(response => responseToClass(DatasetCategory, response));
}

export function remove(
  datasetCategoryId: number
): AxiosPromise<DatasetCategory> {
  return $http
    .delete(`${prefix}/dataset-categories/${datasetCategoryId}`)
    .then(response => responseToClass(DatasetCategory, response));
}
