import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass, responseToClass } from "@/api/lib";
import { AxiosPromise } from "axios";
import ProjectParticipation from "@/models/ProjectParticipation";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";
import { PaginationParams } from "@/interfaces/api/PaginationParams";

export function list(
  organizationId: number,
  projectId: number,
  params: PaginationParams
): AxiosPromise<PaginationResponse<ProjectParticipation[]>> {
  return $http
    .get(
      `${prefix}/organizations/${organizationId}/projects/${projectId}/participations`,
      {
        params
      }
    )
    .then(response => paginatedResponseToClass(ProjectParticipation, response));
}
