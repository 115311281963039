import $http from "@/services/Http";
import { responseToClass } from "@/api/lib";
import Protocol from "@/models/Protocol";
import { prefix } from "@/api/config";
import { AxiosPromise } from "axios";

export function list(
  teamId: number,
  only: "team" | "project" | null = null
): AxiosPromise<Protocol[]> {
  return $http
    .get(`${prefix}/teams/${teamId}/protocols`, { params: only })
    .then(response => responseToClass(Protocol, response));
}

export function store(teamId: number, data) {
  return $http.post(`${prefix}/teams/${teamId}/protocols`, data);
}
