import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionType,
} from "@/reports/ReportModel";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";
import {
  ProjectLeaderLinkAccessor,
  ProjectLinkAccessor,
} from "@/reports/ReportLinkAccessors";

const query: Query = {
  order: [["Users.email", "asc"]],
  dimensions: [
    "Projects.id",
    "Projects.name",
    "ProjectLeader.id",
    "ProjectLeader.name",
    "ProjectTags.label",
    "Projects.startsAt",
    "Projects.endsAt",
  ],
  filters: [
    {
      member: "ProjectTags.label",
      operator: "startsWith",
      values: ["CER-"],
    },
  ],
  segments: [
    "Projects.notDeleted",
    "ProjectTags.notDeleted",
    "ProjectLeader.notDeleted",
  ],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Project's Name",
    key: "Projects.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
  },
  {
    title: "Project Leader",
    key: "ProjectLeader.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLeaderLinkAccessor,
  },
  {
    title: "Ethics Tag",
    key: "ProjectTags.label",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Start Date",
    key: "Projects.startsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Project End Date",
    key: "Projects.endsAt",
    type: ReportColumnType.Date,
  },
];

export const Ethics: ReportConfiguration = {
  name: "Ethics",
  section: "Project",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Projects.startsAt",
    endsAt: "Projects.endsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
};
