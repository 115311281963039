import ITimestamps from "@/models/TimestampsInterface";
import User from "@/models/User";
import { RoleRelationshipInterface } from "@/interfaces/behavior/RoleRelationshipInterface";
import { Roles } from "@/staticCollections/projectRoles";
import { Type } from "class-transformer";
import Team from "@/models/Team";
import Project from "@/models/Project";
import University from "@/models/University";

export default class ProjectParticipation
  implements ITimestamps, RoleRelationshipInterface<Roles> {
  public created_at: string;
  public ends_at: string | null;
  public deleted_at: string | null;
  public id: number;
  @Type(() => Project)
  public project: Project;
  public project_id: number;
  public project_role: string | null;
  public starts_at: string;
  @Type(() => Team)
  public team: Team;
  public team_id: number;
  @Type(() => University)
  public university?: University | null;
  public university_id: string | null;
  public updated_at: string;
  @Type(() => User)
  public user: User;
  public user_id: number;

  get isFinished(): boolean {
    if (!this.ends_at) {
      return false;
    }

    const now = new Date();
    const endsAt = new Date(this.ends_at);

    return now.getTime() >= endsAt.getTime();
  }
}
