/**
 * Use this function to load external resource (either css or js)
 * @param url
 * @param id
 * @param type
 * @param parentTag
 */
export function loadResource(
  url: string,
  id: string,
  type: "js" | "css" = "js",
  parentTag: "body" | "head" = "head"
): void {
  if (document.getElementById(id)) {
    return;
  }

  let resource: HTMLScriptElement | HTMLLinkElement;

  if (type === "js") {
    resource = document.createElement("script");
    resource.type = "text/javascript";
    resource.src = url;
  } else {
    resource = document.createElement("link");
    resource.rel = "stylesheet";
    resource.href = url;
  }

  resource.id = id;

  document.getElementsByTagName(parentTag)[0].appendChild(resource);
}

export function scrollToHash() {
  if (window.location.hash) {
    scrollToId(window.location.hash.substring(1));
  }
}

export function scrollToId(id: string) {
  const elem = document.getElementById(id)

  if (elem) {
    elem.scrollIntoView();
  }
}
