import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import { JoinInvitation } from "@/models/JoinInvitation";
import { AxiosPromise } from "axios";

export function renew(userId: number): AxiosPromise<JoinInvitation> {
  return $http
    .post(`${prefix}/users/${userId}/invitations`)
    .then(response => responseToClass(JoinInvitation, response));
}
