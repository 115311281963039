import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass, responseToClass } from "@/api/lib";
import { AxiosPromise } from "axios";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";
import ResearchAxis from "@/models/ResearchAxis";

export interface IResearchAxisOptions {
  page?: number;
  query?: string;
  label?: string;
  sortBy?: string;
  order?: string;
}

export function list(
  organizationId: number,
  params: IResearchAxisOptions
): AxiosPromise<PaginationResponse<ResearchAxis[]>> {
  return $http
    .get(`${prefix}/organizations/${organizationId}/research-axes`, {
      params
    })
    .then(response => paginatedResponseToClass(ResearchAxis, response));
}
