// export const unique = Symbol('unique');
//
// Object.defineProperty(Array.prototype, unique, {
//   value: function () {
//     return [].concat(this).filter((elem, index, self) => self.indexOf(elem) === index);
//   }
// });

export function intersect<T>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter(value => arr2.includes(value));
}

export function diff<T>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter(value => !arr2.includes(value));
}

type ElementOrCallback<T> = T | ((elem: T) => boolean);

export function remove<T>(
  arr: T[],
  value: ElementOrCallback<T>,
  multiple = false
): T[] {
  const fn = typeof value === "function" ? "findIndex" : "indexOf";

  let index = -1;
  do {
    index = arr[fn](value);

    if (index >= 0) {
      arr.splice(index, 1);
    }
  } while (index >= 0 && multiple);

  return arr;
}

export function convertFileListToArray(files: FileList | null): File[] {
  if (!files) {
    return [];
  }
  const arr: File[] = [];
  for (let i = 0; i < files.length; i++) {
    // I didn't use .item(index) because it can return a nullable value
    arr.push(files[i]);
  }
  return arr;
}
