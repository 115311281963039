import ElectronicSignatureForm from "@/components/signature/ElectronicSignatureForm";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { Ordered } from "@/interfaces/behavior/IOrdered";
import IModel from "@/models/IModel";
import { AxiosPromise } from "axios";
import ElectronicSignature from "@/models/ElectronicSignature";
import { responseToClass } from "@/api/lib";
import { SignatoryActions } from "@/models/Signatory";

export function store(
  noteVersionId: number,
  form: ElectronicSignatureForm<Ordered<IModel>>
): AxiosPromise<ElectronicSignature> {
  return $http
    .post(
      `${prefix}/note-versions/${noteVersionId}/electronic-signatures`,
      form
    )
    .then(response => responseToClass(ElectronicSignature, response));
}

export function sign(
  noteVersionId: number,
  esId: number,
  statement = "",
  action: SignatoryActions
): AxiosPromise<ElectronicSignature> {
  return $http
    .put(
      `${prefix}/note-versions/${noteVersionId}/electronic-signatures/${esId}`,
      { statement, action }
    )
    .then(response => responseToClass(ElectronicSignature, response));
}
