/**
 * This dirty hack fixes the daylight saving issues with toast-ui-calendar
 * This bug fix can be remove when this PR is merged: https://github.com/nhn/tui.calendar/pull/705
 */

import Calendar from "tui-calendar";

Calendar.setTimezoneOffsetCallback(timestamp =>
  new Date(timestamp).getTimezoneOffset()
);
