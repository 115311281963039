import { Type } from "class-transformer";
import FileModel from "@/models/File";

export default class LicenseAgreement {
  public id: number;
  public note: string;
  @Type(() => FileModel)
  public document: FileModel;
  @Type(() => FileModel)
  public privacy_policy: FileModel;
}
