import { isModelObject } from "@/models/IModel";
import _ from "lodash";

export function exclude<T, K extends keyof T>(
  source: T,
  fields: K[],
  shouldClone = true,
  baseObject: Partial<T> = {}
): Pick<T, K> {
  const target: T = shouldClone ? Object.assign(baseObject, source) : source;

  fields.forEach(f => delete target[f]);

  return target;
}

export function only<T, K extends keyof T>(
  source: T,
  fields: K[],
  shouldClone = true,
  baseObject: Partial<T> = {}
): Pick<T, K> {
  const target: T = shouldClone ? Object.assign(baseObject, source) : source;

  for (const key in target) {
    if (!fields.includes(key)) {
      delete target[key];
    }
  }

  return target;
}

/**
 * Uses the fields of "output" to extract data from "input" into "output"
 * @param output
 * @param input
 * @param shouldClone
 */
export function extract<T, U>(output: U, input: T, shouldClone = false): U {
  const inputKeys = Object.keys(input);
  const fields = Object.keys(output).filter(key =>
    inputKeys.includes(key)
  ) as Array<keyof U & keyof T>;

  return shouldClone
    ? Object.assign({}, output, only(input, fields))
    : Object.assign(output, only(input, fields));
}

export function isSame<T>(object1: T, object2: T): boolean {
  const keys1 = Object.keys(object1) as Array<keyof typeof object1>;
  const keys2 = Object.keys(object2) as Array<keyof typeof object2>;

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every(k1 => {
    if (!keys2.includes(k1)) {
      return false;
    }

    const value1 = object1[k1];
    const value2 = object2[k1];
    if (isModelObject(value1) && isModelObject(value2)) {
      return value1.id === value2.id;
    }

    return value1 === value2;
  });
}

/**
 * Deep copy an object
 * @param object
 */
export function copyObject<T>(object: T): T {
  return _.cloneDeep(object);
}

/**
 * Compare objects
 * Can be unrelieable in certain situations: https://www.mattzeunert.com/2016/01/28/javascript-deep-equal.html
 * @param objects
 */
export function deepEquals(...objects: Array<T>): boolean {
  return !objects.every(
    obj => JSON.stringify(obj) === JSON.stringify(objects[0])
  );
}
