import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType,
} from "@/reports/ReportModel";
import { userLinkAccessorBuilder } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["MvStudents.countryList"],
  order: {
    "MvStudents.WeStartsAt": "desc",
  },
  filters: [
    // {
    //   member: "MvStudents.ProjectParticipationUserCategoryCode",
    //   operator: "equals",
    //   values: ["student"],
    // },
  ],
  dimensions: [
    "MvStudents.id", // Required for LinkAccessor
    "MvStudents.firstName",
    "MvStudents.lastName",
    "MvStudents.name",
    "MvStudents.universityName",
    "MvStudents.linkedInId",
    "MvStudents.email",
    "MvStudents.availableForHireAt",
    "MvStudents.gender",
    "MvStudents.race",
    "MvStudents.visa",
    "MvStudents.lawfulPermanentResidencyStatus",
    "MvStudents.exportControlStatus",
    "MvStudents.exitStatus",
    "MvStudents.WeCompanyName",
    "MvStudents.WeCompanyCategory",
    "MvStudents.WeStartsAt",
    "MvStudents.WeEndsAt",
    "MvStudents.WeTitle",
    "MvStudents.ResumeId",
    "MvStudents.ResumeName",
    "MvStudents.WeDegreeDegree",
    "MvStudents.WeDegreeField",
    "MvStudents.BusinessEntity",
    "MvStudents.Center",
    "MvStudents.SeismicShift",
    "MvStudents.Thrust",
    "MvStudents.Subthrust",
    "MvStudents.Theme",
    "MvStudents.Area",
    "MvStudents.country",
  ],
  segments: ["MvStudents.onlyInternships", "MvStudents.notDeleted"],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "MvStudents.name",
    label: "User Name",
  },
  {
    dimension: "MvStudents.WeCompanyName",
    label: "Internship Organization",
  },
  {
    dimension: "MvStudents.lastName",
    label: "Student Last Name",
  },
  {
    dimension: "MvStudents.firstName",
    label: "Student First Name",
  },
  {
    dimension: "MvStudents.universityName",
    label: "University",
  },
  {
    dimension: "MvStudents.gender",
    label: "Gender",
  },
  {
    dimension: "MvStudents.race",
    label: "Ethnicity",
  },
  {
    dimension: "MvStudents.country",
    label: "Citizenship",
  },
  {
    dimension: "MvStudents.visa",
    label: "US Visa Status",
  },
  {
    dimension: "MvStudents.WeDegreeDegree",
    label: "Degree Type",
  },
  {
    dimension: "MvStudents.WeDegreeField",
    label: "Degree Field",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Student ID",
    key: "MvStudents.id",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Student name",
    key: "MvStudents.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder("MvStudents.id"),
  },
  {
    title: "Student University",
    key: "MvStudents.universityName",
    type: ReportColumnType.Text,
  },
  {
    title: "Student email",
    key: "MvStudents.email",
    type: ReportColumnType.Text,
  },
  {
    title: "Company name",
    key: "MvStudents.WeCompanyName",
    type: ReportColumnType.Text,
  },
  {
    title: "Internship start date",
    key: "MvStudents.WeStartsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Internship end date",
    key: "MvStudents.WeEndsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Internship company category",
    key: "MvStudents.WeCompanyCategory",
    type: ReportColumnType.Text,
  },
  {
    title: "Internship title",
    key: "MvStudents.WeTitle",
    type: ReportColumnType.Text,
  },
  {
    title: "Available for hire date",
    key: "MvStudents.availableForHireAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Student LinkedIn",
    key: "MvStudents.linkedInId",
    type: ReportColumnType.Text,
  },
  {
    title: "Resume",
    key: "MvStudents.ResumeName",
    fileIdMember: "MvStudents.ResumeId",
    type: ReportColumnType.File,
  },
  {
    title: "Gender",
    key: "MvStudents.gender",
    type: ReportColumnType.Text,
  },
  {
    title: "Ethnicity",
    key: "MvStudents.race",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Type",
    key: "MvStudents.WeDegreeDegree",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Field",
    key: "MvStudents.WeDegreeField",
    type: ReportColumnType.Text,
  },
  {
    title: "Citizenships",
    key: "MvStudents.countryList",
    type: ReportColumnType.Text,
  },
  {
    title: "US Visa status",
    key: "MvStudents.visa",
    type: ReportColumnType.Text,
  },
  {
    title: "Green Card",
    key: "MvStudents.lawfulPermanentResidencyStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Export Control Status",
    key: "MvStudents.exportControlStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Business Entity",
    key: "MvStudents.BusinessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "MvStudents.SeismicShift",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "MvStudents.Thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "MvStudents.Subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "MvStudents.Theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "MvStudents.Center",
    type: ReportColumnType.Text,
  },
  {
    title: "Science Area",
    key: "MvStudents.Area",
    type: ReportColumnType.Text,
  },
  {
    title: "Exit Status",
    key: "MvStudents.exitStatus",
    type: ReportColumnType.Text,
  },
];

export const SCH102InternshipsPerProgramReportConfig: ReportConfiguration = {
  name: "Internships by Program (405)",
  section: "Student",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "MvStudents.WeStartsAt",
    endsAt: "MvStudents.WeEndsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
};
