import TimestampsInterface from "@/models/TimestampsInterface";
import Team from "@/models/Team";
import IModel from "@/models/Base";
import { RoleRelationshipInterface } from "@/interfaces/behavior/RoleRelationshipInterface";
import { Roles } from "@/staticCollections/projectRoles";
import { Type } from "class-transformer";
import { TeamUser } from "@/models/TeamUser";
import Publication from "@/models/Publication";
import { JoinInvitation } from "@/models/JoinInvitation";
import File from "@/models/File";
import UserPermissions from "@/models/UserPermissions";
import Organization from "@/models/Organization";
import Activity from "@/models/Activity";
import University from "@/models/University";
import Formation from "@/models/Formation";
import { ProjectUser } from "@/models/ProjectUser";
import Project from "@/models/Project";
import moment from "moment";
import { ACCOUNT_EXPIRATION_THRESHOLD_DAYS } from "@/config/app";
import Dataset from "@/models/Dataset";
import WorkExperience from "@/models/WorkExperience";
import Address from "@/models/Address";
import Country from "@/models/Country";
import UserCategory, { UserCategories } from "@/models/UserCategory";
import {
  getAffiliation,
  getDisambiguatedUser,
} from "@/components/user/userDisambiguation";
import { IDisplayName } from "@/models/DisplayNameInterface";
import { OrganizationRoles } from "@/models/enum/OrganizationRoles";
import ManagementGroup from "@/models/ManagementGroup";
import Concept from "@/models/Concept";

export interface IAuth0User {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

export default class User
  implements
    IModel,
    IAuth0User,
    TimestampsInterface,
    RoleRelationshipInterface<Roles>,
    IDisplayName
{
  public id: number;
  public hash: string;
  public email: string;
  public first_name = "";
  public last_name = "";
  public enroll_2fa: boolean;
  public permissions: UserPermissions[];
  public pivot?: TeamUser | ProjectUser;
  @Type(() => Team)
  public teams: Team[] = [];
  public created_at: string;
  public updated_at: string;
  @Type(() => Date)
  public last_action_at: Date | null;
  public comment_mention_notifications?: boolean;
  public user_joins_project_notifications?: boolean;
  public signature_reminder_notifications?: boolean;
  public task_weekly_summary_notifications?: boolean;
  public task_assigned_notifications?: boolean;
  public task_updated_notifications?: boolean;
  public task_uncompleted_notifications?: boolean;
  public marketing_accepted_at?: boolean;
  public update_work_experiences_reminder_notifications?: boolean;
  public is_profile_private?: boolean;
  public active?: boolean;
  public professional_title: string;
  public work_email: string;
  public work_phone: string;
  public faculty_status: string;
  public faculty_affiliation: string;
  public departmental_affiliation: string;
  public program_affiliation: string;
  public other_functions: string[];
  public short_description: string;
  public has_accepted_license_agreement: boolean;
  public onboarding_completed: boolean;
  @Type(() => Publication)
  public publications: Publication[];
  public orcid_id: string;
  @Type(() => JoinInvitation)
  public invitations?: JoinInvitation[];
  @Type(() => File)
  public profile_photo: File | null;
  public allow_edit_profile_photo?: boolean;
  @Type(() => Organization)
  public organization: Organization;
  public organization_role: OrganizationRoles = OrganizationRoles.Member;
  @Type(() => Activity)
  public activities?: Activity[];
  @Type(() => University)
  public university_affiliation: University;
  public email_verified: boolean;
  public nickname: string;
  public picture: string;
  public sub: string;
  public office_no: string;
  @Type(() => Formation)
  public formations: Formation[];
  public auth0UserId: string;
  @Type(() => Project)
  public projects: Project[];
  @Type(() => Project)
  public former_projects: Project[];
  @Type(() => Project)
  public accessCategoryProjects: Project[];
  @Type(() => Team)
  public main_team: Team | null = null;
  public deleted_at?: string | null = null;
  @Type(() => Date)
  public expires_at?: Date | null = null;
  @Type(() => Dataset)
  public datasets: Dataset[] = [];
  @Type(() => File)
  public resume: File | null = null;
  public gender_code: string | null = null;
  public pronoun_code: string | null = null;
  public race_code: string | null = null;
  public visa_code: string | null = null;
  public export_control_status_code: string | null = null;
  public citizenship: Country[] | null = null;
  public lawful_permanent_residency_status_code: string | null = null;
  @Type(() => Date)
  public available_for_hire_date: Date | null = null;
  @Type(() => Date)
  public available_for_internship_date: Date | null = null;
  @Type(() => WorkExperience)
  public work_experiences: WorkExperience[] = [];
  @Type(() => Address)
  public work_address: Address | null = null;
  public category_code: string | null = null;
  @Type(() => UserCategory)
  public category: UserCategory | null = null;
  public agreement_personal_data = false;
  @Type(() => ManagementGroup)
  public management_groups: ManagementGroup[];
  public interests_count = 0;
  public openalex_id: string | null = null;
  @Type(() => Concept)
  public interests: Concept[] = [];

  get name(): string {
    if (!this.last_name) {
      return this.first_name;
    }

    return `${this.first_name} ${this.last_name}`;
  }

  get isExpired(): boolean {
    if (!this.expires_at) {
      return false;
    }

    return moment(this.expires_at).isBefore(moment());
  }

  get isExpireSoon(): boolean {
    if (!this.expires_at) {
      return false;
    }

    return moment(this.expires_at).isBefore(
      moment().add(ACCOUNT_EXPIRATION_THRESHOLD_DAYS, "days")
    );
  }

  get isStudent(): boolean {
    return this.category_code === UserCategories.Student;
  }

  get affiliation(): string {
    return getAffiliation(this); // todo, bring back the function implementation here
  }

  get disambiguatedName(): string {
    return getDisambiguatedUser(this); // todo, bring back the function implementation here
  }

  getDisplayName(): string {
    return this.name;
  }

  get isOrgAdmin(): boolean {
    return (
      this.organization &&
      this.organization_role &&
      this.organization_role === OrganizationRoles.Admin
    );
  }

  get hasManagementGroup(): boolean {
    return this.management_groups && this.management_groups.length > 0;
  }
}
