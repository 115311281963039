import $http from "@/services/Http";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { responseToClass } from "@/api/lib";
import { prefix } from "@/api/config";
import File from "@/models/File";
import StoreFormRequest from "@/api/requests/protocol/attachment/StoreFormRequest";

export function list(protocolId: number): AxiosPromise<File[]> {
  return $http
    .get(`${prefix}/protocols/${protocolId}/attachments`)
    .then(response => responseToClass(File, response));
}

export function store(
  protocolId: number,
  request: StoreFormRequest,
  config: AxiosRequestConfig
): AxiosPromise<File> {
  return $http
    .post(
      `${prefix}/protocols/${protocolId}/attachments`,
      request.toFormData(),
      config
    )
    .then(response => responseToClass(File, response));
}
