import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/tasks",
    name: "tasks",
    component: () =>
      import(/* webpackChunkName: "route.tasks" */ "../views/Tasks.vue"),
    meta: {
      auth: true
    }
  }
];

export default routes;
