import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType,
} from "@/reports/ReportModel";
import {
  ProjectLinkAccessor,
  userLinkAccessorBuilder,
} from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["Citizenships.countryList"],
  order: {
    "Users.id": "desc",
  },
  filters: [
    {
      member: "HiresPerProgram.ProjectParticipationType",
      operator: "equals",
      values: ["student"],
    },
  ],
  dimensions: [
    "Users.id", // Required for LinkAccessor
    "Users.pronoun",
    "Projects.id", // Required for LinkAccessor
    "HiresPerProgram.email",
    "HiresPerProgram.availableForHireAt",
    "HiresPerProgram.availableForInternshipAt",
    "HiresPerProgram.linkedInId",
    "HiresPerProgram.name",
    "HiresPerProgram.universityName",
    "HiresPerProgram.visa",
    "HiresPerProgram.lawfulPermanentResidencyStatus",
    "HiresPerProgram.exportControlStatus",
    "HiresPerProgram.current",
    "HiresPerProgram.ProjectParticipationDegreeDegree",
    "HiresPerProgram.ProjectParticipationDegreeField",
    "HiresPerProgram.PPDegreeGraduationDate",
    "HiresPerProgram.ProjectParticipationDegreeId",
    "HiresPerProgram.BusinessEntity",
    "HiresPerProgram.Center",
    "HiresPerProgram.SeismicShift",
    "HiresPerProgram.Thrust",
    "HiresPerProgram.Subthrust",
    "HiresPerProgram.Theme",
    "HiresPerProgram.Area",
    "HiresPerProgram.ResearchScholar",
    "HiresPerProgram.ProjectId",
    "HiresPerProgram.ProjectBusinessId",
    "HiresPerProgram.ProjectName",
    "HiresPerProgram.ProjectStartsAt",
    "HiresPerProgram.ProjectEndsAt",
    "HiresPerProgram.hasPrivateProfile",
    "Resumes.name",
    "Resumes.id",
  ],
  segments: ["Users.notDeleted", "Resumes.notDeleted", "Projects.notDeleted"],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "HiresPerProgram.name",
    label: "User Name",
  },
  {
    dimension: "HiresPerProgram.current",
    label: "Current",
  },
  {
    dimension: "Citizenships.country",
    label: "Citizenship",
  },
  {
    dimension: "HiresPerProgram.visa",
    label: "US Visa Status",
  },
  {
    label: "Export Control Status",
    dimension: "HiresPerProgram.exportControlStatus",
  },
  {
    dimension: "HiresPerProgram.ProjectParticipationDegreeDegree",
    label: "Degree Type",
  },
  {
    dimension: "HiresPerProgram.ProjectParticipationDegreeField",
    label: "Degree Field",
  },
  {
    dimension: "HiresPerProgram.availableForHireAt",
    label: "Available for hire",
  },
  {
    dimension: "HiresPerProgram.availableForInternshipAt",
    label: "Available for internship",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Student ID",
    key: "Users.id",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Student name",
    key: "HiresPerProgram.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder(),
  },
  {
    title: "Student E-mail",
    key: "HiresPerProgram.email",
    type: ReportColumnType.Text,
  },
  {
    title: "Preferred Pronouns",
    key: "Users.pronoun",
    type: ReportColumnType.Text,
  },
  {
    title: "Student University",
    key: "HiresPerProgram.universityName",
    type: ReportColumnType.Text,
  },
  {
    title: "US Visa status",
    key: "HiresPerProgram.visa",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Type",
    key: "HiresPerProgram.ProjectParticipationDegreeDegree",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Field",
    key: "HiresPerProgram.ProjectParticipationDegreeField",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Graduation Date",
    key: "HiresPerProgram.PPDegreeGraduationDate",
    type: ReportColumnType.Date,
  },
  {
    title: "Resume",
    key: "Resumes.name",
    fileIdMember: "Resumes.id",
    type: ReportColumnType.File,
  },
  {
    title: "Citizenships",
    key: "Citizenships.countryList",
    type: ReportColumnType.Text,
  },
  {
    title: "Green Card",
    key: "HiresPerProgram.lawfulPermanentResidencyStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Export Control Status",
    key: "HiresPerProgram.exportControlStatus",
    type: ReportColumnType.Text,
  },
  {
    title: "Business Entity",
    key: "HiresPerProgram.BusinessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Seismic Shift",
    key: "HiresPerProgram.SeismicShift",
    type: ReportColumnType.Text,
  },
  {
    title: "Thrust",
    key: "HiresPerProgram.Thrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Subthrust",
    key: "HiresPerProgram.Subthrust",
    type: ReportColumnType.Text,
  },
  {
    title: "Theme",
    key: "HiresPerProgram.Theme",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "HiresPerProgram.Center",
    type: ReportColumnType.Text,
  },
  {
    title: "Science Area",
    key: "HiresPerProgram.Area",
    type: ReportColumnType.Text,
  },
  {
    title: "Research Scholar",
    key: "HiresPerProgram.ResearchScholar",
    type: ReportColumnType.Text,
  },
  {
    title: "Project ID",
    key: "HiresPerProgram.ProjectBusinessId",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Project Pillar ID",
    key: "HiresPerProgram.ProjectId",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Project Name",
    key: "HiresPerProgram.ProjectName",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
  },
  {
    title: "Project Start Date",
    key: "HiresPerProgram.ProjectStartsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Projects End Date",
    key: "HiresPerProgram.ProjectEndsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Private Profile",
    key: "HiresPerProgram.hasPrivateProfile",
    type: ReportColumnType.Numeric,
  },
];

export const ScholarDegree456Config: ReportConfiguration = {
  name: "Scholar Degree (456)",
  section: "Student",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "HiresPerProgram.PPDegreeGraduationDate",
    endsAt: "HiresPerProgram.PPDegreeGraduationDate",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
};
