import User from "@/models/User";
import Protocol from "@/models/Protocol";
import { Type } from "class-transformer";

export default class ProtocolVersion {
  public id: number;
  public content: string;
  public excerpt: string;
  public name: string;
  public created_at: string;
  public updated_at: string;
  public tags: string[] = [];
  @Type(() => User)
  public originator: User;
  @Type(() => Protocol)
  public protocol: Protocol;
  public version_name: string;
}
