import { Pusher } from "pusher-js";
import { apiUrl } from "@/config/app";
import { authenticationPromise } from "@/services/Auth";
import Echo from "laravel-echo";
import _ from "lodash";
import { configuration } from "@/config/dynamic";

type EchoOptions = {
  client: unknown;
  broadcaster: string;
  key?: string;
  cluster: string;
  encrypted: boolean;
  authEndpoint: string;
  auth?: Record<string, unknown>;
};

function getDefaultOptions(): Promise<EchoOptions> {
  return configuration.getInstance().then(config => {
    return {
      client: Pusher,
      broadcaster: "pusher",
      key: config.pusherKey,
      cluster: config.pusherCluster || "us2",
      encrypted: true,
      authEndpoint: `${apiUrl.getInstance()}/api/broadcast/auth`
    };
  });
}

function clientBuilder(options: Partial<EchoOptions> = {}): Promise<Echo> {
  return getDefaultOptions().then(
    defaultOptions => new Echo(_.merge(defaultOptions, options))
  );
}

export function echoClientFactory(): Promise<Echo> {
  return authenticationPromise.getInstance().then(authentication => {
    return authentication.onReady().then(
      ({ client, token }) => {
        return clientBuilder({
          auth: {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        });
      },
      _error => {
        return clientBuilder();
      }
    );
  });
}
