import * as Auth from "@/api/namespaces/auth";
import * as Country from "@/api/namespaces/country";
import * as Dataset from "@/api/namespaces/dataset";
import * as DatasetCategory from "@/api/namespaces/datasetCategory";
import * as File from "@/api/namespaces/file";
import * as Formation from "@/api/namespaces/formation";
import * as Invitation from "@/api/namespaces/invitation";
import * as LicenseAgreement from "@/api/namespaces/licenseAgreement";
import * as Mention from "@/api/namespaces/mention";
import * as News from "@/api/namespaces/news";
import * as Note from "@/api/namespaces/note";
import * as NoteVersion from "@/api/namespaces/noteVersion";
import * as OnlyOffice from "@/api/namespaces/onlyOffice";
import * as Organization from "@/api/namespaces/organization";
import * as Project from "@/api/namespaces/project";
import * as Protocol from "@/api/namespaces/protocol";
import * as S3 from "@/api/namespaces/s3";
import * as Search from "@/api/namespaces/search";
import * as Task from "@/api/namespaces/task";
import * as Team from "@/api/namespaces/team";
import * as University from "@/api/namespaces/university";
import * as User from "@/api/namespaces/user";
import * as AccessCategory from "@/api/namespaces/accessCategory";
import * as ProjectParticipation from "@/api/namespaces/projectParticipation";
import * as Options from "@/api/namespaces/options";
import * as WorkExperience from "@/api/namespaces/workExperience";
import * as Company from "@/api/namespaces/company";
import * as SrcExportCv from "@/api/namespaces/srcExportCv";
import $http from "@/services/Http";
import { DefaultApi, PubliclyAccessibleApi } from "@/apiClientGenerated";
import { apiUrl } from "@/config/app";
import { prefixV2 } from "@/api/config";

export const ApiClient = {
  AccessCategory,
  Auth,
  Company,
  Country,
  Dataset,
  DatasetCategory,
  File,
  Formation,
  Invitation,
  LicenseAgreement,
  Mention,
  News,
  Note,
  NoteVersion,
  OnlyOffice,
  Organization,
  Project,
  Protocol,
  S3,
  Search,
  Task,
  Team,
  University,
  User,
  ProjectParticipation,
  Options,
  WorkExperience,
  SrcExportCv,
};

export const ApiGeneratedPublicClient = new PubliclyAccessibleApi(
  undefined,
  `${apiUrl.getInstance()}${prefixV2}`
);

export const ApiGeneratedClient = new DefaultApi(
  undefined,
  `${apiUrl.getInstance()}${prefixV2}`,
  $http
);
