import { RouteConfig } from "vue-router";
import UserPermissions from "@/models/UserPermissions";

const organizationForm = () =>
  import(
    /* webpackChunkName: "route.admin.organization-edit" */ "../views/AdminOrganizationForm.vue"
  );

const routes: RouteConfig[] = [
  {
    path: "/admin",
    name: "admin_home",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.home" */ "../views/AdminHome.vue"
      ),
    meta: {}
  },
  {
    path: "/admin/users",
    name: "admin_users",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.users" */ "../views/AdminUsers.vue"
      ),
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/projects",
    name: "admin_projects",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.projects" */ "../views/AdminProjects.vue"
      ),
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/projects/:project_id",
    name: "admin_project",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.project" */ "../views/AdminProject.vue"
      ),
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/user-create",
    name: "admin_users_create",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.users.create" */ "../views/AdminUserCreate.vue"
      ),
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/users/:id",
    name: "admin_users_edit",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.users.edit" */ "../views/AdminUsersForm.vue"
      ),
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/organizations",
    name: "admin_organizations",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.organizations" */ "../views/AdminOrganizations.vue"
      ),
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/organizations-create",
    name: "admin_organization_create",
    component: organizationForm,
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/organizations/:organization_id/edit",
    name: "admin_organization_update",
    component: organizationForm,
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/teams",
    name: "admin_teams",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.teams" */ "../views/AdminTeams.vue"
      ),
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/teams/:team_id",
    name: "admin_team",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.teams.edit" */ "../views/AdminTeam.vue"
      ),
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/team-create",
    name: "admin_teams_create",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.teams.create" */ "../views/AdminTeamsCreate.vue"
      ),
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/datasets",
    name: "admin_datasets",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.datasets" */ "../views/AdminDatasets.vue"
      ),
    meta: {
      permissions: [UserPermissions.debugTools]
    }
  },
  {
    path: "/admin/datasets/:dataset_id",
    name: "admin_dataset",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.dataset" */ "../views/AdminDataset.vue"
      ),
    meta: {
      permissions: [UserPermissions.debugTools]
    }
  },
  {
    path: "/admin/mass-import",
    name: "admin_mass_import",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.mass-import" */ "../views/AdminMassImport.vue"
      ),
    meta: {
      permissions: [UserPermissions.userTeamManagement]
    }
  },
  {
    path: "/admin/tos-management",
    name: "admin_tos_management",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.tos-management" */ "../views/AdminTOSManagement.vue"
      ),
    meta: {
      permissions: [UserPermissions.debugTools]
    }
  },
  {
    path: "/admin/orcid",
    name: "admin_orcid",
    component: () =>
      import(
        /* webpackChunkName: "route.admin.orcid" */ "../views/AdminOrcid.vue"
      ),
    meta: {
      permissions: [UserPermissions.debugTools]
    }
  }
];

routes.forEach(r =>
  Object.assign(r.meta, {
    auth: true,
    admin: true
  })
);

export default routes;
