import UpdateViewModel from "@/forms/models/organization/user/UpdateViewModel";
import UpdateFormRequest from "@/forms/requests/organization/user/UpdateFormRequest";
import { extract } from "@/utils/Object";
import _ from "lodash";
import { UserCategories, PostdoctoralResearcher } from "@/models/UserCategory";
import { transformDateToUTC } from "@/forms/transformers/common/date";

export default function (inputForm: UpdateViewModel): UpdateFormRequest {
  const form = extract(new UpdateFormRequest(), inputForm);

  form.citizenship =
    inputForm.citizenship && inputForm.citizenship.length > 0
      ? _.map(inputForm.citizenship, "code")
      : null;

  form.university_affiliation_id = inputForm.university_affiliation
    ? inputForm.university_affiliation.id
    : null;

  if (inputForm.interests) {
    form.interests = inputForm.interests.map((interest) => interest.id);
  }

  if (inputForm.category_code === PostdoctoralResearcher) {
    form.category_code = UserCategories.Student;
  }

  if (_.isEmpty(form.other_functions)) {
    form.other_functions = null;
  }

  transformDateToUTC(form, [
    "available_for_hire_date",
    "available_for_internship_date",
    "expires_at",
  ]);

  return form;
}
