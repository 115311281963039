import { RouteConfig } from "vue-router";
import { reportConfigsToRoutes } from "@/reports/ReportNavUtils";
import { ReportScope } from "@/reports/ReportModel";

const routes: RouteConfig[] = [
  {
    path: "/organizations/:organization_id",
    name: "organization",
    component: () =>
      import(
        /* webpackChunkName: "route.organization" */ "../views/Organization.vue"
      ),
    meta: {
      auth: true
    },
    children: [
      {
        path: "manage-users",
        name: "organization_manage_users",
        component: () => import("../views/OrganizationManageUsers.vue"),
        meta: {
          auth: true,
          tags: ["organization_manage_users"],
          breadcrumbs: "Manage Users"
        }
      },
      {
        path: "manage-projects",
        name: "organization_manage_projects",
        component: () => import("../views/OrganizationManageProjects.vue"),
        meta: {
          auth: true,
          tags: ["organization_manage_projects"],
          breadcrumbs: "Manage Projects"
        }
      },
      {
        path: "manage-teams",
        name: "organization_manage_teams",
        component: () => import("../views/OrganizationManageTeams.vue"),
        meta: {
          auth: true,
          tags: ["organization_manage_teams"],
          breadcrumbs: "Manage Teams"
        }
      },
      {
        path: "teams/:team_id/edit",
        name: "organization_manage_team",
        component: () => import("../views/OrganizationTeamForm.vue"),
        meta: {
          auth: true,
          tags: ["organization_manage_team"],
          breadcrumbs: "Manage Team"
        }
      },
      {
        path: "manage-access-categories",
        name: "organization_manage_access_categories",
        component: () =>
          import("../views/OrganizationManageAccessCategories.vue"),
        meta: {
          auth: true,
          tags: ["organization_manage_access_categories"],
          breadcrumbs: "Access Categories"
        }
      },
      {
        path: "manage-dataset-categories",
        name: "organization_manage_dataset_categories",
        component: () =>
          import("../views/OrganizationManageDatasetCategories.vue"),
        meta: {
          auth: true,
          tags: ["organization_manage_dataset_categories"],
          breadcrumbs: "Dataset Categories"
        }
      },
      {
        path: "manage-management-groups",
        name: "organization_manage_management_groups",
        component: () =>
          import("../views/OrganizationManageManagementGroups.vue"),
        meta: {
          auth: true,
          tags: ["organization_manage_management_groups"],
          breadcrumbs: "Manage Admin Groups"
        }
      },
      {
        path: "users/:user_id/edit",
        name: "organization_edit_user",
        component: () => import("../views/OrganizationUsersForm.vue"),
        meta: {
          auth: true,
          tags: ["organization_edit_user"],
          breadcrumbs: "Edit User"
        }
      },
      {
        path: "projects/create",
        name: "organization_project_create_wizard",
        component: () =>
          import(
            /* webpackChunkName: "route.organization.project.create.wizard" */ "../views/OrganizationProjectCreateWizard.vue"
          ),
        meta: {
          auth: true,
          tags: ["organization_project_create_wizard"],
          breadcrumbs: "Create Project"
        }
      },
      {
        path: "projects/:project_id/participations",
        name: "organization_project_participation",
        component: () =>
          import("../views/OrganizationProjectParticipationsForm.vue"),
        meta: {
          auth: true,
          tags: ["organization_project_participation"],
          breadcrumbs: "Project Participation"
        }
      },
      {
        path: "users/create",
        name: "organization_create_user",
        component: () => import("../views/OrganizationCreateUser.vue"),
        meta: {
          auth: true,
          tags: ["organization_create_user"],
          breadcrumbs: "Create User"
        }
      },
      {
        path: "teams/create",
        name: "organization_create_team",
        component: () => import("../views/OrganizationCreateTeam.vue"),
        meta: {
          auth: true,
          tags: ["organization_create_team"],
          breadcrumbs: "Create Team"
        }
      },
      {
        path: "management-groups/:management_group_id/edit",
        name: "organization_edit_management_group",
        component: () => import("../views/OrganizationManagementGroupForm.vue"),
        meta: {
          auth: true,
          tags: ["organization_edit_management_group"],
          breadcrumbs: "Edit Admin Group"
        }
      },
      ...reportConfigsToRoutes(ReportScope.Organization)
    ]
  }
];

export default routes;
