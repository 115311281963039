import IModel from "@/models/IModel";

export enum EMentionableType {
  User = "App\\Models\\User",
  Dataset = "App\\Models\\DatasetVersion",
  Protocol = "App\\Models\\ProtocolVersion",
  Task = "App\\Models\\Task",
  MentionableGroup = "MentionableGroup",
}

export default class Mentionable {
  id: string | number;
  type: EMentionableType;
  tag: string;
  value: string;
  deleted_at: string;
  authorized: boolean;
  parent_resource: IModel | null;
  parent_type?: string;

  get displayableType() {
    return this.type.substr(this.type.lastIndexOf("\\") + 1);
  }
}
