export interface IPublicationDate {
  year: number | null;
  month: number | null;
  day: number | null;
}

export interface IAuthor {
  orcid?: string;
  authenticated_orcid?: boolean;
  affiliation: { name: string }[];
  given: string;
  family: string;
  sequence: string;
}

export default class Publication {
  public publication_date: IPublicationDate;
  public type: string;
  public title: string;
  public subtitle: string;
  public authors: IAuthor[];
  public journal?: string;
  public volume?: string;
  public issue?: string;
  public page?: string;
  public doi?: string;
  public get authorsJoined() {
    return this.authors
      .map(a => `${a.family} ${(a.given || "").substr(0, 1)}`)
      .join(", ");
  }
}
