import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionType,
} from "@/reports/ReportModel";
import {
  ProjectLeaderLinkAccessor,
  ProjectLinkAccessor,
} from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["ProjectParticipations.liaisonCount"],
  order: [
    ["Projects.startsAt", "asc"],
    ["Projects.endsAt", "asc"],
  ],
  dimensions: [
    "Projects.id",
    "SrcAccessCategoryTags.businessEntity",
    "SrcAccessCategoryTags.area",
    "SrcAccessCategoryTags.center",
    "ProjectManagerLists.userList",
    "Projects.businessId",
    "ProjectLeader.participationUniversityName",
    "ProjectLeader.name",
    "ProjectLeader.id",
    "Projects.name",
    "Projects.startsAt",
    "Projects.endsAt",
    "SrcAccessCategoryTags.thrust",
    "SrcAccessCategoryTags.theme",
    "SrcAccessCategoryTags.subthrust",
  ],
  segments: [
    "Projects.notDeleted",
    "SrcAccessCategoryTags.notDeleted",
    "ProjectManagerLists.notDeleted",
    "ProjectLeader.notDeleted",
  ],
  filters: [
    {
      member: "ProjectParticipations.liaisonCount",
      operator: "equals",
      values: ["0"],
    },
    // To filter out Center "projects"
    {
      member: "Projects.businessId",
      operator: "contains",
      values: ["."],
    },
  ],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Projects.endedStatus",
    label: "Status",
    defaultValue: "Active",
  },
];
// order is important
const columns: ReportColumn[] = [
  {
    title: "Business Entity",
    key: "SrcAccessCategoryTags.businessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Science Area",
    key: "SrcAccessCategoryTags.area",
    type: ReportColumnType.Text,
  },
  {
    title: "Center",
    key: "SrcAccessCategoryTags.center",
    type: ReportColumnType.Text,
  },
  {
    title: "Manager",
    key: "ProjectManagerLists.userList",
    type: ReportColumnType.Text,
  },
  {
    title: "Project ID",
    key: "Projects.businessId",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    freeze: "Left",
  },
  {
    title: "Project University",
    key: "ProjectLeader.participationUniversityName",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Leader",
    key: "ProjectLeader.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLeaderLinkAccessor,
  },
  {
    title: "Project Name",
    key: "Projects.name",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
    width: 200,
  },
  {
    title: "Project Start Date",
    key: "Projects.startsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Project End Date",
    key: "Projects.endsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Pillar ID",
    key: "Projects.id",
    type: ReportColumnType.Link,
    linkAccessor: ProjectLinkAccessor,
  },
];

export const ProjectsWithoutLiaisonsReportConfig: ReportConfiguration = {
  name: "Projects w/o Liaisons (152M)",
  section: "Project",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Projects.startsAt",
    endsAt: "Projects.endsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
};
