import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { ReportColumn } from "@/reports/ReportModel";
import { AxiosPromise } from "axios";
import { PreprocessedRecord } from "@/reports/CSVSerializer";

export interface ExportCvRequest {
  columns: ReportColumn[];
  rows?: PreprocessedRecord[];
}

export function exportCv(form: ExportCvRequest): AxiosPromise<void> {
  return $http.post(`${prefix}/export-cv`, form);
}
