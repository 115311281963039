import * as AccessCategory from "@/api/namespaces/team/accessCategory";
import * as Organization from "@/api/namespaces/team/organization";
import * as Project from "@/api/namespaces/team/project";
import * as Protocol from "@/api/namespaces/team/protocol";
import * as User from "@/api/namespaces/team/user";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { AxiosPromise } from "axios";
import Team from "@/models/Team";
import { responseToClass } from "@/api/lib";
import UpdateFormRequest from "@/forms/requests/team/UpdateFormRequest";

export { AccessCategory, Organization, Project, Protocol, User };

export function update(
  teamId: number,
  data: UpdateFormRequest
): AxiosPromise<Team> {
  return $http
    .put(`${prefix}/teams/${teamId}`, data)
    .then((response) => responseToClass(Team, response));
}

export function archive(teamId: number): AxiosPromise<Team> {
  return $http
    .post(`${prefix}/teams/${teamId}/archive`)
    .then((response) => responseToClass(Team, response));
}

export function unarchive(teamId: number): AxiosPromise<Team> {
  return $http
    .post(`${prefix}/teams/${teamId}/restore`)
    .then((response) => responseToClass(Team, response));
}
