import Formation from "@/models/Formation";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { AxiosPromise } from "axios";
import { responseToClass } from "@/api/lib";

export function store(
  userId: number,
  formation: Formation
): AxiosPromise<Formation> {
  return $http
    .post(`${prefix}/users/${userId}/formations`, formation)
    .then(response => responseToClass(Formation, response));
}
