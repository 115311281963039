import EventBusPlugin from "./bus";
import FilesPlugin from "@/plugins/files";
import Fontawesome from "./fontawesome";
import MomentPlugin from "@/plugins/moment";
import StringPlugin from "@/plugins/str";
import UtilsPlugin from "@/plugins/utils";
import DownloadPlugin from "@/plugins/download";
import FormatPlugin from "@/plugins/format";
import { PluginObject } from "vue";
import RootHtml from "@/plugins/svg";

const plugin: PluginObject<any> = {
  install(Vue, options) {
    Vue.use(EventBusPlugin);
    Vue.use(FilesPlugin);
    Vue.use(Fontawesome);
    Vue.use(MomentPlugin);
    Vue.use(StringPlugin);
    Vue.use(UtilsPlugin);
    Vue.use(DownloadPlugin);
    Vue.use(FormatPlugin);
    Vue.use(RootHtml);
  }
};

export default plugin;
