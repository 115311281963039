import User from "@/models/User";
import { Type } from "class-transformer";
import University from "@/models/University";
import ITimestamps from "@/models/TimestampsInterface";
import Team from "@/models/Team";
import { DefTableRecord } from "@/store/modules/definitions";

export default class Formation implements ITimestamps {
  public id: number | null = null;
  @Type(() => Team)
  public team: Team | null = null;
  public team_id: number | null = null;
  public degree: DefTableRecord | null = null;
  public degree_code: string | null = null;
  public field: string | null = null;
  public degree_field: DefTableRecord | null = null;
  public degree_field_code: string | null = null;
  @Type(() => User)
  public user: User | null = null;
  @Type(() => University)
  public university: University | null = null;
  public university_id: number | null = null;
  public institute_center: string | null = null;
  public starts_at: Date | null = null;
  public ends_at: Date | null = null;
  public french_value: string | null = null;
  public created_at: string | null = null;
  public updated_at: string | null = null;
}
