var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.isNotFound ? _c("not-found") : _vm._e(),
      _vm._v(" "),
      _vm.isForbidden ? _c("forbidden") : _vm._e(),
      _vm._v(" "),
      _vm.isServerError
        ? _c("server-error", { attrs: { message: _vm.errorMessage } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isOk && _vm.userReady
        ? _c("router-view", {
            key: _vm.$route.fullPath,
            attrs: {
              "test-route-name": _vm.$route.name,
              "test-route-url": _vm.pathname,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.requestUserInterests,
            width: "600px",
            "can-cancel": false,
          },
        },
        [
          _c("user-interests-modal", {
            on: {
              close: function ($event) {
                _vm.requestUserInterests = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }