
























import SpecialNavbar from "@/navbars/SpecialNavbar.vue";
import Helper from "../services/errorHelper";
import Component from "vue-class-component";
import Vue from "vue";
import { RawLocation, Route } from "vue-router";
import { namespace } from "vuex-class";
import { authenticationPromise } from "@/services/Auth";

const user = namespace("user");

@Component({
  components: {
    SpecialNavbar,
  },
})
export default class ServerError extends Vue {
  beforeRouteLeave(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
  ): void {
    Helper.clear();

    next();
  }

  logout(): void {
    authenticationPromise
      .getInstance()
      .then((authentication) => authentication.logout());
  }
}
