import FileModel, { SignedFile } from "@/models/File";
import { plainToClass } from "class-transformer";
import { ApiClient } from "@/api";
import { publicDownloadUrl } from "@/plugins/download";
import { AxiosRequestConfig } from "axios";
import * as JsonHash from "json-hash";

export default class FileService {
  private _model: FileModel;

  constructor(model: FileModel) {
    this._model = model;
  }

  public sign(data: unknown = null): Promise<SignedFile> {
    if (!this._model.id || !this._model.signed) {
      return Promise.reject();
    }

    const key = `file-${this._model.id}${
      data ? `-${JsonHash.digest(data)}` : ""
    }.signed`;

    const storedResponse: string | null = sessionStorage.getItem(key);

    if (storedResponse !== null) {
      const signedUrl = plainToClass(SignedFile, JSON.parse(storedResponse));

      if (!signedUrl.isExpired) {
        return Promise.resolve(signedUrl);
      }
    }

    return ApiClient.File.sign(this._model.id, data).then(response => {
      const signedUrl = response.data;

      sessionStorage.setItem(key, JSON.stringify(signedUrl));

      return signedUrl;
    });
  }

  public resolveUrl(): Promise<string> {
    return this._model.signed
      ? this.sign().then(file => file.url)
      : Promise.resolve(publicDownloadUrl(this._model.id));
  }

  public download(options: AxiosRequestConfig = {}): Promise<unknown> {
    return ApiClient.File.download(this._model.id, options);
  }

  get file(): FileModel {
    return this._model;
  }
}
