/* tslint:disable */
/* eslint-disable */
/**
 * Pillar Science
 * Pillar Science main api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessCategoryModel
 */
export interface AccessCategoryModel {
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryModel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryModel
     */
    'tag'?: string;
    /**
     * 
     * @type {number}
     * @memberof AccessCategoryModel
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryModel
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryModel
     */
    'archived_at'?: string;
}
/**
 * 
 * @export
 * @interface AccessCategoryResource
 */
export interface AccessCategoryResource {
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryResource
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryResource
     */
    'tag'?: string;
    /**
     * 
     * @type {number}
     * @memberof AccessCategoryResource
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryResource
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryResource
     */
    'archived_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof AccessCategoryResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessCategoryResource
     */
    'updated_at': string | null;
}
/**
 * 
 * @export
 * @interface AggregationModel
 */
export interface AggregationModel {
    /**
     * 
     * @type {string}
     * @memberof AggregationModel
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregationModel
     */
    'key'?: string;
    /**
     * 
     * @type {Array<AggregationModelBuckets>}
     * @memberof AggregationModel
     */
    'buckets'?: Array<AggregationModelBuckets>;
}
/**
 * 
 * @export
 * @interface AggregationModelBuckets
 */
export interface AggregationModelBuckets {
    /**
     * 
     * @type {string}
     * @memberof AggregationModelBuckets
     */
    'label'?: string;
    /**
     * 
     * @type {string | number | boolean}
     * @memberof AggregationModelBuckets
     */
    'value'?: string | number | boolean;
    /**
     * 
     * @type {number}
     * @memberof AggregationModelBuckets
     */
    'count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AggregationModelBuckets
     */
    'selected'?: boolean;
}
/**
 * Archival status for models
 * @export
 * @interface ArchivedModelComponent
 */
export interface ArchivedModelComponent {
    /**
     * 
     * @type {string}
     * @memberof ArchivedModelComponent
     */
    'archived_at': string | null;
}
/**
 * 
 * @export
 * @interface Auth0ConnectionModel
 */
export interface Auth0ConnectionModel {
    /**
     * 
     * @type {string}
     * @memberof Auth0ConnectionModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Auth0ConnectionModel
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof Auth0ConnectionModel
     */
    'auth0_connection_id': string;
}
/**
 * 
 * @export
 * @interface CategoryResource
 */
export interface CategoryResource {
    /**
     * 
     * @type {string}
     * @memberof CategoryResource
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryResource
     */
    'label': string;
}
/**
 * A company
 * @export
 * @interface CompanyBaseModelComponent
 */
export interface CompanyBaseModelComponent {
    /**
     * 
     * @type {string}
     * @memberof CompanyBaseModelComponent
     */
    'name': string;
    /**
     * 
     * @type {Array<CompanyBaseModelComponentMetrics>}
     * @memberof CompanyBaseModelComponent
     */
    'metrics'?: Array<CompanyBaseModelComponentMetrics>;
}
/**
 * 
 * @export
 * @interface CompanyBaseModelComponentMetrics
 */
export interface CompanyBaseModelComponentMetrics {
    /**
     * 
     * @type {string}
     * @memberof CompanyBaseModelComponentMetrics
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBaseModelComponentMetrics
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface CompanyModelArray
 */
export interface CompanyModelArray {
    /**
     * 
     * @type {Array<CompanyResource>}
     * @memberof CompanyModelArray
     */
    'data': Array<CompanyResource>;
}
/**
 * 
 * @export
 * @interface CompanyResource
 */
export interface CompanyResource {
    /**
     * 
     * @type {string}
     * @memberof CompanyResource
     */
    'name': string;
    /**
     * 
     * @type {Array<CompanyBaseModelComponentMetrics>}
     * @memberof CompanyResource
     */
    'metrics'?: Array<CompanyBaseModelComponentMetrics>;
    /**
     * 
     * @type {number}
     * @memberof CompanyResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResource
     */
    'deleted_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResource
     */
    'updated_at': string | null;
}
/**
 * A corporate institution
 * @export
 * @interface CorporateInstitutionBaseModelComponent
 */
export interface CorporateInstitutionBaseModelComponent {
    /**
     * 
     * @type {number}
     * @memberof CorporateInstitutionBaseModelComponent
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionBaseModelComponent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionBaseModelComponent
     */
    'homepage_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionBaseModelComponent
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionBaseModelComponent
     */
    'image_thumbnail_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionBaseModelComponent
     */
    'country_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionBaseModelComponent
     */
    'openalex_id': string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionBaseModelComponent
     */
    'ror_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CorporateInstitutionBaseModelComponent
     */
    'is_favorited': boolean;
    /**
     * 
     * @type {number}
     * @memberof CorporateInstitutionBaseModelComponent
     */
    'concepts_count'?: number;
}
/**
 * 
 * @export
 * @interface CorporateInstitutionModelArray
 */
export interface CorporateInstitutionModelArray {
    /**
     * 
     * @type {Array<CorporateInstitutionResource>}
     * @memberof CorporateInstitutionModelArray
     */
    'data': Array<CorporateInstitutionResource>;
}
/**
 * 
 * @export
 * @interface CorporateInstitutionResource
 */
export interface CorporateInstitutionResource {
    /**
     * 
     * @type {number}
     * @memberof CorporateInstitutionResource
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionResource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionResource
     */
    'homepage_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionResource
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionResource
     */
    'image_thumbnail_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionResource
     */
    'country_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionResource
     */
    'openalex_id': string;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionResource
     */
    'ror_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CorporateInstitutionResource
     */
    'is_favorited': boolean;
    /**
     * 
     * @type {number}
     * @memberof CorporateInstitutionResource
     */
    'concepts_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CorporateInstitutionResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionResource
     */
    'deleted_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof CorporateInstitutionResource
     */
    'updated_at': string | null;
}
/**
 * List of country codes
 * @export
 * @enum {string}
 */

export const CountryListEnum = {
    Kw: 'kw',
    Pw: 'pw',
    Li: 'li',
    Md: 'md',
    Ao: 'ao',
    Bo: 'bo',
    Tv: 'tv',
    Pe: 'pe',
    Nc: 'nc',
    De: 'de',
    Bw: 'bw',
    Af: 'af',
    Hu: 'hu',
    Bf: 'bf',
    Ca: 'ca',
    Bl: 'bl',
    Id: 'id',
    Tw: 'tw',
    Lc: 'lc',
    Aw: 'aw',
    Ga: 'ga',
    Gs: 'gs',
    Lr: 'lr',
    Tc: 'tc',
    Eh: 'eh',
    Tr: 'tr',
    Ml: 'ml',
    Bj: 'bj',
    Cy: 'cy',
    So: 'so',
    Ne: 'ne',
    Fo: 'fo',
    Pt: 'pt',
    Sn: 'sn',
    Cu: 'cu',
    By: 'by',
    Bb: 'bb',
    Tn: 'tn',
    Il: 'il',
    It: 'it',
    Mm: 'mm',
    Aq: 'aq',
    Fm: 'fm',
    Wf: 'wf',
    Dj: 'dj',
    Cg: 'cg',
    Gi: 'gi',
    Cd: 'cd',
    Bz: 'bz',
    Bm: 'bm',
    Au: 'au',
    Sh: 'sh',
    Cm: 'cm',
    Ru: 'ru',
    Ag: 'ag',
    Jp: 'jp',
    Ky: 'ky',
    Bq: 'bq',
    Hn: 'hn',
    Hm: 'hm',
    Bi: 'bi',
    Py: 'py',
    Ar: 'ar',
    Pf: 'pf',
    St: 'st',
    Ls: 'ls',
    Sd: 'sd',
    Sv: 'sv',
    Cx: 'cx',
    Kg: 'kg',
    Br: 'br',
    Kh: 'kh',
    Ms: 'ms',
    Za: 'za',
    Gt: 'gt',
    Us: 'us',
    Az: 'az',
    My: 'my',
    Co: 'co',
    Mr: 'mr',
    Bh: 'bh',
    Be: 'be',
    Cl: 'cl',
    Mk: 'mk',
    Mz: 'mz',
    Nf: 'nf',
    As: 'as',
    Et: 'et',
    Gp: 'gp',
    Gu: 'gu',
    In: 'in',
    Pr: 'pr',
    Es: 'es',
    Kz: 'kz',
    Om: 'om',
    Ae: 'ae',
    Pl: 'pl',
    Gf: 'gf',
    Gd: 'gd',
    Lk: 'lk',
    Tj: 'tj',
    Sy: 'sy',
    Ly: 'ly',
    Ht: 'ht',
    Sm: 'sm',
    To: 'to',
    Vg: 'vg',
    Ge: 'ge',
    Er: 'er',
    Tz: 'tz',
    Cz: 'cz',
    Xk: 'xk',
    Bt: 'bt',
    Eg: 'eg',
    Kn: 'kn',
    Vn: 'vn',
    Mh: 'mh',
    At: 'at',
    Pg: 'pg',
    Bs: 'bs',
    Hr: 'hr',
    Si: 'si',
    Uz: 'uz',
    Fi: 'fi',
    Gy: 'gy',
    Ax: 'ax',
    Mc: 'mc',
    Sk: 'sk',
    Ye: 'ye',
    Mw: 'mw',
    Gm: 'gm',
    Pa: 'pa',
    Im: 'im',
    Ie: 'ie',
    Kr: 'kr',
    Gr: 'gr',
    Gg: 'gg',
    Pk: 'pk',
    Pn: 'pn',
    Am: 'am',
    Mt: 'mt',
    Tm: 'tm',
    Do: 'do',
    Sb: 'sb',
    Vu: 'vu',
    Mg: 'mg',
    La: 'la',
    Ck: 'ck',
    Mf: 'mf',
    Mn: 'mn',
    Mq: 'mq',
    Fr: 'fr',
    Cf: 'cf',
    Ai: 'ai',
    Sz: 'sz',
    Gb: 'gb',
    Is: 'is',
    Np: 'np',
    Gh: 'gh',
    Iq: 'iq',
    Th: 'th',
    Dk: 'dk',
    Rs: 'rs',
    Ug: 'ug',
    Cc: 'cc',
    Me: 'me',
    Sa: 'sa',
    Jo: 'jo',
    Sr: 'sr',
    Nz: 'nz',
    Ke: 'ke',
    Mx: 'mx',
    Ba: 'ba',
    Vc: 'vc',
    Sc: 'sc',
    Pm: 'pm',
    Tk: 'tk',
    Bg: 'bg',
    Fk: 'fk',
    Lv: 'lv',
    Re: 're',
    Gn: 'gn',
    Lb: 'lb',
    Lu: 'lu',
    Mu: 'mu',
    Ee: 'ee',
    No: 'no',
    Km: 'km',
    Ve: 've',
    Ir: 'ir',
    Se: 'se',
    Fj: 'fj',
    Ph: 'ph',
    Hk: 'hk',
    Uy: 'uy',
    Mo: 'mo',
    Ch: 'ch',
    Tt: 'tt',
    Rw: 'rw',
    Al: 'al',
    Gw: 'gw',
    Ci: 'ci',
    Kp: 'kp',
    Bv: 'bv',
    Ad: 'ad',
    Mv: 'mv',
    Nr: 'nr',
    Sg: 'sg',
    Zw: 'zw',
    Vi: 'vi',
    Dm: 'dm',
    Ma: 'ma',
    Yt: 'yt',
    Qa: 'qa',
    Sx: 'sx',
    Cr: 'cr',
    Nu: 'nu',
    Cw: 'cw',
    Cv: 'cv',
    Nl: 'nl',
    Ro: 'ro',
    Sl: 'sl',
    Ni: 'ni',
    Dz: 'dz',
    Jm: 'jm',
    Tg: 'tg',
    Va: 'va',
    Je: 'je',
    Ec: 'ec',
    Ws: 'ws',
    Io: 'io',
    Ng: 'ng',
    Zm: 'zm',
    Tf: 'tf',
    Td: 'td',
    Sj: 'sj',
    Lt: 'lt',
    Ua: 'ua',
    Cn: 'cn',
    Um: 'um',
    Mp: 'mp',
    Ps: 'ps',
    Gl: 'gl',
    Ss: 'ss',
    Bd: 'bd',
    Gq: 'gq',
    Na: 'na',
    Tl: 'tl',
    Bn: 'bn',
    Ki: 'ki'
} as const;

export type CountryListEnum = typeof CountryListEnum[keyof typeof CountryListEnum];


/**
 * 
 * @export
 * @interface DefTableModel
 */
export interface DefTableModel {
    /**
     * 
     * @type {string}
     * @memberof DefTableModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DefTableModel
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface FileBaseModelComponent
 */
export interface FileBaseModelComponent {
    /**
     * 
     * @type {string}
     * @memberof FileBaseModelComponent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FileBaseModelComponent
     */
    'mime_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof FileBaseModelComponent
     */
    'signed': boolean;
    /**
     * 
     * @type {number}
     * @memberof FileBaseModelComponent
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface FileResource
 */
export interface FileResource {
    /**
     * 
     * @type {number}
     * @memberof FileResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FileResource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FileResource
     */
    'mime_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof FileResource
     */
    'signed': boolean;
    /**
     * 
     * @type {number}
     * @memberof FileResource
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface FormationModel
 */
export interface FormationModel {
    /**
     * 
     * @type {string}
     * @memberof FormationModel
     */
    'starts_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormationModel
     */
    'ends_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormationModel
     */
    'institute_center'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormationModel
     */
    'year'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormationModel
     */
    'degree_code'?: FormationModelDegreeCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof FormationModel
     */
    'degree_field'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormationModel
     */
    'university_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormationModel
     */
    'field'?: string;
    /**
     * 
     * @type {DefTableModel}
     * @memberof FormationModel
     */
    'degree'?: DefTableModel;
    /**
     * 
     * @type {DefTableModel}
     * @memberof FormationModel
     */
    'degreeField'?: DefTableModel;
    /**
     * 
     * @type {UniversityResource}
     * @memberof FormationModel
     */
    'university'?: UniversityResource;
}

export const FormationModelDegreeCodeEnum = {
    Associate: 'associate',
    Bachelors: 'bachelors',
    Doctoral: 'doctoral',
    Graduate: 'graduate',
    IntegratedMater: 'integrated_mater',
    Masters: 'masters',
    Other: 'other',
    Postdoctoral: 'postdoctoral',
    UndergraduateCertificate: 'undergraduate_certificate'
} as const;

export type FormationModelDegreeCodeEnum = typeof FormationModelDegreeCodeEnum[keyof typeof FormationModelDegreeCodeEnum];

/**
 * 
 * @export
 * @interface FormationResource
 */
export interface FormationResource {
    /**
     * 
     * @type {string}
     * @memberof FormationResource
     */
    'starts_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormationResource
     */
    'ends_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormationResource
     */
    'institute_center'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormationResource
     */
    'year'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormationResource
     */
    'degree_code'?: FormationResourceDegreeCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof FormationResource
     */
    'degree_field'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormationResource
     */
    'university_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormationResource
     */
    'field'?: string;
    /**
     * 
     * @type {DefTableModel}
     * @memberof FormationResource
     */
    'degree'?: DefTableModel;
    /**
     * 
     * @type {DefTableModel}
     * @memberof FormationResource
     */
    'degreeField'?: DefTableModel;
    /**
     * 
     * @type {UniversityResource}
     * @memberof FormationResource
     */
    'university'?: UniversityResource;
    /**
     * 
     * @type {number}
     * @memberof FormationResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FormationResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof FormationResource
     */
    'updated_at': string | null;
}

export const FormationResourceDegreeCodeEnum = {
    Associate: 'associate',
    Bachelors: 'bachelors',
    Doctoral: 'doctoral',
    Graduate: 'graduate',
    IntegratedMater: 'integrated_mater',
    Masters: 'masters',
    Other: 'other',
    Postdoctoral: 'postdoctoral',
    UndergraduateCertificate: 'undergraduate_certificate'
} as const;

export type FormationResourceDegreeCodeEnum = typeof FormationResourceDegreeCodeEnum[keyof typeof FormationResourceDegreeCodeEnum];

/**
 * 
 * @export
 * @interface FrontendConfiguration
 */
export interface FrontendConfiguration {
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'pusherKey': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'pusherCluster': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'loginRoute'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'homeRoute'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'onlyOfficeUrl': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'onlyOfficeCommandServer': string;
    /**
     * 
     * @type {number}
     * @memberof FrontendConfiguration
     */
    'onlyOfficeForceSaveInterval'?: number;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'amplitudeKey': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'amplitudeServerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'auth0Domain': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'auth0ClientId': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'auth0Audience': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'auth0CacheLocation'?: FrontendConfigurationAuth0CacheLocationEnum;
    /**
     * 
     * @type {number}
     * @memberof FrontendConfiguration
     */
    'fileUploadPoolSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'sentryDsn'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'sentryEnvironment'?: string;
    /**
     * 
     * @type {number}
     * @memberof FrontendConfiguration
     */
    'sentryTraceSampleRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'sentryRelease'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'froalaLicenseKey'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FrontendConfiguration
     */
    'maintenanceMode'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FrontendConfiguration
     */
    's3UploadThreshold'?: number;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfiguration
     */
    'collectInterestsForUsers'?: string;
    /**
     * 
     * @type {number}
     * @memberof FrontendConfiguration
     */
    'resetTokenIssuedBefore'?: number;
}

export const FrontendConfigurationAuth0CacheLocationEnum = {
    Memory: 'memory',
    Localstorage: 'localstorage'
} as const;

export type FrontendConfigurationAuth0CacheLocationEnum = typeof FrontendConfigurationAuth0CacheLocationEnum[keyof typeof FrontendConfigurationAuth0CacheLocationEnum];

/**
 * 
 * @export
 * @interface GeneratedPaginatedResource
 */
export interface GeneratedPaginatedResource {
    /**
     * 
     * @type {GeneratedPaginatedResourceMeta}
     * @memberof GeneratedPaginatedResource
     */
    'meta': GeneratedPaginatedResourceMeta;
}
/**
 * 
 * @export
 * @interface GeneratedPaginatedResourceMeta
 */
export interface GeneratedPaginatedResourceMeta {
    /**
     * 
     * @type {number}
     * @memberof GeneratedPaginatedResourceMeta
     */
    'current_page': number;
    /**
     * 
     * @type {number}
     * @memberof GeneratedPaginatedResourceMeta
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof GeneratedPaginatedResourceMeta
     */
    'last_page': number;
    /**
     * 
     * @type {Array<GeneratedPaginatedResourceMetaLinks>}
     * @memberof GeneratedPaginatedResourceMeta
     */
    'links': Array<GeneratedPaginatedResourceMetaLinks>;
    /**
     * 
     * @type {string}
     * @memberof GeneratedPaginatedResourceMeta
     */
    'path': string;
    /**
     * 
     * @type {number}
     * @memberof GeneratedPaginatedResourceMeta
     */
    'per_page': number;
    /**
     * 
     * @type {number}
     * @memberof GeneratedPaginatedResourceMeta
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof GeneratedPaginatedResourceMeta
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface GeneratedPaginatedResourceMetaLinks
 */
export interface GeneratedPaginatedResourceMetaLinks {
    /**
     * 
     * @type {boolean}
     * @memberof GeneratedPaginatedResourceMetaLinks
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GeneratedPaginatedResourceMetaLinks
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratedPaginatedResourceMetaLinks
     */
    'url'?: string | null;
}
/**
 * The id for every models
 * @export
 * @interface IdModelComponent
 */
export interface IdModelComponent {
    /**
     * 
     * @type {number}
     * @memberof IdModelComponent
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject
     */
    'datasets': Array<number>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {ManagementGroupBaseModelComponent & IdModelComponent & SoftDeleteModelComponent & TimestampsModelComponent}
     * @memberof InlineResponse200
     */
    'data': ManagementGroupBaseModelComponent & IdModelComponent & SoftDeleteModelComponent & TimestampsModelComponent;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {Array<OpenAlexProxyConcept>}
     * @memberof InlineResponse2001
     */
    'results': Array<OpenAlexProxyConcept>;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {CompanyBaseModelComponent & IdModelComponent & SoftDeleteModelComponent & TimestampsModelComponent}
     * @memberof InlineResponse2002
     */
    'data': CompanyBaseModelComponent & IdModelComponent & SoftDeleteModelComponent & TimestampsModelComponent;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {Auth0ConnectionModel}
     * @memberof InlineResponse2003
     */
    'data': Auth0ConnectionModel;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {UserResource}
     * @memberof InlineResponse2004
     */
    'impersonate'?: UserResource;
    /**
     * 
     * @type {Array<AggregationModel>}
     * @memberof InlineResponse2004
     */
    'aggregations': Array<AggregationModel>;
    /**
     * 
     * @type {PaginationComponent & TalentModelArray}
     * @memberof InlineResponse2004
     */
    'talents': PaginationComponent & TalentModelArray;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {UsersListResource}
     * @memberof InlineResponse2005
     */
    'data'?: UsersListResource;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2006
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2006
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2007
     */
    'addedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2007
     */
    'skippedCount'?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {ManagementGroupBaseModelComponent & IdModelComponent & SoftDeleteModelComponent & TimestampsModelComponent}
     * @memberof InlineResponse201
     */
    'data': ManagementGroupBaseModelComponent & IdModelComponent & SoftDeleteModelComponent & TimestampsModelComponent;
}
/**
 * 
 * @export
 * @interface ManagementGroupAttachTeamsForm
 */
export interface ManagementGroupAttachTeamsForm {
    /**
     * The array is arbitrarily limited to 25 results to balance between business need while not having a finite result count. 
     * @type {Array<number>}
     * @memberof ManagementGroupAttachTeamsForm
     */
    'team_ids': Array<number>;
}
/**
 * 
 * @export
 * @interface ManagementGroupAttachUsersForm
 */
export interface ManagementGroupAttachUsersForm {
    /**
     * The array is arbitrarily limited to 25 results to balance between business need while not having a finite result count. 
     * @type {Array<number>}
     * @memberof ManagementGroupAttachUsersForm
     */
    'user_ids': Array<number>;
}
/**
 * A organization management group
 * @export
 * @interface ManagementGroupBaseModelComponent
 */
export interface ManagementGroupBaseModelComponent {
    /**
     * 
     * @type {string}
     * @memberof ManagementGroupBaseModelComponent
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ManagementGroupBaseModelComponent
     */
    'is_active': boolean;
}
/**
 * 
 * @export
 * @interface ManagementGroupDetachTeamsForm
 */
export interface ManagementGroupDetachTeamsForm {
    /**
     * The array is arbitrarily limited to 25 results to balance between business need while not having a finite result count. 
     * @type {Array<number>}
     * @memberof ManagementGroupDetachTeamsForm
     */
    'team_ids': Array<number>;
}
/**
 * 
 * @export
 * @interface ManagementGroupDetachUsersForm
 */
export interface ManagementGroupDetachUsersForm {
    /**
     * The array is arbitrarily limited to 25 results to balance between business need while not having a finite result count. 
     * @type {Array<number>}
     * @memberof ManagementGroupDetachUsersForm
     */
    'user_ids': Array<number>;
}
/**
 * 
 * @export
 * @interface ManagementGroupModelArray
 */
export interface ManagementGroupModelArray {
    /**
     * 
     * @type {Array<ManagementGroupResource>}
     * @memberof ManagementGroupModelArray
     */
    'data': Array<ManagementGroupResource>;
}
/**
 * 
 * @export
 * @interface ManagementGroupResource
 */
export interface ManagementGroupResource {
    /**
     * 
     * @type {string}
     * @memberof ManagementGroupResource
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ManagementGroupResource
     */
    'is_active': boolean;
    /**
     * 
     * @type {number}
     * @memberof ManagementGroupResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ManagementGroupResource
     */
    'deleted_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementGroupResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementGroupResource
     */
    'updated_at': string | null;
}
/**
 * 
 * @export
 * @interface ManagementGroupUpdateForm
 */
export interface ManagementGroupUpdateForm {
    /**
     * 
     * @type {string}
     * @memberof ManagementGroupUpdateForm
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManagementGroupUpdateForm
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface OpenAlexProxyConcept
 */
export interface OpenAlexProxyConcept {
    /**
     * 
     * @type {string}
     * @memberof OpenAlexProxyConcept
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OpenAlexProxyConcept
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof OpenAlexProxyConcept
     */
    'hint': string;
    /**
     * 
     * @type {number}
     * @memberof OpenAlexProxyConcept
     */
    'cited_by_count': number;
    /**
     * 
     * @type {number}
     * @memberof OpenAlexProxyConcept
     */
    'works_count': number;
    /**
     * 
     * @type {string}
     * @memberof OpenAlexProxyConcept
     */
    'entity_type': string;
    /**
     * 
     * @type {string}
     * @memberof OpenAlexProxyConcept
     */
    'external_id': string;
}
/**
 * 
 * @export
 * @interface OrganizationBaseModelComponent
 */
export interface OrganizationBaseModelComponent {
    /**
     * 
     * @type {string}
     * @memberof OrganizationBaseModelComponent
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OrganizationManagementGroupCreateForm
 */
export interface OrganizationManagementGroupCreateForm {
    /**
     * 
     * @type {string}
     * @memberof OrganizationManagementGroupCreateForm
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationManagementGroupCreateForm
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface OrganizationResource
 */
export interface OrganizationResource {
    /**
     * 
     * @type {string}
     * @memberof OrganizationResource
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResource
     */
    'deleted_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResource
     */
    'updated_at': string | null;
}
/**
 * 
 * @export
 * @interface PaginatedResourceOfCompany
 */
export interface PaginatedResourceOfCompany {
    /**
     * 
     * @type {GeneratedPaginatedResourceMeta}
     * @memberof PaginatedResourceOfCompany
     */
    'meta': GeneratedPaginatedResourceMeta;
    /**
     * 
     * @type {Array<CompanyResource>}
     * @memberof PaginatedResourceOfCompany
     */
    'data': Array<CompanyResource>;
}
/**
 * 
 * @export
 * @interface PaginatedResourceOfCorporateInstitution
 */
export interface PaginatedResourceOfCorporateInstitution {
    /**
     * 
     * @type {GeneratedPaginatedResourceMeta}
     * @memberof PaginatedResourceOfCorporateInstitution
     */
    'meta': GeneratedPaginatedResourceMeta;
    /**
     * 
     * @type {Array<CorporateInstitutionResource>}
     * @memberof PaginatedResourceOfCorporateInstitution
     */
    'data': Array<CorporateInstitutionResource>;
}
/**
 * 
 * @export
 * @interface PaginatedResourceOfManagementGroup
 */
export interface PaginatedResourceOfManagementGroup {
    /**
     * 
     * @type {GeneratedPaginatedResourceMeta}
     * @memberof PaginatedResourceOfManagementGroup
     */
    'meta': GeneratedPaginatedResourceMeta;
    /**
     * 
     * @type {Array<ManagementGroupResource>}
     * @memberof PaginatedResourceOfManagementGroup
     */
    'data': Array<ManagementGroupResource>;
}
/**
 * 
 * @export
 * @interface PaginatedResourceOfTeams
 */
export interface PaginatedResourceOfTeams {
    /**
     * 
     * @type {GeneratedPaginatedResourceMeta}
     * @memberof PaginatedResourceOfTeams
     */
    'meta': GeneratedPaginatedResourceMeta;
    /**
     * 
     * @type {Array<TeamResource>}
     * @memberof PaginatedResourceOfTeams
     */
    'data': Array<TeamResource>;
}
/**
 * 
 * @export
 * @interface PaginatedResourceOfUsers
 */
export interface PaginatedResourceOfUsers {
    /**
     * 
     * @type {GeneratedPaginatedResourceMeta}
     * @memberof PaginatedResourceOfUsers
     */
    'meta': GeneratedPaginatedResourceMeta;
    /**
     * 
     * @type {Array<UserResource>}
     * @memberof PaginatedResourceOfUsers
     */
    'data': Array<UserResource>;
}
/**
 * 
 * @export
 * @interface PaginationComponent
 */
export interface PaginationComponent {
    /**
     * 
     * @type {number}
     * @memberof PaginationComponent
     */
    'current_page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationComponent
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationComponent
     */
    'last_page': number;
    /**
     * 
     * @type {Array<GeneratedPaginatedResourceMetaLinks>}
     * @memberof PaginationComponent
     */
    'links': Array<GeneratedPaginatedResourceMetaLinks>;
    /**
     * 
     * @type {string}
     * @memberof PaginationComponent
     */
    'path': string;
    /**
     * 
     * @type {number}
     * @memberof PaginationComponent
     */
    'per_page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationComponent
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationComponent
     */
    'total': number;
}
/**
 * Soft deletes for models
 * @export
 * @interface SoftDeleteModelComponent
 */
export interface SoftDeleteModelComponent {
    /**
     * 
     * @type {string}
     * @memberof SoftDeleteModelComponent
     */
    'deleted_at': string | null;
}
/**
 * 
 * @export
 * @interface SortBy
 */
export interface SortBy {
    /**
     * 
     * @type {string}
     * @memberof SortBy
     */
    'sortBy'?: SortBySortByEnum;
}

export const SortBySortByEnum = {
    Id: 'id',
    Name: 'name'
} as const;

export type SortBySortByEnum = typeof SortBySortByEnum[keyof typeof SortBySortByEnum];

/**
 * 
 * @export
 * @interface SortBy1
 */
export interface SortBy1 {
    /**
     * 
     * @type {string}
     * @memberof SortBy1
     */
    'sortBy'?: SortBy1SortByEnum;
}

export const SortBy1SortByEnum = {
    Id: 'id',
    Name: 'name'
} as const;

export type SortBy1SortByEnum = typeof SortBy1SortByEnum[keyof typeof SortBy1SortByEnum];

/**
 * 
 * @export
 * @interface SortBy2
 */
export interface SortBy2 {
    /**
     * 
     * @type {string}
     * @memberof SortBy2
     */
    'sortBy'?: SortBy2SortByEnum;
}

export const SortBy2SortByEnum = {
    Id: 'id',
    Name: 'name'
} as const;

export type SortBy2SortByEnum = typeof SortBy2SortByEnum[keyof typeof SortBy2SortByEnum];

/**
 * 
 * @export
 * @interface SortBy3
 */
export interface SortBy3 {
    /**
     * 
     * @type {string}
     * @memberof SortBy3
     */
    'sortBy'?: SortBy3SortByEnum;
}

export const SortBy3SortByEnum = {
    Id: 'id',
    Name: 'name'
} as const;

export type SortBy3SortByEnum = typeof SortBy3SortByEnum[keyof typeof SortBy3SortByEnum];

/**
 * 
 * @export
 * @interface SortBy4
 */
export interface SortBy4 {
    /**
     * 
     * @type {string}
     * @memberof SortBy4
     */
    'sortBy'?: SortBy4SortByEnum;
}

export const SortBy4SortByEnum = {
    Id: 'id',
    Name: 'name'
} as const;

export type SortBy4SortByEnum = typeof SortBy4SortByEnum[keyof typeof SortBy4SortByEnum];

/**
 * 
 * @export
 * @interface SortableOrderRequestComponent
 */
export interface SortableOrderRequestComponent {
    /**
     * 
     * @type {string}
     * @memberof SortableOrderRequestComponent
     */
    'orderBy'?: SortableOrderRequestComponentOrderByEnum;
}

export const SortableOrderRequestComponentOrderByEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type SortableOrderRequestComponentOrderByEnum = typeof SortableOrderRequestComponentOrderByEnum[keyof typeof SortableOrderRequestComponentOrderByEnum];

/**
 * A base tag model
 * @export
 * @interface TagBaseModelComponent
 */
export interface TagBaseModelComponent {
    /**
     * 
     * @type {string}
     * @memberof TagBaseModelComponent
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface TagModelArray
 */
export interface TagModelArray {
    /**
     * 
     * @type {Array<TagResource>}
     * @memberof TagModelArray
     */
    'data': Array<TagResource>;
}
/**
 * 
 * @export
 * @interface TagResource
 */
export interface TagResource {
    /**
     * 
     * @type {string}
     * @memberof TagResource
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface TalentModelArray
 */
export interface TalentModelArray {
    /**
     * 
     * @type {Array<TalentResource>}
     * @memberof TalentModelArray
     */
    'data': Array<TalentResource>;
}
/**
 * 
 * @export
 * @interface TalentResource
 */
export interface TalentResource {
    /**
     * 
     * @type {string}
     * @memberof TalentResource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TalentResource
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof TalentResource
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof TalentResource
     */
    'professional_title': string;
    /**
     * 
     * @type {IdModelComponent & TimestampsModelComponent & TeamBaseModelComponent & ArchivedModelComponent}
     * @memberof TalentResource
     */
    'main_team'?: IdModelComponent & TimestampsModelComponent & TeamBaseModelComponent & ArchivedModelComponent;
    /**
     * 
     * @type {FileResource}
     * @memberof TalentResource
     */
    'profile_photo'?: FileResource;
    /**
     * 
     * @type {CategoryResource}
     * @memberof TalentResource
     */
    'category'?: CategoryResource;
    /**
     * 
     * @type {UniversityResource}
     * @memberof TalentResource
     */
    'university_affiliation'?: UniversityResource;
    /**
     * 
     * @type {string}
     * @memberof TalentResource
     */
    'position': string;
    /**
     * 
     * @type {DefTableModel}
     * @memberof TalentResource
     */
    'visa'?: DefTableModel;
    /**
     * 
     * @type {DefTableModel}
     * @memberof TalentResource
     */
    'export_control_status'?: DefTableModel;
    /**
     * 
     * @type {string}
     * @memberof TalentResource
     */
    'expected_graduation_date'?: string;
    /**
     * 
     * @type {Array<FormationModel>}
     * @memberof TalentResource
     */
    'degrees'?: Array<FormationModel>;
    /**
     * 
     * @type {Array<DefTableModel>}
     * @memberof TalentResource
     */
    'citizenships'?: Array<DefTableModel>;
    /**
     * 
     * @type {FileResource}
     * @memberof TalentResource
     */
    'resume'?: FileResource;
    /**
     * 
     * @type {Array<string>}
     * @memberof TalentResource
     */
    'topics'?: Array<string>;
    /**
     * 
     * @type {Array<AccessCategoryResource>}
     * @memberof TalentResource
     */
    'programs'?: Array<AccessCategoryResource>;
    /**
     * 
     * @type {Array<DefTableModel>}
     * @memberof TalentResource
     */
    'relevantFacts': Array<DefTableModel>;
    /**
     * 
     * @type {number}
     * @memberof TalentResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TalentResource
     */
    'deleted_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof TalentResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof TalentResource
     */
    'updated_at': string | null;
}
/**
 * 
 * @export
 * @interface TeamAdminModelComponent
 */
export interface TeamAdminModelComponent {
    /**
     * This is the disk name
     * @type {string}
     * @memberof TeamAdminModelComponent
     */
    'storage_location': string;
}
/**
 * 
 * @export
 * @interface TeamBaseModelComponent
 */
export interface TeamBaseModelComponent {
    /**
     * 
     * @type {string}
     * @memberof TeamBaseModelComponent
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TeamModelArray
 */
export interface TeamModelArray {
    /**
     * 
     * @type {Array<TeamResource>}
     * @memberof TeamModelArray
     */
    'data': Array<TeamResource>;
}
/**
 * 
 * @export
 * @interface TeamResource
 */
export interface TeamResource {
    /**
     * 
     * @type {string}
     * @memberof TeamResource
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof TeamResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamResource
     */
    'deleted_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamResource
     */
    'updated_at': string | null;
}
/**
 * Timestamps for models
 * @export
 * @interface TimestampsModelComponent
 */
export interface TimestampsModelComponent {
    /**
     * 
     * @type {string}
     * @memberof TimestampsModelComponent
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof TimestampsModelComponent
     */
    'updated_at': string | null;
}
/**
 * 
 * @export
 * @interface UniversityBaseModelComponent
 */
export interface UniversityBaseModelComponent {
    /**
     * 
     * @type {string}
     * @memberof UniversityBaseModelComponent
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UniversityBaseModelComponent
     */
    'country_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UniversityBaseModelComponent
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface UniversityResource
 */
export interface UniversityResource {
    /**
     * 
     * @type {number}
     * @memberof UniversityResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UniversityResource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UniversityResource
     */
    'country_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UniversityResource
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UniversityResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof UniversityResource
     */
    'updated_at': string | null;
}
/**
 * A base user model
 * @export
 * @interface UserBaseModelComponent
 */
export interface UserBaseModelComponent {
    /**
     * 
     * @type {string}
     * @memberof UserBaseModelComponent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserBaseModelComponent
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserBaseModelComponent
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserBaseModelComponent
     */
    'professional_title': string;
    /**
     * 
     * @type {IdModelComponent & TimestampsModelComponent & TeamBaseModelComponent & ArchivedModelComponent}
     * @memberof UserBaseModelComponent
     */
    'main_team'?: IdModelComponent & TimestampsModelComponent & TeamBaseModelComponent & ArchivedModelComponent;
    /**
     * 
     * @type {FileResource}
     * @memberof UserBaseModelComponent
     */
    'profile_photo'?: FileResource;
    /**
     * 
     * @type {CategoryResource}
     * @memberof UserBaseModelComponent
     */
    'category'?: CategoryResource;
    /**
     * 
     * @type {UniversityResource}
     * @memberof UserBaseModelComponent
     */
    'university_affiliation'?: UniversityResource;
}
/**
 * Extra properties attached to user model
 * @export
 * @interface UserExtraModelComponent
 */
export interface UserExtraModelComponent {
    /**
     * 
     * @type {string}
     * @memberof UserExtraModelComponent
     */
    'position': string;
    /**
     * 
     * @type {DefTableModel}
     * @memberof UserExtraModelComponent
     */
    'visa'?: DefTableModel;
    /**
     * 
     * @type {DefTableModel}
     * @memberof UserExtraModelComponent
     */
    'export_control_status'?: DefTableModel;
    /**
     * 
     * @type {string}
     * @memberof UserExtraModelComponent
     */
    'expected_graduation_date'?: string;
    /**
     * 
     * @type {Array<FormationModel>}
     * @memberof UserExtraModelComponent
     */
    'degrees'?: Array<FormationModel>;
    /**
     * 
     * @type {Array<DefTableModel>}
     * @memberof UserExtraModelComponent
     */
    'citizenships'?: Array<DefTableModel>;
    /**
     * 
     * @type {FileResource}
     * @memberof UserExtraModelComponent
     */
    'resume'?: FileResource;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserExtraModelComponent
     */
    'topics'?: Array<string>;
    /**
     * 
     * @type {Array<AccessCategoryResource>}
     * @memberof UserExtraModelComponent
     */
    'programs'?: Array<AccessCategoryResource>;
    /**
     * 
     * @type {Array<DefTableModel>}
     * @memberof UserExtraModelComponent
     */
    'relevantFacts': Array<DefTableModel>;
}
/**
 * 
 * @export
 * @interface UserModelArray
 */
export interface UserModelArray {
    /**
     * 
     * @type {Array<UserResource>}
     * @memberof UserModelArray
     */
    'data': Array<UserResource>;
}
/**
 * 
 * @export
 * @interface UserResource
 */
export interface UserResource {
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'professional_title': string;
    /**
     * 
     * @type {IdModelComponent & TimestampsModelComponent & TeamBaseModelComponent & ArchivedModelComponent}
     * @memberof UserResource
     */
    'main_team'?: IdModelComponent & TimestampsModelComponent & TeamBaseModelComponent & ArchivedModelComponent;
    /**
     * 
     * @type {FileResource}
     * @memberof UserResource
     */
    'profile_photo'?: FileResource;
    /**
     * 
     * @type {CategoryResource}
     * @memberof UserResource
     */
    'category'?: CategoryResource;
    /**
     * 
     * @type {UniversityResource}
     * @memberof UserResource
     */
    'university_affiliation'?: UniversityResource;
    /**
     * 
     * @type {number}
     * @memberof UserResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'deleted_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    'updated_at': string | null;
}
/**
 * 
 * @export
 * @interface UsersListBaseModel
 */
export interface UsersListBaseModel {
    /**
     * 
     * @type {string}
     * @memberof UsersListBaseModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UsersListBaseModel
     */
    'users_count': number;
    /**
     * Small subset of the users in the list. Limited to 3 users for now
     * @type {Array<UserResource>}
     * @memberof UsersListBaseModel
     */
    'users_preview': Array<UserResource>;
}
/**
 * 
 * @export
 * @interface UsersListCreateForm
 */
export interface UsersListCreateForm {
    /**
     * 
     * @type {string}
     * @memberof UsersListCreateForm
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UsersListResource
 */
export interface UsersListResource {
    /**
     * 
     * @type {string}
     * @memberof UsersListResource
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UsersListResource
     */
    'users_count': number;
    /**
     * Small subset of the users in the list. Limited to 3 users for now
     * @type {Array<UserResource>}
     * @memberof UsersListResource
     */
    'users_preview': Array<UserResource>;
    /**
     * 
     * @type {number}
     * @memberof UsersListResource
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UsersListResource
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersListResource
     */
    'updated_at': string | null;
}
/**
 * 
 * @export
 * @interface UsersListResourceArray
 */
export interface UsersListResourceArray {
    /**
     * 
     * @type {Array<UsersListResource>}
     * @memberof UsersListResourceArray
     */
    'data': Array<UsersListResource>;
}
/**
 * 
 * @export
 * @interface UsersListUpdateForm
 */
export interface UsersListUpdateForm {
    /**
     * 
     * @type {string}
     * @memberof UsersListUpdateForm
     */
    'name'?: string;
    /**
     * This list should not contain the current user id itself
     * @type {Array<number>}
     * @memberof UsersListUpdateForm
     */
    'addUsersIds'?: Array<number>;
    /**
     * This list should not contain values from addUsersIds or the current user id itself
     * @type {Array<number>}
     * @memberof UsersListUpdateForm
     */
    'removeUsersIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UsersListUsersShareForm
 */
export interface UsersListUsersShareForm {
    /**
     * An array of email addresses
     * @type {Array<string>}
     * @memberof UsersListUsersShareForm
     */
    'shareEmails'?: Array<string>;
    /**
     * An array of user ids
     * @type {Array<number>}
     * @memberof UsersListUsersShareForm
     */
    'shareUsersIds'?: Array<number>;
    /**
     * An array of users ids
     * @type {Array<number>}
     * @memberof UsersListUsersShareForm
     */
    'unshareUsersIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UsersRecommendedTalentsFeedRequest
 */
export interface UsersRecommendedTalentsFeedRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersRecommendedTalentsFeedRequest
     */
    'event': UsersRecommendedTalentsFeedRequestEventEnum;
    /**
     * 
     * @type {string}
     * @memberof UsersRecommendedTalentsFeedRequest
     */
    'eventName'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UsersRecommendedTalentsFeedRequest
     */
    'objectIDs': Array<number>;
}

export const UsersRecommendedTalentsFeedRequestEventEnum = {
    View: 'view',
    Click: 'click',
    Conversion: 'conversion'
} as const;

export type UsersRecommendedTalentsFeedRequestEventEnum = typeof UsersRecommendedTalentsFeedRequestEventEnum[keyof typeof UsersRecommendedTalentsFeedRequestEventEnum];


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send a csv of corporate institutions to import
         * @param {number} organizationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationCorporateInstitutionImport: async (organizationId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('adminOrganizationCorporateInstitutionImport', 'organizationId', organizationId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('adminOrganizationCorporateInstitutionImport', 'file', file)
            const localVarPath = `/admin/organizations/{organization_id}/corporate-institutions/import`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a csv of corporate institution interests to import
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationCorporateInstitutionInterestImport: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('adminOrganizationCorporateInstitutionInterestImport', 'file', file)
            const localVarPath = `/admin/corporate-institution-interests/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Trigger a job that will scrape the linkedin handle of all the organization\'s users
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationScrapeLinkedinHandles: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('adminOrganizationScrapeLinkedinHandles', 'organizationId', organizationId)
            const localVarPath = `/admin/organizations/{organization_id}/scrape-all-linkedin-handles`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationShowLatestScraping: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('adminOrganizationShowLatestScraping', 'organizationId', organizationId)
            const localVarPath = `/admin/organizations/{organization_id}/scrape-all-linkedin-handles`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyShow: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyShow', 'companyId', companyId)
            const localVarPath = `/companies/{company_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete multiple datasets
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDatasets: async (inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRealm: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getUserRealm', 'email', email)
            const localVarPath = `/users/realm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupDelete: async (managementGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupDelete', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupShow: async (managementGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupShow', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds one or many teams to the management group. If a team is already  a member of the group, the user will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupAttachTeamsForm} [managementGroupAttachTeamsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupTeamsAttach: async (managementGroupId: number, managementGroupAttachTeamsForm?: ManagementGroupAttachTeamsForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupTeamsAttach', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}/teams`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managementGroupAttachTeamsForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupTeamsAutocomplete: async (managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupTeamsAutocomplete', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}/teams-autocomplete`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id[]'] = id;
            }

            if (idOperator !== undefined) {
                localVarQueryParameter['id_operator'] = idOperator;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (nameOperator !== undefined) {
                localVarQueryParameter['name_operator'] = nameOperator;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes one or many teams from the management group. If a team is not a member of the group, the team will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupDetachTeamsForm} [managementGroupDetachTeamsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupTeamsDetach: async (managementGroupId: number, managementGroupDetachTeamsForm?: ManagementGroupDetachTeamsForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupTeamsDetach', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}/teams`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managementGroupDetachTeamsForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupTeamsList: async (managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupTeamsList', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}/teams`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id[]'] = id;
            }

            if (idOperator !== undefined) {
                localVarQueryParameter['id_operator'] = idOperator;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (nameOperator !== undefined) {
                localVarQueryParameter['name_operator'] = nameOperator;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {ManagementGroupUpdateForm} [managementGroupUpdateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupUpdate: async (managementGroupId: number, managementGroupUpdateForm?: ManagementGroupUpdateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupUpdate', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managementGroupUpdateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds one or many users to the management group. If a user is already  a member of the group, it will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupAttachUsersForm} [managementGroupAttachUsersForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupUsersAttach: async (managementGroupId: number, managementGroupAttachUsersForm?: ManagementGroupAttachUsersForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupUsersAttach', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}/users`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managementGroupAttachUsersForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupUsersAutocomplete: async (managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupUsersAutocomplete', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}/users-autocomplete`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id[]'] = id;
            }

            if (idOperator !== undefined) {
                localVarQueryParameter['id_operator'] = idOperator;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (nameOperator !== undefined) {
                localVarQueryParameter['name_operator'] = nameOperator;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes one or many users from the management group. If a user is not a member of the group, the user will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupDetachUsersForm} [managementGroupDetachUsersForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupUsersDetach: async (managementGroupId: number, managementGroupDetachUsersForm?: ManagementGroupDetachUsersForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupUsersDetach', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}/users`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managementGroupDetachUsersForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupUsersList: async (managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managementGroupId' is not null or undefined
            assertParamExists('managementGroupUsersList', 'managementGroupId', managementGroupId)
            const localVarPath = `/management-groups/{management_group_id}/users`
                .replace(`{${"management_group_id"}}`, encodeURIComponent(String(managementGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id[]'] = id;
            }

            if (idOperator !== undefined) {
                localVarQueryParameter['id_operator'] = idOperator;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (nameOperator !== undefined) {
                localVarQueryParameter['name_operator'] = nameOperator;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openAlexProxyConceptsAutocomplete: async (q: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('openAlexProxyConceptsAutocomplete', 'q', q)
            const localVarPath = `/openalex/concepts-autocomplete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {'name'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationCompanyList: async (organizationId: number, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', sortBy?: 'name', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organisationCompanyList', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organization_id}/companies`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {boolean} [isFavorited] 
         * @param {Array<string>} [concepts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationCorporateInstitutionList: async (organizationId: number, page?: number, perPage?: number, isFavorited?: boolean, concepts?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organisationCorporateInstitutionList', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organization_id}/corporate-institutions`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (isFavorited !== undefined) {
                localVarQueryParameter['is_favorited'] = isFavorited;
            }

            if (concepts) {
                localVarQueryParameter['concepts'] = concepts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {'id' | 'name' | 'created_at' | 'updated_at'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationManagementGroupsList: async (organizationId: number, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', sortBy?: 'id' | 'name' | 'created_at' | 'updated_at', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organisationManagementGroupsList', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organization_id}/management-groups`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {OrganizationManagementGroupCreateForm} [organizationManagementGroupCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationManagementGroupsCreate: async (organizationId: number, organizationManagementGroupCreateForm?: OrganizationManagementGroupCreateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationManagementGroupsCreate', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organization_id}/management-groups`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationManagementGroupCreateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [label] Filter by label (Value)
         * @param {'=' | '~='} [labelOperator] Filter by label operator
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {Array<string>} [subtractTagsByLabel] 
         * @param {'id' | 'label' | 'created_at' | 'updated_at'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProjectsTagList: async (userId: number, id?: Array<number>, idOperator?: '=' | '!=', label?: string, labelOperator?: '=' | '~=', page?: number, perPage?: number, orderBy?: 'asc' | 'desc', subtractTagsByLabel?: Array<string>, sortBy?: 'id' | 'label' | 'created_at' | 'updated_at', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userProjectsTagList', 'userId', userId)
            const localVarPath = `/users/{user_id}/projects/tags`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id[]'] = id;
            }

            if (idOperator !== undefined) {
                localVarQueryParameter['id_operator'] = idOperator;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (labelOperator !== undefined) {
                localVarQueryParameter['label_operator'] = labelOperator;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (subtractTagsByLabel) {
                localVarQueryParameter['subtractTagsByLabel'] = subtractTagsByLabel;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UsersListCreateForm} [usersListCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUsersListCreate: async (userId: number, usersListCreateForm?: UsersListCreateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userUsersListCreate', 'userId', userId)
            const localVarPath = `/users/{user_id}/users-lists`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersListCreateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUsersListList: async (userId: number, sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userUsersListList', 'userId', userId)
            const localVarPath = `/users/{user_id}/users-lists`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This will send a download request. The downloaded file will be sent by email, not through this endpoint
         * @param {number} usersListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListDownload: async (usersListId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersListId' is not null or undefined
            assertParamExists('usersListDownload', 'usersListId', usersListId)
            const localVarPath = `/users-lists/{users_list_id}/download`
                .replace(`{${"users_list_id"}}`, encodeURIComponent(String(usersListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all of the users that are part of the list themselves
         * @param {number} usersListId 
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListListeesList: async (usersListId: number, sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersListId' is not null or undefined
            assertParamExists('usersListListeesList', 'usersListId', usersListId)
            const localVarPath = `/users-lists/{users_list_id}/listees`
                .replace(`{${"users_list_id"}}`, encodeURIComponent(String(usersListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} usersListId 
         * @param {string} [query] The query string to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListUsersAutocomplete: async (usersListId: number, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersListId' is not null or undefined
            assertParamExists('usersListUsersAutocomplete', 'usersListId', usersListId)
            const localVarPath = `/users-lists/{users_list_id}/users-autocomplete`
                .replace(`{${"users_list_id"}}`, encodeURIComponent(String(usersListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the users that have access to the list
         * @param {number} usersListId 
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListUsersList: async (usersListId: number, sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersListId' is not null or undefined
            assertParamExists('usersListUsersList', 'usersListId', usersListId)
            const localVarPath = `/users-lists/{users_list_id}/users`
                .replace(`{${"users_list_id"}}`, encodeURIComponent(String(usersListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add or remove users as (sharee) to the list by email or id
         * @param {number} usersListId 
         * @param {UsersListUsersShareForm} [usersListUsersShareForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListUsersShare: async (usersListId: number, usersListUsersShareForm?: UsersListUsersShareForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersListId' is not null or undefined
            assertParamExists('usersListUsersShare', 'usersListId', usersListId)
            const localVarPath = `/users-lists/{users_list_id}/share`
                .replace(`{${"users_list_id"}}`, encodeURIComponent(String(usersListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersListUsersShareForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} usersListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListsDelete: async (usersListId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersListId' is not null or undefined
            assertParamExists('usersListsDelete', 'usersListId', usersListId)
            const localVarPath = `/users-lists`
                .replace(`{${"users_list_id"}}`, encodeURIComponent(String(usersListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} usersListId 
         * @param {UsersListUpdateForm} [usersListUpdateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListsUpdate: async (usersListId: number, usersListUpdateForm?: UsersListUpdateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersListId' is not null or undefined
            assertParamExists('usersListsUpdate', 'usersListId', usersListId)
            const localVarPath = `/users-lists`
                .replace(`{${"users_list_id"}}`, encodeURIComponent(String(usersListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersListUpdateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'hire' | 'internship'} [type] 
         * @param {string} [date] 
         * @param {string} [joinedAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRecommendedExpertsList: async (page?: number, perPage?: number, type?: 'hire' | 'internship', date?: string, joinedAfter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/recommended-experts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (joinedAfter !== undefined) {
                localVarQueryParameter['joinedAfter'] = (joinedAfter as any instanceof Date) ?
                    (joinedAfter as any).toISOString().substr(0,10) :
                    joinedAfter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Feed recommended talents personalization
         * @param {UsersRecommendedTalentsFeedRequest} [usersRecommendedTalentsFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRecommendedTalentsFeed: async (usersRecommendedTalentsFeedRequest?: UsersRecommendedTalentsFeedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/recommended-talents/feed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersRecommendedTalentsFeedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'hire' | 'internship'} [type] 
         * @param {string} [date] 
         * @param {string} [joinedAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRecommendedTalentsList: async (page?: number, perPage?: number, type?: 'hire' | 'internship', date?: string, joinedAfter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/recommended-talents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (joinedAfter !== undefined) {
                localVarQueryParameter['joinedAfter'] = (joinedAfter as any instanceof Date) ?
                    (joinedAfter as any).toISOString().substr(0,10) :
                    joinedAfter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [createdAtFrom] 
         * @param {Array<'range-1' | 'range-2' | 'range-3' | 'range-4' | 'range-5'>} [graduationPeriods] 
         * @param {Array<'postdoctoral' | 'doctoral' | 'masters' | 'bachelors' | 'graduate' | 'undergraduate_certificate' | 'integrated_mater' | 'associate' | 'other'>} [degreeTypes] 
         * @param {Array<string>} [degreeFields] 
         * @param {Array<string>} [universityAffiliations] 
         * @param {Array<'controlled' | 'uncontrolled'>} [exportControlStatuses] 
         * @param {Array<CountryListEnum>} [citizenships] 
         * @param {Array<'f-1' | 'f-2' | 'j-1' | 'j-2' | 'h-1b' | 'tn-1' | 'tn-2' | 'i-20' | 'visa_waiver_program' | 'n_a'>} [usVisas] 
         * @param {Array<string>} [programs] 
         * @param {number} [asUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSearchTalentsList: async (page?: number, perPage?: number, createdAtFrom?: string, graduationPeriods?: Array<'range-1' | 'range-2' | 'range-3' | 'range-4' | 'range-5'>, degreeTypes?: Array<'postdoctoral' | 'doctoral' | 'masters' | 'bachelors' | 'graduate' | 'undergraduate_certificate' | 'integrated_mater' | 'associate' | 'other'>, degreeFields?: Array<string>, universityAffiliations?: Array<string>, exportControlStatuses?: Array<'controlled' | 'uncontrolled'>, citizenships?: Array<CountryListEnum>, usVisas?: Array<'f-1' | 'f-2' | 'j-1' | 'j-2' | 'h-1b' | 'tn-1' | 'tn-2' | 'i-20' | 'visa_waiver_program' | 'n_a'>, programs?: Array<string>, asUserId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/talents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['created_at_from'] = (createdAtFrom as any instanceof Date) ?
                    (createdAtFrom as any).toISOString().substr(0,10) :
                    createdAtFrom;
            }

            if (graduationPeriods) {
                localVarQueryParameter['graduation_periods'] = graduationPeriods;
            }

            if (degreeTypes) {
                localVarQueryParameter['degree_types'] = degreeTypes;
            }

            if (degreeFields) {
                localVarQueryParameter['degree_fields'] = degreeFields;
            }

            if (universityAffiliations) {
                localVarQueryParameter['university_affiliations'] = universityAffiliations;
            }

            if (exportControlStatuses) {
                localVarQueryParameter['export_control_statuses'] = exportControlStatuses;
            }

            if (citizenships) {
                localVarQueryParameter['citizenships'] = citizenships;
            }

            if (usVisas) {
                localVarQueryParameter['us_visas'] = usVisas;
            }

            if (programs) {
                localVarQueryParameter['programs'] = programs;
            }

            if (asUserId !== undefined) {
                localVarQueryParameter['as_user_id'] = asUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Send a csv of corporate institutions to import
         * @param {number} organizationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationCorporateInstitutionImport(organizationId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationCorporateInstitutionImport(organizationId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send a csv of corporate institution interests to import
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationCorporateInstitutionInterestImport(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationCorporateInstitutionInterestImport(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Trigger a job that will scrape the linkedin handle of all the organization\'s users
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationScrapeLinkedinHandles(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationScrapeLinkedinHandles(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationShowLatestScraping(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationShowLatestScraping(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyShow(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyShow(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete multiple datasets
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDatasets(inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDatasets(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRealm(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRealm(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupDelete(managementGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupDelete(managementGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupShow(managementGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupShow(managementGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds one or many teams to the management group. If a team is already  a member of the group, the user will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupAttachTeamsForm} [managementGroupAttachTeamsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupTeamsAttach(managementGroupId: number, managementGroupAttachTeamsForm?: ManagementGroupAttachTeamsForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupTeamsAttach(managementGroupId, managementGroupAttachTeamsForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupTeamsAutocomplete(managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & TeamModelArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupTeamsAutocomplete(managementGroupId, id, idOperator, name, nameOperator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes one or many teams from the management group. If a team is not a member of the group, the team will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupDetachTeamsForm} [managementGroupDetachTeamsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupTeamsDetach(managementGroupId: number, managementGroupDetachTeamsForm?: ManagementGroupDetachTeamsForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupTeamsDetach(managementGroupId, managementGroupDetachTeamsForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupTeamsList(managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & TeamModelArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupTeamsList(managementGroupId, id, idOperator, name, nameOperator, sortBy, page, perPage, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {ManagementGroupUpdateForm} [managementGroupUpdateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupUpdate(managementGroupId: number, managementGroupUpdateForm?: ManagementGroupUpdateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupUpdate(managementGroupId, managementGroupUpdateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds one or many users to the management group. If a user is already  a member of the group, it will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupAttachUsersForm} [managementGroupAttachUsersForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupUsersAttach(managementGroupId: number, managementGroupAttachUsersForm?: ManagementGroupAttachUsersForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserBaseModelComponent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupUsersAttach(managementGroupId, managementGroupAttachUsersForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupUsersAutocomplete(managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & UserModelArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupUsersAutocomplete(managementGroupId, id, idOperator, name, nameOperator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes one or many users from the management group. If a user is not a member of the group, the user will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupDetachUsersForm} [managementGroupDetachUsersForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupUsersDetach(managementGroupId: number, managementGroupDetachUsersForm?: ManagementGroupDetachUsersForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserBaseModelComponent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupUsersDetach(managementGroupId, managementGroupDetachUsersForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGroupUsersList(managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & UserModelArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGroupUsersList(managementGroupId, id, idOperator, name, nameOperator, sortBy, page, perPage, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openAlexProxyConceptsAutocomplete(q: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openAlexProxyConceptsAutocomplete(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {'name'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organisationCompanyList(organizationId: number, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', sortBy?: 'name', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResourceOfCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organisationCompanyList(organizationId, page, perPage, orderBy, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {boolean} [isFavorited] 
         * @param {Array<string>} [concepts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organisationCorporateInstitutionList(organizationId: number, page?: number, perPage?: number, isFavorited?: boolean, concepts?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResourceOfCorporateInstitution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organisationCorporateInstitutionList(organizationId, page, perPage, isFavorited, concepts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {'id' | 'name' | 'created_at' | 'updated_at'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organisationManagementGroupsList(organizationId: number, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', sortBy?: 'id' | 'name' | 'created_at' | 'updated_at', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & ManagementGroupModelArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organisationManagementGroupsList(organizationId, page, perPage, orderBy, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {OrganizationManagementGroupCreateForm} [organizationManagementGroupCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationManagementGroupsCreate(organizationId: number, organizationManagementGroupCreateForm?: OrganizationManagementGroupCreateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationManagementGroupsCreate(organizationId, organizationManagementGroupCreateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [label] Filter by label (Value)
         * @param {'=' | '~='} [labelOperator] Filter by label operator
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {Array<string>} [subtractTagsByLabel] 
         * @param {'id' | 'label' | 'created_at' | 'updated_at'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProjectsTagList(userId: number, id?: Array<number>, idOperator?: '=' | '!=', label?: string, labelOperator?: '=' | '~=', page?: number, perPage?: number, orderBy?: 'asc' | 'desc', subtractTagsByLabel?: Array<string>, sortBy?: 'id' | 'label' | 'created_at' | 'updated_at', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & TagModelArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProjectsTagList(userId, id, idOperator, label, labelOperator, page, perPage, orderBy, subtractTagsByLabel, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UsersListCreateForm} [usersListCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUsersListCreate(userId: number, usersListCreateForm?: UsersListCreateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUsersListCreate(userId, usersListCreateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUsersListList(userId: number, sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & UsersListResourceArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUsersListList(userId, sortBy, page, perPage, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This will send a download request. The downloaded file will be sent by email, not through this endpoint
         * @param {number} usersListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersListDownload(usersListId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersListDownload(usersListId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all of the users that are part of the list themselves
         * @param {number} usersListId 
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersListListeesList(usersListId: number, sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResourceOfUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersListListeesList(usersListId, sortBy, page, perPage, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} usersListId 
         * @param {string} [query] The query string to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersListUsersAutocomplete(usersListId: number, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & UserModelArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersListUsersAutocomplete(usersListId, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the users that have access to the list
         * @param {number} usersListId 
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersListUsersList(usersListId: number, sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResourceOfUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersListUsersList(usersListId, sortBy, page, perPage, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add or remove users as (sharee) to the list by email or id
         * @param {number} usersListId 
         * @param {UsersListUsersShareForm} [usersListUsersShareForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersListUsersShare(usersListId: number, usersListUsersShareForm?: UsersListUsersShareForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResourceOfUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersListUsersShare(usersListId, usersListUsersShareForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} usersListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersListsDelete(usersListId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersListsDelete(usersListId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} usersListId 
         * @param {UsersListUpdateForm} [usersListUpdateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersListsUpdate(usersListId: number, usersListUpdateForm?: UsersListUpdateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & UsersListResourceArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersListsUpdate(usersListId, usersListUpdateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'hire' | 'internship'} [type] 
         * @param {string} [date] 
         * @param {string} [joinedAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRecommendedExpertsList(page?: number, perPage?: number, type?: 'hire' | 'internship', date?: string, joinedAfter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & UserModelArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRecommendedExpertsList(page, perPage, type, date, joinedAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Feed recommended talents personalization
         * @param {UsersRecommendedTalentsFeedRequest} [usersRecommendedTalentsFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRecommendedTalentsFeed(usersRecommendedTalentsFeedRequest?: UsersRecommendedTalentsFeedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRecommendedTalentsFeed(usersRecommendedTalentsFeedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'hire' | 'internship'} [type] 
         * @param {string} [date] 
         * @param {string} [joinedAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRecommendedTalentsList(page?: number, perPage?: number, type?: 'hire' | 'internship', date?: string, joinedAfter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedPaginatedResource & UserModelArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRecommendedTalentsList(page, perPage, type, date, joinedAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [createdAtFrom] 
         * @param {Array<'range-1' | 'range-2' | 'range-3' | 'range-4' | 'range-5'>} [graduationPeriods] 
         * @param {Array<'postdoctoral' | 'doctoral' | 'masters' | 'bachelors' | 'graduate' | 'undergraduate_certificate' | 'integrated_mater' | 'associate' | 'other'>} [degreeTypes] 
         * @param {Array<string>} [degreeFields] 
         * @param {Array<string>} [universityAffiliations] 
         * @param {Array<'controlled' | 'uncontrolled'>} [exportControlStatuses] 
         * @param {Array<CountryListEnum>} [citizenships] 
         * @param {Array<'f-1' | 'f-2' | 'j-1' | 'j-2' | 'h-1b' | 'tn-1' | 'tn-2' | 'i-20' | 'visa_waiver_program' | 'n_a'>} [usVisas] 
         * @param {Array<string>} [programs] 
         * @param {number} [asUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSearchTalentsList(page?: number, perPage?: number, createdAtFrom?: string, graduationPeriods?: Array<'range-1' | 'range-2' | 'range-3' | 'range-4' | 'range-5'>, degreeTypes?: Array<'postdoctoral' | 'doctoral' | 'masters' | 'bachelors' | 'graduate' | 'undergraduate_certificate' | 'integrated_mater' | 'associate' | 'other'>, degreeFields?: Array<string>, universityAffiliations?: Array<string>, exportControlStatuses?: Array<'controlled' | 'uncontrolled'>, citizenships?: Array<CountryListEnum>, usVisas?: Array<'f-1' | 'f-2' | 'j-1' | 'j-2' | 'h-1b' | 'tn-1' | 'tn-2' | 'i-20' | 'visa_waiver_program' | 'n_a'>, programs?: Array<string>, asUserId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSearchTalentsList(page, perPage, createdAtFrom, graduationPeriods, degreeTypes, degreeFields, universityAffiliations, exportControlStatuses, citizenships, usVisas, programs, asUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Send a csv of corporate institutions to import
         * @param {number} organizationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationCorporateInstitutionImport(organizationId: number, file: any, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.adminOrganizationCorporateInstitutionImport(organizationId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Send a csv of corporate institution interests to import
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationCorporateInstitutionInterestImport(file: any, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.adminOrganizationCorporateInstitutionInterestImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * Trigger a job that will scrape the linkedin handle of all the organization\'s users
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationScrapeLinkedinHandles(organizationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.adminOrganizationScrapeLinkedinHandles(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationShowLatestScraping(organizationId: number, options?: any): AxiosPromise<InlineResponse2006> {
            return localVarFp.adminOrganizationShowLatestScraping(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyShow(companyId: number, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.companyShow(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete multiple datasets
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDatasets(inlineObject?: InlineObject, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDatasets(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRealm(email: string, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.getUserRealm(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupDelete(managementGroupId: number, options?: any): AxiosPromise<void> {
            return localVarFp.managementGroupDelete(managementGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupShow(managementGroupId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.managementGroupShow(managementGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds one or many teams to the management group. If a team is already  a member of the group, the user will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupAttachTeamsForm} [managementGroupAttachTeamsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupTeamsAttach(managementGroupId: number, managementGroupAttachTeamsForm?: ManagementGroupAttachTeamsForm, options?: any): AxiosPromise<void> {
            return localVarFp.managementGroupTeamsAttach(managementGroupId, managementGroupAttachTeamsForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupTeamsAutocomplete(managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', options?: any): AxiosPromise<GeneratedPaginatedResource & TeamModelArray> {
            return localVarFp.managementGroupTeamsAutocomplete(managementGroupId, id, idOperator, name, nameOperator, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes one or many teams from the management group. If a team is not a member of the group, the team will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupDetachTeamsForm} [managementGroupDetachTeamsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupTeamsDetach(managementGroupId: number, managementGroupDetachTeamsForm?: ManagementGroupDetachTeamsForm, options?: any): AxiosPromise<void> {
            return localVarFp.managementGroupTeamsDetach(managementGroupId, managementGroupDetachTeamsForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupTeamsList(managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options?: any): AxiosPromise<GeneratedPaginatedResource & TeamModelArray> {
            return localVarFp.managementGroupTeamsList(managementGroupId, id, idOperator, name, nameOperator, sortBy, page, perPage, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {ManagementGroupUpdateForm} [managementGroupUpdateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupUpdate(managementGroupId: number, managementGroupUpdateForm?: ManagementGroupUpdateForm, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.managementGroupUpdate(managementGroupId, managementGroupUpdateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds one or many users to the management group. If a user is already  a member of the group, it will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupAttachUsersForm} [managementGroupAttachUsersForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupUsersAttach(managementGroupId: number, managementGroupAttachUsersForm?: ManagementGroupAttachUsersForm, options?: any): AxiosPromise<UserBaseModelComponent> {
            return localVarFp.managementGroupUsersAttach(managementGroupId, managementGroupAttachUsersForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupUsersAutocomplete(managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', options?: any): AxiosPromise<GeneratedPaginatedResource & UserModelArray> {
            return localVarFp.managementGroupUsersAutocomplete(managementGroupId, id, idOperator, name, nameOperator, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes one or many users from the management group. If a user is not a member of the group, the user will be ignored. 
         * @param {number} managementGroupId 
         * @param {ManagementGroupDetachUsersForm} [managementGroupDetachUsersForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupUsersDetach(managementGroupId: number, managementGroupDetachUsersForm?: ManagementGroupDetachUsersForm, options?: any): AxiosPromise<UserBaseModelComponent> {
            return localVarFp.managementGroupUsersDetach(managementGroupId, managementGroupDetachUsersForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} managementGroupId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [name] Filter by name (Value)
         * @param {'=' | '~='} [nameOperator] Filter by name operator
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGroupUsersList(managementGroupId: number, id?: Array<number>, idOperator?: '=' | '!=', name?: string, nameOperator?: '=' | '~=', sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options?: any): AxiosPromise<GeneratedPaginatedResource & UserModelArray> {
            return localVarFp.managementGroupUsersList(managementGroupId, id, idOperator, name, nameOperator, sortBy, page, perPage, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openAlexProxyConceptsAutocomplete(q: string, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.openAlexProxyConceptsAutocomplete(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {'name'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationCompanyList(organizationId: number, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', sortBy?: 'name', options?: any): AxiosPromise<PaginatedResourceOfCompany> {
            return localVarFp.organisationCompanyList(organizationId, page, perPage, orderBy, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {boolean} [isFavorited] 
         * @param {Array<string>} [concepts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationCorporateInstitutionList(organizationId: number, page?: number, perPage?: number, isFavorited?: boolean, concepts?: Array<string>, options?: any): AxiosPromise<PaginatedResourceOfCorporateInstitution> {
            return localVarFp.organisationCorporateInstitutionList(organizationId, page, perPage, isFavorited, concepts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {'id' | 'name' | 'created_at' | 'updated_at'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationManagementGroupsList(organizationId: number, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', sortBy?: 'id' | 'name' | 'created_at' | 'updated_at', options?: any): AxiosPromise<GeneratedPaginatedResource & ManagementGroupModelArray> {
            return localVarFp.organisationManagementGroupsList(organizationId, page, perPage, orderBy, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {OrganizationManagementGroupCreateForm} [organizationManagementGroupCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationManagementGroupsCreate(organizationId: number, organizationManagementGroupCreateForm?: OrganizationManagementGroupCreateForm, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.organizationManagementGroupsCreate(organizationId, organizationManagementGroupCreateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {Array<number>} [id] Filter by id (Collection)
         * @param {'=' | '!='} [idOperator] Filter by id operator
         * @param {string} [label] Filter by label (Value)
         * @param {'=' | '~='} [labelOperator] Filter by label operator
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {Array<string>} [subtractTagsByLabel] 
         * @param {'id' | 'label' | 'created_at' | 'updated_at'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProjectsTagList(userId: number, id?: Array<number>, idOperator?: '=' | '!=', label?: string, labelOperator?: '=' | '~=', page?: number, perPage?: number, orderBy?: 'asc' | 'desc', subtractTagsByLabel?: Array<string>, sortBy?: 'id' | 'label' | 'created_at' | 'updated_at', options?: any): AxiosPromise<GeneratedPaginatedResource & TagModelArray> {
            return localVarFp.userProjectsTagList(userId, id, idOperator, label, labelOperator, page, perPage, orderBy, subtractTagsByLabel, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {UsersListCreateForm} [usersListCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUsersListCreate(userId: number, usersListCreateForm?: UsersListCreateForm, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.userUsersListCreate(userId, usersListCreateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUsersListList(userId: number, sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options?: any): AxiosPromise<GeneratedPaginatedResource & UsersListResourceArray> {
            return localVarFp.userUsersListList(userId, sortBy, page, perPage, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * This will send a download request. The downloaded file will be sent by email, not through this endpoint
         * @param {number} usersListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListDownload(usersListId: number, options?: any): AxiosPromise<void> {
            return localVarFp.usersListDownload(usersListId, options).then((request) => request(axios, basePath));
        },
        /**
         * List all of the users that are part of the list themselves
         * @param {number} usersListId 
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListListeesList(usersListId: number, sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options?: any): AxiosPromise<PaginatedResourceOfUsers> {
            return localVarFp.usersListListeesList(usersListId, sortBy, page, perPage, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} usersListId 
         * @param {string} [query] The query string to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListUsersAutocomplete(usersListId: number, query?: string, options?: any): AxiosPromise<GeneratedPaginatedResource & UserModelArray> {
            return localVarFp.usersListUsersAutocomplete(usersListId, query, options).then((request) => request(axios, basePath));
        },
        /**
         * List the users that have access to the list
         * @param {number} usersListId 
         * @param {object} [sortBy] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListUsersList(usersListId: number, sortBy?: object, page?: number, perPage?: number, orderBy?: 'asc' | 'desc', options?: any): AxiosPromise<PaginatedResourceOfUsers> {
            return localVarFp.usersListUsersList(usersListId, sortBy, page, perPage, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Add or remove users as (sharee) to the list by email or id
         * @param {number} usersListId 
         * @param {UsersListUsersShareForm} [usersListUsersShareForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListUsersShare(usersListId: number, usersListUsersShareForm?: UsersListUsersShareForm, options?: any): AxiosPromise<PaginatedResourceOfUsers> {
            return localVarFp.usersListUsersShare(usersListId, usersListUsersShareForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} usersListId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListsDelete(usersListId: number, options?: any): AxiosPromise<void> {
            return localVarFp.usersListsDelete(usersListId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} usersListId 
         * @param {UsersListUpdateForm} [usersListUpdateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListsUpdate(usersListId: number, usersListUpdateForm?: UsersListUpdateForm, options?: any): AxiosPromise<GeneratedPaginatedResource & UsersListResourceArray> {
            return localVarFp.usersListsUpdate(usersListId, usersListUpdateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'hire' | 'internship'} [type] 
         * @param {string} [date] 
         * @param {string} [joinedAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRecommendedExpertsList(page?: number, perPage?: number, type?: 'hire' | 'internship', date?: string, joinedAfter?: string, options?: any): AxiosPromise<GeneratedPaginatedResource & UserModelArray> {
            return localVarFp.usersRecommendedExpertsList(page, perPage, type, date, joinedAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * Feed recommended talents personalization
         * @param {UsersRecommendedTalentsFeedRequest} [usersRecommendedTalentsFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRecommendedTalentsFeed(usersRecommendedTalentsFeedRequest?: UsersRecommendedTalentsFeedRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersRecommendedTalentsFeed(usersRecommendedTalentsFeedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'hire' | 'internship'} [type] 
         * @param {string} [date] 
         * @param {string} [joinedAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRecommendedTalentsList(page?: number, perPage?: number, type?: 'hire' | 'internship', date?: string, joinedAfter?: string, options?: any): AxiosPromise<GeneratedPaginatedResource & UserModelArray> {
            return localVarFp.usersRecommendedTalentsList(page, perPage, type, date, joinedAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [createdAtFrom] 
         * @param {Array<'range-1' | 'range-2' | 'range-3' | 'range-4' | 'range-5'>} [graduationPeriods] 
         * @param {Array<'postdoctoral' | 'doctoral' | 'masters' | 'bachelors' | 'graduate' | 'undergraduate_certificate' | 'integrated_mater' | 'associate' | 'other'>} [degreeTypes] 
         * @param {Array<string>} [degreeFields] 
         * @param {Array<string>} [universityAffiliations] 
         * @param {Array<'controlled' | 'uncontrolled'>} [exportControlStatuses] 
         * @param {Array<CountryListEnum>} [citizenships] 
         * @param {Array<'f-1' | 'f-2' | 'j-1' | 'j-2' | 'h-1b' | 'tn-1' | 'tn-2' | 'i-20' | 'visa_waiver_program' | 'n_a'>} [usVisas] 
         * @param {Array<string>} [programs] 
         * @param {number} [asUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSearchTalentsList(page?: number, perPage?: number, createdAtFrom?: string, graduationPeriods?: Array<'range-1' | 'range-2' | 'range-3' | 'range-4' | 'range-5'>, degreeTypes?: Array<'postdoctoral' | 'doctoral' | 'masters' | 'bachelors' | 'graduate' | 'undergraduate_certificate' | 'integrated_mater' | 'associate' | 'other'>, degreeFields?: Array<string>, universityAffiliations?: Array<string>, exportControlStatuses?: Array<'controlled' | 'uncontrolled'>, citizenships?: Array<CountryListEnum>, usVisas?: Array<'f-1' | 'f-2' | 'j-1' | 'j-2' | 'h-1b' | 'tn-1' | 'tn-2' | 'i-20' | 'visa_waiver_program' | 'n_a'>, programs?: Array<string>, asUserId?: number, options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.usersSearchTalentsList(page, perPage, createdAtFrom, graduationPeriods, degreeTypes, degreeFields, universityAffiliations, exportControlStatuses, citizenships, usVisas, programs, asUserId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminOrganizationCorporateInstitutionImport operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminOrganizationCorporateInstitutionImportRequest
 */
export interface DefaultApiAdminOrganizationCorporateInstitutionImportRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiAdminOrganizationCorporateInstitutionImport
     */
    readonly organizationId: number

    /**
     * 
     * @type {any}
     * @memberof DefaultApiAdminOrganizationCorporateInstitutionImport
     */
    readonly file: any
}

/**
 * Request parameters for adminOrganizationCorporateInstitutionInterestImport operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminOrganizationCorporateInstitutionInterestImportRequest
 */
export interface DefaultApiAdminOrganizationCorporateInstitutionInterestImportRequest {
    /**
     * 
     * @type {any}
     * @memberof DefaultApiAdminOrganizationCorporateInstitutionInterestImport
     */
    readonly file: any
}

/**
 * Request parameters for adminOrganizationScrapeLinkedinHandles operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminOrganizationScrapeLinkedinHandlesRequest
 */
export interface DefaultApiAdminOrganizationScrapeLinkedinHandlesRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiAdminOrganizationScrapeLinkedinHandles
     */
    readonly organizationId: number
}

/**
 * Request parameters for adminOrganizationShowLatestScraping operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminOrganizationShowLatestScrapingRequest
 */
export interface DefaultApiAdminOrganizationShowLatestScrapingRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiAdminOrganizationShowLatestScraping
     */
    readonly organizationId: number
}

/**
 * Request parameters for companyShow operation in DefaultApi.
 * @export
 * @interface DefaultApiCompanyShowRequest
 */
export interface DefaultApiCompanyShowRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiCompanyShow
     */
    readonly companyId: number
}

/**
 * Request parameters for deleteDatasets operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteDatasetsRequest
 */
export interface DefaultApiDeleteDatasetsRequest {
    /**
     * 
     * @type {InlineObject}
     * @memberof DefaultApiDeleteDatasets
     */
    readonly inlineObject?: InlineObject
}

/**
 * Request parameters for getUserRealm operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUserRealmRequest
 */
export interface DefaultApiGetUserRealmRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetUserRealm
     */
    readonly email: string
}

/**
 * Request parameters for managementGroupDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupDeleteRequest
 */
export interface DefaultApiManagementGroupDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupDelete
     */
    readonly managementGroupId: number
}

/**
 * Request parameters for managementGroupShow operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupShowRequest
 */
export interface DefaultApiManagementGroupShowRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupShow
     */
    readonly managementGroupId: number
}

/**
 * Request parameters for managementGroupTeamsAttach operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupTeamsAttachRequest
 */
export interface DefaultApiManagementGroupTeamsAttachRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupTeamsAttach
     */
    readonly managementGroupId: number

    /**
     * 
     * @type {ManagementGroupAttachTeamsForm}
     * @memberof DefaultApiManagementGroupTeamsAttach
     */
    readonly managementGroupAttachTeamsForm?: ManagementGroupAttachTeamsForm
}

/**
 * Request parameters for managementGroupTeamsAutocomplete operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupTeamsAutocompleteRequest
 */
export interface DefaultApiManagementGroupTeamsAutocompleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupTeamsAutocomplete
     */
    readonly managementGroupId: number

    /**
     * Filter by id (Collection)
     * @type {Array<number>}
     * @memberof DefaultApiManagementGroupTeamsAutocomplete
     */
    readonly id?: Array<number>

    /**
     * Filter by id operator
     * @type {'=' | '!='}
     * @memberof DefaultApiManagementGroupTeamsAutocomplete
     */
    readonly idOperator?: '=' | '!='

    /**
     * Filter by name (Value)
     * @type {string}
     * @memberof DefaultApiManagementGroupTeamsAutocomplete
     */
    readonly name?: string

    /**
     * Filter by name operator
     * @type {'=' | '~='}
     * @memberof DefaultApiManagementGroupTeamsAutocomplete
     */
    readonly nameOperator?: '=' | '~='
}

/**
 * Request parameters for managementGroupTeamsDetach operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupTeamsDetachRequest
 */
export interface DefaultApiManagementGroupTeamsDetachRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupTeamsDetach
     */
    readonly managementGroupId: number

    /**
     * 
     * @type {ManagementGroupDetachTeamsForm}
     * @memberof DefaultApiManagementGroupTeamsDetach
     */
    readonly managementGroupDetachTeamsForm?: ManagementGroupDetachTeamsForm
}

/**
 * Request parameters for managementGroupTeamsList operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupTeamsListRequest
 */
export interface DefaultApiManagementGroupTeamsListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupTeamsList
     */
    readonly managementGroupId: number

    /**
     * Filter by id (Collection)
     * @type {Array<number>}
     * @memberof DefaultApiManagementGroupTeamsList
     */
    readonly id?: Array<number>

    /**
     * Filter by id operator
     * @type {'=' | '!='}
     * @memberof DefaultApiManagementGroupTeamsList
     */
    readonly idOperator?: '=' | '!='

    /**
     * Filter by name (Value)
     * @type {string}
     * @memberof DefaultApiManagementGroupTeamsList
     */
    readonly name?: string

    /**
     * Filter by name operator
     * @type {'=' | '~='}
     * @memberof DefaultApiManagementGroupTeamsList
     */
    readonly nameOperator?: '=' | '~='

    /**
     * 
     * @type {object}
     * @memberof DefaultApiManagementGroupTeamsList
     */
    readonly sortBy?: object

    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupTeamsList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupTeamsList
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultApiManagementGroupTeamsList
     */
    readonly orderBy?: 'asc' | 'desc'
}

/**
 * Request parameters for managementGroupUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupUpdateRequest
 */
export interface DefaultApiManagementGroupUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupUpdate
     */
    readonly managementGroupId: number

    /**
     * 
     * @type {ManagementGroupUpdateForm}
     * @memberof DefaultApiManagementGroupUpdate
     */
    readonly managementGroupUpdateForm?: ManagementGroupUpdateForm
}

/**
 * Request parameters for managementGroupUsersAttach operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupUsersAttachRequest
 */
export interface DefaultApiManagementGroupUsersAttachRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupUsersAttach
     */
    readonly managementGroupId: number

    /**
     * 
     * @type {ManagementGroupAttachUsersForm}
     * @memberof DefaultApiManagementGroupUsersAttach
     */
    readonly managementGroupAttachUsersForm?: ManagementGroupAttachUsersForm
}

/**
 * Request parameters for managementGroupUsersAutocomplete operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupUsersAutocompleteRequest
 */
export interface DefaultApiManagementGroupUsersAutocompleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupUsersAutocomplete
     */
    readonly managementGroupId: number

    /**
     * Filter by id (Collection)
     * @type {Array<number>}
     * @memberof DefaultApiManagementGroupUsersAutocomplete
     */
    readonly id?: Array<number>

    /**
     * Filter by id operator
     * @type {'=' | '!='}
     * @memberof DefaultApiManagementGroupUsersAutocomplete
     */
    readonly idOperator?: '=' | '!='

    /**
     * Filter by name (Value)
     * @type {string}
     * @memberof DefaultApiManagementGroupUsersAutocomplete
     */
    readonly name?: string

    /**
     * Filter by name operator
     * @type {'=' | '~='}
     * @memberof DefaultApiManagementGroupUsersAutocomplete
     */
    readonly nameOperator?: '=' | '~='
}

/**
 * Request parameters for managementGroupUsersDetach operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupUsersDetachRequest
 */
export interface DefaultApiManagementGroupUsersDetachRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupUsersDetach
     */
    readonly managementGroupId: number

    /**
     * 
     * @type {ManagementGroupDetachUsersForm}
     * @memberof DefaultApiManagementGroupUsersDetach
     */
    readonly managementGroupDetachUsersForm?: ManagementGroupDetachUsersForm
}

/**
 * Request parameters for managementGroupUsersList operation in DefaultApi.
 * @export
 * @interface DefaultApiManagementGroupUsersListRequest
 */
export interface DefaultApiManagementGroupUsersListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupUsersList
     */
    readonly managementGroupId: number

    /**
     * Filter by id (Collection)
     * @type {Array<number>}
     * @memberof DefaultApiManagementGroupUsersList
     */
    readonly id?: Array<number>

    /**
     * Filter by id operator
     * @type {'=' | '!='}
     * @memberof DefaultApiManagementGroupUsersList
     */
    readonly idOperator?: '=' | '!='

    /**
     * Filter by name (Value)
     * @type {string}
     * @memberof DefaultApiManagementGroupUsersList
     */
    readonly name?: string

    /**
     * Filter by name operator
     * @type {'=' | '~='}
     * @memberof DefaultApiManagementGroupUsersList
     */
    readonly nameOperator?: '=' | '~='

    /**
     * 
     * @type {object}
     * @memberof DefaultApiManagementGroupUsersList
     */
    readonly sortBy?: object

    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupUsersList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiManagementGroupUsersList
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultApiManagementGroupUsersList
     */
    readonly orderBy?: 'asc' | 'desc'
}

/**
 * Request parameters for openAlexProxyConceptsAutocomplete operation in DefaultApi.
 * @export
 * @interface DefaultApiOpenAlexProxyConceptsAutocompleteRequest
 */
export interface DefaultApiOpenAlexProxyConceptsAutocompleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiOpenAlexProxyConceptsAutocomplete
     */
    readonly q: string
}

/**
 * Request parameters for organisationCompanyList operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganisationCompanyListRequest
 */
export interface DefaultApiOrganisationCompanyListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiOrganisationCompanyList
     */
    readonly organizationId: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiOrganisationCompanyList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiOrganisationCompanyList
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultApiOrganisationCompanyList
     */
    readonly orderBy?: 'asc' | 'desc'

    /**
     * 
     * @type {'name'}
     * @memberof DefaultApiOrganisationCompanyList
     */
    readonly sortBy?: 'name'
}

/**
 * Request parameters for organisationCorporateInstitutionList operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganisationCorporateInstitutionListRequest
 */
export interface DefaultApiOrganisationCorporateInstitutionListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiOrganisationCorporateInstitutionList
     */
    readonly organizationId: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiOrganisationCorporateInstitutionList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiOrganisationCorporateInstitutionList
     */
    readonly perPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof DefaultApiOrganisationCorporateInstitutionList
     */
    readonly isFavorited?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultApiOrganisationCorporateInstitutionList
     */
    readonly concepts?: Array<string>
}

/**
 * Request parameters for organisationManagementGroupsList operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganisationManagementGroupsListRequest
 */
export interface DefaultApiOrganisationManagementGroupsListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiOrganisationManagementGroupsList
     */
    readonly organizationId: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiOrganisationManagementGroupsList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiOrganisationManagementGroupsList
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultApiOrganisationManagementGroupsList
     */
    readonly orderBy?: 'asc' | 'desc'

    /**
     * 
     * @type {'id' | 'name' | 'created_at' | 'updated_at'}
     * @memberof DefaultApiOrganisationManagementGroupsList
     */
    readonly sortBy?: 'id' | 'name' | 'created_at' | 'updated_at'
}

/**
 * Request parameters for organizationManagementGroupsCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganizationManagementGroupsCreateRequest
 */
export interface DefaultApiOrganizationManagementGroupsCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiOrganizationManagementGroupsCreate
     */
    readonly organizationId: number

    /**
     * 
     * @type {OrganizationManagementGroupCreateForm}
     * @memberof DefaultApiOrganizationManagementGroupsCreate
     */
    readonly organizationManagementGroupCreateForm?: OrganizationManagementGroupCreateForm
}

/**
 * Request parameters for userProjectsTagList operation in DefaultApi.
 * @export
 * @interface DefaultApiUserProjectsTagListRequest
 */
export interface DefaultApiUserProjectsTagListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUserProjectsTagList
     */
    readonly userId: number

    /**
     * Filter by id (Collection)
     * @type {Array<number>}
     * @memberof DefaultApiUserProjectsTagList
     */
    readonly id?: Array<number>

    /**
     * Filter by id operator
     * @type {'=' | '!='}
     * @memberof DefaultApiUserProjectsTagList
     */
    readonly idOperator?: '=' | '!='

    /**
     * Filter by label (Value)
     * @type {string}
     * @memberof DefaultApiUserProjectsTagList
     */
    readonly label?: string

    /**
     * Filter by label operator
     * @type {'=' | '~='}
     * @memberof DefaultApiUserProjectsTagList
     */
    readonly labelOperator?: '=' | '~='

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUserProjectsTagList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUserProjectsTagList
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultApiUserProjectsTagList
     */
    readonly orderBy?: 'asc' | 'desc'

    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultApiUserProjectsTagList
     */
    readonly subtractTagsByLabel?: Array<string>

    /**
     * 
     * @type {'id' | 'label' | 'created_at' | 'updated_at'}
     * @memberof DefaultApiUserProjectsTagList
     */
    readonly sortBy?: 'id' | 'label' | 'created_at' | 'updated_at'
}

/**
 * Request parameters for userUsersListCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiUserUsersListCreateRequest
 */
export interface DefaultApiUserUsersListCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUserUsersListCreate
     */
    readonly userId: number

    /**
     * 
     * @type {UsersListCreateForm}
     * @memberof DefaultApiUserUsersListCreate
     */
    readonly usersListCreateForm?: UsersListCreateForm
}

/**
 * Request parameters for userUsersListList operation in DefaultApi.
 * @export
 * @interface DefaultApiUserUsersListListRequest
 */
export interface DefaultApiUserUsersListListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUserUsersListList
     */
    readonly userId: number

    /**
     * 
     * @type {object}
     * @memberof DefaultApiUserUsersListList
     */
    readonly sortBy?: object

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUserUsersListList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUserUsersListList
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultApiUserUsersListList
     */
    readonly orderBy?: 'asc' | 'desc'
}

/**
 * Request parameters for usersListDownload operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersListDownloadRequest
 */
export interface DefaultApiUsersListDownloadRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListDownload
     */
    readonly usersListId: number
}

/**
 * Request parameters for usersListListeesList operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersListListeesListRequest
 */
export interface DefaultApiUsersListListeesListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListListeesList
     */
    readonly usersListId: number

    /**
     * 
     * @type {object}
     * @memberof DefaultApiUsersListListeesList
     */
    readonly sortBy?: object

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListListeesList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListListeesList
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultApiUsersListListeesList
     */
    readonly orderBy?: 'asc' | 'desc'
}

/**
 * Request parameters for usersListUsersAutocomplete operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersListUsersAutocompleteRequest
 */
export interface DefaultApiUsersListUsersAutocompleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListUsersAutocomplete
     */
    readonly usersListId: number

    /**
     * The query string to search
     * @type {string}
     * @memberof DefaultApiUsersListUsersAutocomplete
     */
    readonly query?: string
}

/**
 * Request parameters for usersListUsersList operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersListUsersListRequest
 */
export interface DefaultApiUsersListUsersListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListUsersList
     */
    readonly usersListId: number

    /**
     * 
     * @type {object}
     * @memberof DefaultApiUsersListUsersList
     */
    readonly sortBy?: object

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListUsersList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListUsersList
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultApiUsersListUsersList
     */
    readonly orderBy?: 'asc' | 'desc'
}

/**
 * Request parameters for usersListUsersShare operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersListUsersShareRequest
 */
export interface DefaultApiUsersListUsersShareRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListUsersShare
     */
    readonly usersListId: number

    /**
     * 
     * @type {UsersListUsersShareForm}
     * @memberof DefaultApiUsersListUsersShare
     */
    readonly usersListUsersShareForm?: UsersListUsersShareForm
}

/**
 * Request parameters for usersListsDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersListsDeleteRequest
 */
export interface DefaultApiUsersListsDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListsDelete
     */
    readonly usersListId: number
}

/**
 * Request parameters for usersListsUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersListsUpdateRequest
 */
export interface DefaultApiUsersListsUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersListsUpdate
     */
    readonly usersListId: number

    /**
     * 
     * @type {UsersListUpdateForm}
     * @memberof DefaultApiUsersListsUpdate
     */
    readonly usersListUpdateForm?: UsersListUpdateForm
}

/**
 * Request parameters for usersRecommendedExpertsList operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersRecommendedExpertsListRequest
 */
export interface DefaultApiUsersRecommendedExpertsListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersRecommendedExpertsList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersRecommendedExpertsList
     */
    readonly perPage?: number

    /**
     * 
     * @type {'hire' | 'internship'}
     * @memberof DefaultApiUsersRecommendedExpertsList
     */
    readonly type?: 'hire' | 'internship'

    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersRecommendedExpertsList
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersRecommendedExpertsList
     */
    readonly joinedAfter?: string
}

/**
 * Request parameters for usersRecommendedTalentsFeed operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersRecommendedTalentsFeedRequest
 */
export interface DefaultApiUsersRecommendedTalentsFeedRequest {
    /**
     * 
     * @type {UsersRecommendedTalentsFeedRequest}
     * @memberof DefaultApiUsersRecommendedTalentsFeed
     */
    readonly usersRecommendedTalentsFeedRequest?: UsersRecommendedTalentsFeedRequest
}

/**
 * Request parameters for usersRecommendedTalentsList operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersRecommendedTalentsListRequest
 */
export interface DefaultApiUsersRecommendedTalentsListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersRecommendedTalentsList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersRecommendedTalentsList
     */
    readonly perPage?: number

    /**
     * 
     * @type {'hire' | 'internship'}
     * @memberof DefaultApiUsersRecommendedTalentsList
     */
    readonly type?: 'hire' | 'internship'

    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersRecommendedTalentsList
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersRecommendedTalentsList
     */
    readonly joinedAfter?: string
}

/**
 * Request parameters for usersSearchTalentsList operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersSearchTalentsListRequest
 */
export interface DefaultApiUsersSearchTalentsListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly perPage?: number

    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly createdAtFrom?: string

    /**
     * 
     * @type {Array<'range-1' | 'range-2' | 'range-3' | 'range-4' | 'range-5'>}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly graduationPeriods?: Array<'range-1' | 'range-2' | 'range-3' | 'range-4' | 'range-5'>

    /**
     * 
     * @type {Array<'postdoctoral' | 'doctoral' | 'masters' | 'bachelors' | 'graduate' | 'undergraduate_certificate' | 'integrated_mater' | 'associate' | 'other'>}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly degreeTypes?: Array<'postdoctoral' | 'doctoral' | 'masters' | 'bachelors' | 'graduate' | 'undergraduate_certificate' | 'integrated_mater' | 'associate' | 'other'>

    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly degreeFields?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly universityAffiliations?: Array<string>

    /**
     * 
     * @type {Array<'controlled' | 'uncontrolled'>}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly exportControlStatuses?: Array<'controlled' | 'uncontrolled'>

    /**
     * 
     * @type {Array<CountryListEnum>}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly citizenships?: Array<CountryListEnum>

    /**
     * 
     * @type {Array<'f-1' | 'f-2' | 'j-1' | 'j-2' | 'h-1b' | 'tn-1' | 'tn-2' | 'i-20' | 'visa_waiver_program' | 'n_a'>}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly usVisas?: Array<'f-1' | 'f-2' | 'j-1' | 'j-2' | 'h-1b' | 'tn-1' | 'tn-2' | 'i-20' | 'visa_waiver_program' | 'n_a'>

    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly programs?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof DefaultApiUsersSearchTalentsList
     */
    readonly asUserId?: number
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Send a csv of corporate institutions to import
     * @param {DefaultApiAdminOrganizationCorporateInstitutionImportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminOrganizationCorporateInstitutionImport(requestParameters: DefaultApiAdminOrganizationCorporateInstitutionImportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminOrganizationCorporateInstitutionImport(requestParameters.organizationId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send a csv of corporate institution interests to import
     * @param {DefaultApiAdminOrganizationCorporateInstitutionInterestImportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminOrganizationCorporateInstitutionInterestImport(requestParameters: DefaultApiAdminOrganizationCorporateInstitutionInterestImportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminOrganizationCorporateInstitutionInterestImport(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Trigger a job that will scrape the linkedin handle of all the organization\'s users
     * @param {DefaultApiAdminOrganizationScrapeLinkedinHandlesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminOrganizationScrapeLinkedinHandles(requestParameters: DefaultApiAdminOrganizationScrapeLinkedinHandlesRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminOrganizationScrapeLinkedinHandles(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAdminOrganizationShowLatestScrapingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminOrganizationShowLatestScraping(requestParameters: DefaultApiAdminOrganizationShowLatestScrapingRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminOrganizationShowLatestScraping(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCompanyShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companyShow(requestParameters: DefaultApiCompanyShowRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companyShow(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete multiple datasets
     * @param {DefaultApiDeleteDatasetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDatasets(requestParameters: DefaultApiDeleteDatasetsRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteDatasets(requestParameters.inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiGetUserRealmRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserRealm(requestParameters: DefaultApiGetUserRealmRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserRealm(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiManagementGroupDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupDelete(requestParameters: DefaultApiManagementGroupDeleteRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupDelete(requestParameters.managementGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiManagementGroupShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupShow(requestParameters: DefaultApiManagementGroupShowRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupShow(requestParameters.managementGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds one or many teams to the management group. If a team is already  a member of the group, the user will be ignored. 
     * @param {DefaultApiManagementGroupTeamsAttachRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupTeamsAttach(requestParameters: DefaultApiManagementGroupTeamsAttachRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupTeamsAttach(requestParameters.managementGroupId, requestParameters.managementGroupAttachTeamsForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiManagementGroupTeamsAutocompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupTeamsAutocomplete(requestParameters: DefaultApiManagementGroupTeamsAutocompleteRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupTeamsAutocomplete(requestParameters.managementGroupId, requestParameters.id, requestParameters.idOperator, requestParameters.name, requestParameters.nameOperator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes one or many teams from the management group. If a team is not a member of the group, the team will be ignored. 
     * @param {DefaultApiManagementGroupTeamsDetachRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupTeamsDetach(requestParameters: DefaultApiManagementGroupTeamsDetachRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupTeamsDetach(requestParameters.managementGroupId, requestParameters.managementGroupDetachTeamsForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiManagementGroupTeamsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupTeamsList(requestParameters: DefaultApiManagementGroupTeamsListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupTeamsList(requestParameters.managementGroupId, requestParameters.id, requestParameters.idOperator, requestParameters.name, requestParameters.nameOperator, requestParameters.sortBy, requestParameters.page, requestParameters.perPage, requestParameters.orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiManagementGroupUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupUpdate(requestParameters: DefaultApiManagementGroupUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupUpdate(requestParameters.managementGroupId, requestParameters.managementGroupUpdateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds one or many users to the management group. If a user is already  a member of the group, it will be ignored. 
     * @param {DefaultApiManagementGroupUsersAttachRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupUsersAttach(requestParameters: DefaultApiManagementGroupUsersAttachRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupUsersAttach(requestParameters.managementGroupId, requestParameters.managementGroupAttachUsersForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiManagementGroupUsersAutocompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupUsersAutocomplete(requestParameters: DefaultApiManagementGroupUsersAutocompleteRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupUsersAutocomplete(requestParameters.managementGroupId, requestParameters.id, requestParameters.idOperator, requestParameters.name, requestParameters.nameOperator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes one or many users from the management group. If a user is not a member of the group, the user will be ignored. 
     * @param {DefaultApiManagementGroupUsersDetachRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupUsersDetach(requestParameters: DefaultApiManagementGroupUsersDetachRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupUsersDetach(requestParameters.managementGroupId, requestParameters.managementGroupDetachUsersForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiManagementGroupUsersListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public managementGroupUsersList(requestParameters: DefaultApiManagementGroupUsersListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).managementGroupUsersList(requestParameters.managementGroupId, requestParameters.id, requestParameters.idOperator, requestParameters.name, requestParameters.nameOperator, requestParameters.sortBy, requestParameters.page, requestParameters.perPage, requestParameters.orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiOpenAlexProxyConceptsAutocompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public openAlexProxyConceptsAutocomplete(requestParameters: DefaultApiOpenAlexProxyConceptsAutocompleteRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).openAlexProxyConceptsAutocomplete(requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiOrganisationCompanyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public organisationCompanyList(requestParameters: DefaultApiOrganisationCompanyListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).organisationCompanyList(requestParameters.organizationId, requestParameters.page, requestParameters.perPage, requestParameters.orderBy, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiOrganisationCorporateInstitutionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public organisationCorporateInstitutionList(requestParameters: DefaultApiOrganisationCorporateInstitutionListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).organisationCorporateInstitutionList(requestParameters.organizationId, requestParameters.page, requestParameters.perPage, requestParameters.isFavorited, requestParameters.concepts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiOrganisationManagementGroupsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public organisationManagementGroupsList(requestParameters: DefaultApiOrganisationManagementGroupsListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).organisationManagementGroupsList(requestParameters.organizationId, requestParameters.page, requestParameters.perPage, requestParameters.orderBy, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiOrganizationManagementGroupsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public organizationManagementGroupsCreate(requestParameters: DefaultApiOrganizationManagementGroupsCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).organizationManagementGroupsCreate(requestParameters.organizationId, requestParameters.organizationManagementGroupCreateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUserProjectsTagListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userProjectsTagList(requestParameters: DefaultApiUserProjectsTagListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userProjectsTagList(requestParameters.userId, requestParameters.id, requestParameters.idOperator, requestParameters.label, requestParameters.labelOperator, requestParameters.page, requestParameters.perPage, requestParameters.orderBy, requestParameters.subtractTagsByLabel, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUserUsersListCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userUsersListCreate(requestParameters: DefaultApiUserUsersListCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userUsersListCreate(requestParameters.userId, requestParameters.usersListCreateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUserUsersListListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userUsersListList(requestParameters: DefaultApiUserUsersListListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userUsersListList(requestParameters.userId, requestParameters.sortBy, requestParameters.page, requestParameters.perPage, requestParameters.orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This will send a download request. The downloaded file will be sent by email, not through this endpoint
     * @param {DefaultApiUsersListDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersListDownload(requestParameters: DefaultApiUsersListDownloadRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersListDownload(requestParameters.usersListId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all of the users that are part of the list themselves
     * @param {DefaultApiUsersListListeesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersListListeesList(requestParameters: DefaultApiUsersListListeesListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersListListeesList(requestParameters.usersListId, requestParameters.sortBy, requestParameters.page, requestParameters.perPage, requestParameters.orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersListUsersAutocompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersListUsersAutocomplete(requestParameters: DefaultApiUsersListUsersAutocompleteRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersListUsersAutocomplete(requestParameters.usersListId, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the users that have access to the list
     * @param {DefaultApiUsersListUsersListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersListUsersList(requestParameters: DefaultApiUsersListUsersListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersListUsersList(requestParameters.usersListId, requestParameters.sortBy, requestParameters.page, requestParameters.perPage, requestParameters.orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add or remove users as (sharee) to the list by email or id
     * @param {DefaultApiUsersListUsersShareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersListUsersShare(requestParameters: DefaultApiUsersListUsersShareRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersListUsersShare(requestParameters.usersListId, requestParameters.usersListUsersShareForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersListsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersListsDelete(requestParameters: DefaultApiUsersListsDeleteRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersListsDelete(requestParameters.usersListId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersListsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersListsUpdate(requestParameters: DefaultApiUsersListsUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersListsUpdate(requestParameters.usersListId, requestParameters.usersListUpdateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersRecommendedExpertsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersRecommendedExpertsList(requestParameters: DefaultApiUsersRecommendedExpertsListRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersRecommendedExpertsList(requestParameters.page, requestParameters.perPage, requestParameters.type, requestParameters.date, requestParameters.joinedAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Feed recommended talents personalization
     * @param {DefaultApiUsersRecommendedTalentsFeedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersRecommendedTalentsFeed(requestParameters: DefaultApiUsersRecommendedTalentsFeedRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersRecommendedTalentsFeed(requestParameters.usersRecommendedTalentsFeedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersRecommendedTalentsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersRecommendedTalentsList(requestParameters: DefaultApiUsersRecommendedTalentsListRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersRecommendedTalentsList(requestParameters.page, requestParameters.perPage, requestParameters.type, requestParameters.date, requestParameters.joinedAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersSearchTalentsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersSearchTalentsList(requestParameters: DefaultApiUsersSearchTalentsListRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersSearchTalentsList(requestParameters.page, requestParameters.perPage, requestParameters.createdAtFrom, requestParameters.graduationPeriods, requestParameters.degreeTypes, requestParameters.degreeFields, requestParameters.universityAffiliations, requestParameters.exportControlStatuses, requestParameters.citizenships, requestParameters.usVisas, requestParameters.programs, requestParameters.asUserId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PubliclyAccessibleApi - axios parameter creator
 * @export
 */
export const PubliclyAccessibleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PubliclyAccessibleApi - functional programming interface
 * @export
 */
export const PubliclyAccessibleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PubliclyAccessibleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontendConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PubliclyAccessibleApi - factory interface
 * @export
 */
export const PubliclyAccessibleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PubliclyAccessibleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfiguration(options?: any): AxiosPromise<FrontendConfiguration> {
            return localVarFp.getClientConfiguration(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PubliclyAccessibleApi - object-oriented interface
 * @export
 * @class PubliclyAccessibleApi
 * @extends {BaseAPI}
 */
export class PubliclyAccessibleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubliclyAccessibleApi
     */
    public getClientConfiguration(options?: AxiosRequestConfig) {
        return PubliclyAccessibleApiFp(this.configuration).getClientConfiguration(options).then((request) => request(this.axios, this.basePath));
    }
}


