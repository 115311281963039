import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType,
} from "@/reports/ReportModel";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["Users.count"],
  timeDimensions: [],
  order: {
    "Users.lastName": "asc",
  },
  segments: [
    "ProjectParticipations.onlyActiveParticipations",
    "ProjectParticipations.onlyStateCompleted",
    "ProjectParticipations.notDeleted",
    "Users.notDeleted",
    "ProjectLeader.notDeleted",
  ],
  dimensions: [
    "Users.email",
    "Users.firstName",
    "Users.lastName",
    "Users.srcOrganization",
    "Users.userCategoryLabel",
    "ProjectParticipations.endedStatus",
  ],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "Users.userCategoryLabel",
    label: "User Category",
  },
  {
    dimension: "ProjectParticipations.projectRole",
    label: "Project User Role",
  },
  {
    dimension: "SrcAccessCategoryTags.businessEntity",
    label: "Business Entity",
  },
  {
    dimension: "SrcAccessCategoryTags.center",
    label: "Center",
  },
  {
    dimension: "SrcAccessCategoryTags.theme",
    label: "Theme",
  },
  {
    dimension: "SrcAccessCategoryTags.thrust",
    label: "Thrust",
  },
  {
    dimension: "SrcAccessCategoryTags.subthrust",
    label: "Subthrust",
  },
  {
    dimension: "SrcAccessCategoryTags.area",
    label: "Science Area",
  },
  {
    dimension: "SrcAccessCategoryTags.seismicshift",
    label: "Seismic Shift",
  },
  {
    dimension: "Projects.name",
    label: "Project Name",
  },
  {
    dimension: "ProjectLeader.participationUniversityName",
    label: "Project University",
  },
  {
    dimension: "ProjectLeader.name",
    label: "Project Leader",
  },
  {
    dimension: "Projects.businessId",
    label: "Project ID",
  },
  {
    dimension: "ProjectParticipations.endedStatus",
    label: "User Status",
    defaultValue: "Active",
  },
  {
    dimension: "ProjectParticipations.teamName",
    label: "User Team",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "First name",
    key: "Users.firstName",
    type: ReportColumnType.Text,
  },
  {
    title: "Last name",
    key: "Users.lastName",
    type: ReportColumnType.Text,
  },
  {
    title: "Organization",
    key: "Users.srcOrganization",
    type: ReportColumnType.Text,
  },
  {
    title: "Email address",
    key: "Users.email",
    type: ReportColumnType.Text,
  },
  {
    title: "User category",
    key: "Users.userCategoryLabel",
    type: ReportColumnType.Text,
  },
  {
    title: "User Status",
    key: "ProjectParticipations.endedStatus",
    type: ReportColumnType.Text,
  },
];

export const ProjectParticipantMailingListReportConfig: ReportConfiguration = {
  name: "Project Participant Mailing List (252M)",
  section: "People",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "Projects.startsAt",
    endsAt: "Projects.endsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
};
