import ITimestamps from "@/models/TimestampsInterface";
import IModel from "@/models/IModel";

export default class Address implements ITimestamps, IModel {
  public id: number;
  public created_at: string | null = null;
  public updated_at: string | null = null;
  public address_line_1: string | null = null;
  public address_line_2: string | null = null;
  public city: string | null = null;
  public region: string | null = null;
  public postal_code: string | null = null;
  public country_code: string | null = null;

  get formatted(): string {
    return [
      this.address_line_1,
      this.address_line_2,
      this.city,
      this.region,
      this.postal_code,
      this.country_code
    ]
      .filter((field): field is string => field !== null)
      .join(", ");
  }
}
