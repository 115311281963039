import { Singleton } from "@/utils/Singleton";

const cubejsEnvironmentUrlMapping = [
  {
    host: "app.pillar.science",
    url: "https://cubejs.pillar.science/cubejs-api/v1",
  },
  {
    host: "app.kube.pillar.science",
    url: "https://cubejs.pillar.science/cubejs-api/v1",
  },
  {
    host: "staging.pillar.science",
    url: "https://extra-falcon.aws-us-east-1.cubecloudapp.dev/cubejs-api/v1",
  },
  {
    host: "staging.kube.pillar.science",
    url: "https://extra-falcon.aws-us-east-1.cubecloudapp.dev/cubejs-api/v1",
  },
  {
    host: "demo.pillar.science",
    url: "https://dominant-sloughhouse.aws-us-east-1.cubecloudapp.dev/cubejs-api/v1",
  },
  {
    host: "demo.kube.pillar.science",
    url: "https://dominant-sloughhouse.aws-us-east-1.cubecloudapp.dev/cubejs-api/v1",
  },
  {
    host: "sandbox.pillar.science",
    url: "https://still-sole.aws-us-east-1.cubecloudapp.dev/cubejs-api/v1",
  },
  {
    host: "sandbox.kube.pillar.science",
    url: "https://still-sole.aws-us-east-1.cubecloudapp.dev/cubejs-api/v1",
  },
  {
    host: "localhost:8080",
    url: "http://localhost:4000/cubejs-api/v1",
  },
];

/**
 * Use apiUrl instead. This function has been exported only to be imported in a test
 *
 * @internal
 * @param host string
 * @param protocol string
 */
export function resolveApiUrl(host: string, protocol = "https:"): string {
  const regexRootDomain = /^app\.(.*)$/;

  if (regexRootDomain.test(host)) {
    const apiHost = host.replace(regexRootDomain, "api.$1");

    return `${protocol}//${apiHost}`;
  }

  const regexes = [
    /^(.*)\.(preview-apps\.pillar\.science)$/,
    /^(.*)\.(kube\.pillar\.science)$/,
  ];

  for (const regex of regexes) {
    if (regex.test(host)) {
      return host.replace(regex, `${protocol}//$1-api.$2`);
    }
  }

  const regExp = /^(.*)\.(pillar\.science)$/;

  if (regExp.test(host)) {
    return host.replace(regExp, `${protocol}//$1.api.$2`);
  }

  return `${protocol}//localhost:8000`;
}

export const apiUrl = new Singleton(() =>
  resolveApiUrl(window.location.host, window.location.protocol)
);

export function resolveCubejsUrl(host: string, protocol = "https:"): string {
  const reg = /(.*)\.(preview-apps\.pillar\.science)$/;
  if (reg.test(host)) {
    return host.replace(reg, `${protocol}//$1-cubejs.$2/cubejs-api/v1`);
  }

  const config = cubejsEnvironmentUrlMapping.find((elem) => elem.host === host);

  if (!config) {
    throw new Error("Could not resolve cubejs url for host");
  }

  return config.url;
}

export const DEFAULT_DEBOUNCE = 200;
export const DEBOUNCE_MID = 600;
export const ACCOUNT_EXPIRATION_THRESHOLD_DAYS = 30;

/**
 * @deprecated
 */
export function getEnvFromHost(): string {
  const host = window.location.host;
  const regExp = /^(.*)\.pillar\.science$/;

  if (regExp.test(host)) {
    return host.replace(regExp, `$1`);
  }

  return "dev";
}
