import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import Organization from "@/models/Organization";

export function show(teamId: number) {
  return $http
    .get(`${prefix}/teams/${teamId}/organization`)
    .then(response => responseToClass(Organization, response));
}
