import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
} from "@/reports/ReportModel";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["Teams.count"],
  order: [["Users.lastName", "asc"]],
  dimensions: [
    "Users.email",
    "Users.category",
    "Users.firstName",
    "Users.lastName",
    "Users.srcOrganization",
    "Users.userCategoryLabel",
    "TeamUser.id",
  ],
  segments: ["Users.notDeleted", "TeamUser.notDeleted"],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "MainTeams.name",
    label: "Team Name",
  },
  {
    dimension: "Users.userCategoryLabel",
    label: "User Category",
  },
  {
    dimension: "Users.current",
    label: "Active",
    defaultValue: "Yes",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Last name",
    key: "Users.lastName",
    type: ReportColumnType.Text,
  },
  {
    title: "First name",
    key: "Users.firstName",
    type: ReportColumnType.Text,
  },
  {
    title: "Organization",
    key: "Users.srcOrganization",
    type: ReportColumnType.Text,
  },
  {
    title: "Email address",
    key: "Users.email",
    type: ReportColumnType.Text,
  },
  {
    title: "User Category",
    key: "Users.userCategoryLabel",
    type: ReportColumnType.Text,
  },
];

export const TeamMailingListReportConfig: ReportConfiguration = {
  name: "Team Mailing List (203)",
  section: "People",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: false,
  sortColumns: extractSortColumnsFromQuery(query),
};
