import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import Team from "@/models/Team";
import { AxiosPromise } from "axios";
import Project from "@/models/Project";
import { Roles } from "@/staticCollections/projectRoles";

export function list(userId: number): AxiosPromise<Team[]> {
  return $http
    .get(`${prefix}/users/${userId}/teams`)
    .then((response) => responseToClass(Team, response));
}

export function show(userId: number, teamId: number): AxiosPromise<Team> {
  return $http
    .get(`${prefix}/users/${userId}/teams/${teamId}`)
    .then((response) => responseToClass(Team, response));
}

export class AvailableRoleResponse {
  roles: Roles[] = [];
}

export function availableRoles(
  userId: number,
  teamId: number,
  currentRole: Roles = Roles.Manager
): AxiosPromise<AvailableRoleResponse> {
  return $http
    .get(`${prefix}/users/${userId}/teams/${teamId}/available-roles`, {
      params: { currentRole },
    })
    .then((response) => responseToClass(AvailableRoleResponse, response));
}

export function projects(
  userId: number,
  teamId: number
): AxiosPromise<Project[]> {
  return $http
    .get(`${prefix}/users/${userId}/teams/${teamId}/projects`)
    .then((response) => responseToClass(Project, response));
}
