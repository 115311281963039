import { ToastProgrammatic } from "buefy";
import { BNoticeComponent, BNoticeConfig } from "buefy/types/components";

interface IToastProgrammatic {
  open: (params: BNoticeConfig | string) => BNoticeComponent;
}

function toastMixin(mixinParams: Partial<BNoticeConfig>): IToastProgrammatic {
  return {
    open: (params: BNoticeConfig | string) => {
      if (typeof params === "string") {
        params = {
          message: params
        };
      }

      return ToastProgrammatic.open({ ...mixinParams, ...params });
    }
  };
}

export default toastMixin({
  position: "is-bottom"
});

export const ToastSuccess: IToastProgrammatic = toastMixin({
  type: "is-success"
});

export const ToastDanger: IToastProgrammatic = toastMixin({
  type: "is-danger"
});

export const ToastWarning: IToastProgrammatic = toastMixin({
  type: "is-warning"
});
