import { isInteger } from "lodash";

/**
 * Tries to parse any type of value. If the value is not
 * parsable, then it returns the defaultValue. If a float
 * or double is passed, then the return value is the parsed
 * value rounded to the closest integer value. If it is
 * parsed to an int, then the parsed value is returned
 *
 * @param subject
 * @param defaultValue
 * @param radix
 */
export function parseIntOrDefault(
  subject: unknown,
  defaultValue: number,
  radix?: number
): number {
  if (typeof subject === "number") {
    return isInteger(subject) ? subject : Math.round(subject);
  } else if (typeof subject !== "string") {
    return defaultValue;
  }

  const result = parseInt(subject, radix);

  if (!result) {
    return defaultValue;
  }

  return result;
}
