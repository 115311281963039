import { CommentFormInterface } from "@/interfaces/forms/CommentForm";
import { AxiosPromise } from "axios";
import Comment from "@/models/Comment";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";

export function list(
  datasetId: number,
  protocolId: number
): AxiosPromise<Comment[]> {
  return $http
    .get(`${prefix}/datasets/${datasetId}/protocols/${protocolId}/comments`)
    .then(response => responseToClass(Comment, response));
}

export function store(
  datasetId: number,
  protocolId: number,
  data: CommentFormInterface
): AxiosPromise<Comment> {
  return $http
    .post(
      `${prefix}/datasets/${datasetId}/protocols/${protocolId}/comments`,
      data
    )
    .then(response => responseToClass(Comment, response));
}

export function remove(
  datasetId: number,
  protocolId: number,
  commentId: number
) {
  return $http.delete(
    `${prefix}/datasets/${datasetId}/protocols/${protocolId}/comments/${commentId}`
  );
}
