import * as Sentry from "@sentry/vue";
import VueRouter from "vue-router";
import { ToastDanger } from "@/toast/ToastProgrammatic";

export function defaultErrorHandler(error?: Error): void {
  Sentry.captureException(error);

  console.log(error);

  ToastDanger.open("An error occurred");
}

export function defaultNavigationErrorHandler(error?: Error): void {
  if (VueRouter.isNavigationFailure(error)) {
    // do nothing
    return;
  }

  defaultErrorHandler(error);
}
