import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import Mentionable from "@/models/Mentionable";
import debounce from "awesome-debounce-promise";
import { ApiClient } from "@/api";

export enum MutationTypes {
  AddMentionables = "AddMentionables",
  AddToQueryQueue = "AddToQueryQueue",
  ClearQueue = "ClearQueue"
}

export interface IMentionState {
  queryQueue: string[];
  mentionables: Mentionable[];
}

const state: IMentionState = {
  queryQueue: [],
  mentionables: []
};

const getters: GetterTree<IMentionState, RootState> = {
  tags: (state): string[] => state.mentionables.map(m => m.tag)
};

const debouncedFetch = debounce(
  function({ commit, state }): Promise<Mentionable[]> {
    const queryQueue = state.queryQueue;

    commit(MutationTypes.ClearQueue);

    if (queryQueue.length === 0) {
      return Promise.resolve([]);
    }

    return ApiClient.Mention.tags(queryQueue).then(response => {
      commit(MutationTypes.AddMentionables, response.data);

      return response.data;
    });
  },
  100,
  { onlyResolvesLast: false }
);

const actions: ActionTree<IMentionState, RootState> = {
  addToQueryQueue({ commit, state }, tags: string[]): Promise<Mentionable[]> {
    commit(MutationTypes.AddToQueryQueue, tags);

    return debouncedFetch({ commit, state });
  }
};

const mutations: MutationTree<IMentionState> = {
  [MutationTypes.AddToQueryQueue](state, tags: string[]) {
    const newTags = tags
      .filter((value, index, self) => self.indexOf(value) === index) // unique
      .filter(
        t =>
          !state.mentionables.map(m => m.tag).includes(t) &&
          !state.queryQueue.includes(t)
      );

    newTags.forEach(t => state.queryQueue.push(t));
  },
  [MutationTypes.AddMentionables](state, mentionables: Mentionable[]) {
    mentionables.forEach(mentionable => {
      const index = state.mentionables.findIndex(
        m => m.tag === mentionable.tag
      );

      if (index >= 0) {
        state.mentionables.splice(index, 1, mentionable);
      } else {
        state.mentionables.push(mentionable);
      }
    });
  },
  [MutationTypes.ClearQueue](state) {
    state.queryQueue = [];
  }
};

export const mention: Module<IMentionState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
