import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/files/:id/download",
    name: "file_download",
    component: () =>
      import(
        /* webpackChunkName: "route.file.download" */ "../views/Download.vue"
      ),
    meta: {
      auth: true
    }
  }
];

export default routes;
