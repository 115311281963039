


































































import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ValidationMixin from "../../mixins/ValidationMixin";
import { maxLength, minLength, required, url } from "vuelidate/lib/validators";
import InterestsAutocomplete from "@/psComponents/businessComponents/InterestsAutocomplete.vue";
import { namespace } from "vuex-class";
import UpdateFormRequest from "@/api/requests/user/UpdateFormRequest";
import { AxiosPromise } from "axios";
import User from "@/models/User";
import { AmplitudeEvents } from "@/events/AmplitudeEvents";
import { defaultErrorHandler } from "@/utils/Error";
import UpdateTransformer from "@/forms/transformers/organization/user/UpdateTransformer";
import { ToastSuccess } from "@/toast/ToastProgrammatic";
import { AmplitudeTeamType } from "@/models/Team";

const user = namespace("user");

@Component({
  components: { InterestsAutocomplete },
  validations: {
    interests: {
      required,
      minLengthArray: minLength(5),
      maxLengthArray: maxLength(100),
    },
  },
})
export default class UserInterestsModal extends mixins(ValidationMixin) {
  @user.State("user") user!: User;
  @user.Action("updateUser") updateUser!: (
    form: UpdateFormRequest
  ) => AxiosPromise<User>;

  interests: string[] = [];
  loading = false;

  mounted(): void {
    this.$bus.$emit(AmplitudeEvents.UserProfileInterestsModalViewed, {
      target_user_profile_id: this.user.id,
      user_type: this.user.category_code,
      isIndustryMember: this.isIndustryMember,
    });
  }

  handleUpdateUser(): void {
    this.$v.$touch();

    if (this.$v.$error) {
      return;
    }

    this.loading = true;

    const form = UpdateTransformer(
      Object.assign(new User(), this.user, { interests: this.interests })
    );

    this.updateUser(form)
      .then(() => {
        this.$bus.$emit(AmplitudeEvents.UserProfileInterestsUpdated, {
          target_user_profile_id: this.user.id,
          user_type: this.user.category_code,
          interests: this.interests,
        });
        ToastSuccess.open("Interests successfully saved");
      }, defaultErrorHandler)
      .finally(() => {
        this.loading = false;
        this.$emit("close");
      });
  }

  get isIndustryMember(): boolean {
    return (
      this.user.main_team?.amplitude_team_type === AmplitudeTeamType.Industry
    );
  }
}
