import { RawLocation } from "vue-router";

export const ProjectLinkAccessor: (
  row: Record<string, string | number>
) => RawLocation = (row) => ({
  name: "project_overview",
  params: { id: row["Projects.id"].toString() },
});

export const MvProjectLinkAccessor: (
  row: Record<string, string | number>
) => RawLocation = (row) => ({
  name: "project_overview",
  params: { id: row["MvStudents.ProjectId"].toString() },
});

export const ProjectLeaderLinkAccessor: (
  row: Record<string, string | number>
) => RawLocation = (row) => ({
  name: "user_profile",
  params: { user_id: row["ProjectLeader.id"].toString() },
});

export const userLinkAccessorBuilder: (
  userIdDimension: string = "Users.id"
) => (row: Record<string, string | number>) => RawLocation =
  (userIdDimension = "Users.id") =>
  (row) => ({
    name: "user_profile",
    params: { user_id: row[userIdDimension].toString() },
  });

export const TeamLinkAccessor: (
  row: Record<string, string | number>
) => RawLocation = (row) => ({
  name: "team_overview",
  params: { id: row["Teams.id"].toString() },
});

export const TaskLinkAccessor: (
  row: Record<string, string | number>
) => RawLocation = (row) => ({
  name: "project_tasks",
  params: { id: row["Projects.id"].toString() },
  query: { task_id: row["Tasks.id"].toString() },
});

export const noteLinkAccessorBuilder: (
  noteIdDimension: string
) => (row: Record<string, string | number>) => RawLocation =
  (noteIdDimension = "Notes.id") =>
  (row) => ({
    name: "project_notes",
    params: { id: row["Projects.id"].toString() },
    hash: `#note-${row[noteIdDimension]}`,
  });
