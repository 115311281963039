import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass } from "@/api/lib";
import Task from "@/models/Task";
import { AxiosPromise } from "axios";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";

interface ITaskListParams {
  page?: number;
  users?: number[];
  projects?: number[];
}

export function list(
  userId: number,
  params: ITaskListParams
): AxiosPromise<PaginationResponse<Task[]>> {
  return $http
    .get(`${prefix}/users/${userId}/tasks`, { params })
    .then(response => paginatedResponseToClass(Task, response));
}
