import { Options, serialize } from "object-to-formdata";

export default class FormRequest<T = Record<string, unknown>> {
  [index: string]: unknown;

  transformations = [];

  // Remove null values attributes
  public cleanNull(): FormRequest<T> {
    Object.keys(this).forEach(key => this[key] === null && delete this[key]);

    return this;
  }

  public serialize(): T | FormRequest<T> {
    return this;
  }

  public toFormData(options?: Options): FormData {
    return serialize(
      this.serialize(),
      options || {
        indices: true,
        nullsAsUndefineds: true,
        booleansAsIntegers: true
      }
    );
  }
}
