export default class ManagementGroup {
  public id?: number;
  public name = "";
  public organization_id?: number;
  public is_active = false;
  public users_count = 0;
  public teams_count = 0;
  public created_at?: string;
  public updated_at?: string;
}
