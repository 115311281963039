import Dict from "./dict";
import { IItemRole } from "@/staticCollections/index";

export enum Roles {
  Viewer = "viewer",
  Contributor = "contributor",
  Manager = "manager",
  Liaison = "liaison",
  Academic = "academic",
  Principal_Investigator = "principal investigator",
  Center_Director = "center director",
  Theme_Leader = "theme leader"
}

const items: Record<Roles, IItemRole> = {
  [Roles.Manager]: {
    order: 1,
    value: Roles.Manager,
    translationKey: "Manager"
  },
  [Roles.Contributor]: {
    order: 2,
    value: Roles.Contributor,
    translationKey: "Contributor"
  },
  [Roles.Academic]: {
    order: 3,
    value: Roles.Academic,
    translationKey: "Academic"
  },
  [Roles.Principal_Investigator]: {
    order: 4,
    value: Roles.Principal_Investigator,
    translationKey: "Principal Investigator"
  },
  [Roles.Center_Director]: {
    order: 5,
    value: Roles.Center_Director,
    translationKey: "Center Director"
  },
  [Roles.Theme_Leader]: {
    order: 6,
    value: Roles.Theme_Leader,
    translationKey: "Theme Director"
  },
  [Roles.Liaison]: {
    order: 7,
    value: Roles.Liaison,
    translationKey: "Liaison"
  },
  [Roles.Viewer]: {
    order: 8,
    value: Roles.Viewer,
    translationKey: "Viewer"
  }
};

export default new Dict<Roles, IItemRole>(items);
