import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import Note from "@/models/Note";
import { AxiosPromise } from "axios";

export function list(projectId: number): AxiosPromise<Note[]> {
  return $http
    .get(`${prefix}/projects/${projectId}/notes`)
    .then(response => responseToClass(Note, response));
}

export function store(projectId: number, data: unknown): AxiosPromise<Note> {
  return $http
    .post(`${prefix}/projects/${projectId}/notes`, data)
    .then(response => responseToClass(Note, response));
}
