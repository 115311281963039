import dotize from "dotize";
import _ from "lodash";
import locales from "@/locales/index";

export default class Locales {
  /**
   * @param module Module supports dot notation
   * @param prefix Boolean when true will add prefix
   */
  public static get(
    module: string | null = null,
    prefix = true
  ): Record<string, unknown> {
    const keys: Record<string, unknown> = {};

    for (const locale in locales) {
      keys[locale] = locales[locale];

      if (module !== null) {
        if (!_.has(locales[locale], module)) {
          console.warn(`Locale ${locale} does not contain the path ${module}`);
        }

        keys[locale] = _.get(keys[locale], module, {});
      }

      keys[locale] = dotize.convert(keys[locale], prefix ? module : null);
    }

    return keys;
  }

  public static availableLocales(): string[] {
    return Object.keys(locales);
  }

  public static hasLocale(locale: string): boolean {
    return this.resolveToAvailableLocale(locale) !== null;
  }

  public static resolveToAvailableLocale(locale: string): string | null {
    const available = this.availableLocales();

    if (available.includes(locale)) {
      return locale;
    }

    const generic = /([a-z]{2})-[A-Z]{2}/.exec(locale);

    if (generic && available.includes(generic[1])) {
      return generic[1];
    }

    return null;
  }
}
