import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
} from "@/reports/ReportModel";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["ProjectUser.uniqueUserCount"],
  order: [
    ["SrcAccessCategoryTags.businessEntity", "asc"],
    ["MainTeams.name", "asc"],
  ],
  dimensions: ["SrcAccessCategoryTags.businessEntity", "MainTeams.name"],
  segments: [
    "ProjectUser.onlyUserActiveProjects",
    "MainTeams.notDeleted",
    "SrcAccessCategoryTags.notDeleted",
  ],
};
// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "MainTeams.name",
    label: "Teams Name",
  },
  {
    dimension: "SrcAccessCategoryTags.businessEntity",
    label: "Business Entity",
  },
  {
    dimension: "SrcAccessCategoryTags.center",
    label: "Center",
  },
  {
    dimension: "SrcAccessCategoryTags.theme",
    label: "Theme",
  },
  {
    dimension: "SrcAccessCategoryTags.thrust",
    label: "Thrust",
  },
  {
    dimension: "SrcAccessCategoryTags.subthrust",
    label: "Subthrust",
  },
  {
    dimension: "SrcAccessCategoryTags.area",
    label: "Science Area",
  },
  {
    dimension: "SrcAccessCategoryTags.seismicshift",
    label: "Seismic Shift",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Business Entity",
    key: "SrcAccessCategoryTags.businessEntity",
    type: ReportColumnType.Text,
  },
  {
    title: "Project Users",
    key: "ProjectUser.uniqueUserCount",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Company",
    key: "MainTeams.name",
    type: ReportColumnType.Text,
  },
];

export const AccessAuditReportConfig: ReportConfiguration = {
  name: "Access Audit (901)",
  section: "QA",
  scope: ReportScope.Organization,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: false,
  sortColumns: extractSortColumnsFromQuery(query),
};
