import Component from "vue-class-component";
import Vue from "vue";
import { DeploymentCompleteEvent } from "@/events/DeploymentCompleteEvent";
import store from "@/store";
import { MutationTypes } from "@/store/modules/application";
import { RealTimeEvents } from "@/events/RealTimeEvents";
import { echoClientFactory } from "@/services/echo";

@Component
export default class DeploymentMixin extends Vue {
  public listenForUpdate(): void {
    echoClientFactory().then(echo => {
      echo
        .channel("deployment")
        .listen(
          RealTimeEvents.DeploymentCompleteEvent,
          (e: DeploymentCompleteEvent) => {
            store.commit(`application/${MutationTypes.UpdateAvailable}`);

            this.$buefy.snackbar.open({
              type: "is-info",
              message: "A new version of the application is available.",
              actionText: "Refresh",
              indefinite: true,
              onAction: () => window.location.reload()
            });
          }
        );
    });
  }
}
