type InstanceConstructor<TSingleton> = () => TSingleton;

export class Singleton<TSingleton> {
  private instance: TSingleton | null = null;
  private readonly instanceConstructor: InstanceConstructor<TSingleton>;
  constructor(instanceConstructor: InstanceConstructor<TSingleton>) {
    this.instanceConstructor = instanceConstructor;
  }
  getInstance(): TSingleton {
    if (this.instance === null) {
      this.instance = this.instanceConstructor();
    }

    return this.instance;
  }
}
