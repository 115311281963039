import * as ElectronicSignature from "@/api/namespaces/noteVersion/electronicSignature";
import { AxiosPromise } from "axios";
import NoteVersion from "@/models/NoteVersion";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";

export { ElectronicSignature };

export function show(versionId: number): AxiosPromise<NoteVersion> {
  return $http
    .get(`${prefix}/note-versions/${versionId}`)
    .then(response => responseToClass(NoteVersion, response));
}
