import $http from "@/services/Http";
import { prefix } from "@/api/config";
import CreateFormRequest from "@/forms/requests/team/project/CreateFormRequest";
import { AxiosPromise } from "axios";
import Project from "@/models/Project";
import { responseToClass } from "@/api/lib";
import Team from "@/models/Team";

export function create(
  teamId: number,
  form: CreateFormRequest
): AxiosPromise<Project> {
  return $http
    .post(`${prefix}/teams/${teamId}/projects`, form)
    .then(response => responseToClass(Team, response));
}
