import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/users/:user_id",
    name: "user_profile",
    component: () =>
      import(
        /* webpackChunkName: "route.user.profile" */ "../views/UserProfile.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/users/:user_id/edit",
    name: "user_profile_edit",
    component: () =>
      import(
        /* webpackChunkName: "route.user.profile.edit" */ "../views/UserProfileEdit.vue"
      ),
    meta: {
      auth: true,
      breadcrumbs: "Edit profile"
    }
  },
  {
    path: "/license-agreement",
    name: "license-agreement",
    component: () =>
      import(
        /* webpackChunkName: "route.license-agreement" */ "../views/LicenseAgreement.vue"
      ),
    meta: {
      auth: true
    }
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: () =>
      import(
        /* webpackChunkName: "route.onboarding" */ "../views/Onboarding.vue"
      ),
    meta: {
      auth: true
    }
  }
];

export default routes;
