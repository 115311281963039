import User from "@/models/User";

export function getAffiliation(user: User): string {
  const { category, main_team, university_affiliation } = user;

  const affiliation =
    category?.code === "employee"
      ? main_team?.name || ""
      : university_affiliation?.name || "";

  return affiliation ? `@ ${affiliation}` : "";
}

export function getDisambiguatedUser(user: User): string {
  if (!user) {
    return "";
  }
  if (!user.category) {
    return user.professional_title || "";
  }

  return `${user.professional_title || ""} ${user.category.label ||
    ""} ${getAffiliation(user)}`.trim();
}
