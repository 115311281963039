import { Query } from "@cubejs-client/core";
import {
  FilterableDimensionConfig,
  FilterVariables,
  ReportColumn,
  ReportColumnType,
  ReportConfiguration,
  ReportScope,
  TimeDimensionPosition,
  TimeDimensionType,
} from "@/reports/ReportModel";
import { userLinkAccessorBuilder } from "@/reports/ReportLinkAccessors";
import { extractSortColumnsFromQuery } from "@/reports/organization/src/ReportUtils";

const query: Query = {
  measures: ["MvStudents.countryList"],
  order: {
    "MvStudents.WeStartsAt": "desc",
  },
  filters: [
    {
      member: "MvStudents.WeCompanyTeamId",
      operator: "equals",
      values: [FilterVariables.TeamId],
    },
  ],
  dimensions: [
    "MvStudents.id", // Required for LinkAccessor
    "MvStudents.name",
    "MvStudents.universityName",
    "MvStudents.linkedInId",
    "MvStudents.email",
    "MvStudents.availableForHireAt",
    "MvStudents.visa",
    "MvStudents.exportControlStatus",
    "MvStudents.WeCompanyCategory",
    "MvStudents.WeStartsAt",
    "MvStudents.WeEndsAt",
    "MvStudents.WeTitle",
    "MvStudents.ResumeId", // Required for link accessor
    "MvStudents.ResumeName",
    "MvStudents.WeDegreeDegree",
    "MvStudents.WeDegreeField",
    "MvStudents.country",
  ],
  segments: ["MvStudents.onlyInternships", "MvStudents.userNotDeleted"],
};

// order is important
const filterableDimensions: FilterableDimensionConfig[] = [
  {
    dimension: "MvStudents.name",
    label: "User Name",
  },
  {
    dimension: "MvStudents.universityName",
    label: "University",
  },
  {
    dimension: "MvStudents.country",
    label: "Citizenship",
  },
  {
    dimension: "MvStudents.visa",
    label: "US Visa Status",
  },
  {
    dimension: "MvStudents.WeDegreeDegree",
    label: "Degree Type",
  },
  {
    dimension: "MvStudents.WeDegreeField",
    label: "Degree Field",
  },
  {
    dimension: "MvStudents.BusinessEntity",
    label: "Business Entity",
  },
  {
    dimension: "MvStudents.Center",
    label: "Center",
  },
  {
    dimension: "MvStudents.Theme",
    label: "Theme",
  },
  {
    dimension: "MvStudents.Thrust",
    label: "Thrust",
  },
  {
    dimension: "MvStudents.Subthrust",
    label: "Subthrust",
  },
  {
    dimension: "MvStudents.Area",
    label: "Science Area",
  },
  {
    dimension: "MvStudents.SeismicShift",
    label: "Seismic Shift",
  },
  {
    dimension: "MvStudents.ResearchScholar",
    label: "Research Scholar",
  },
];

// order is important
const columns: ReportColumn[] = [
  {
    title: "Student ID",
    key: "MvStudents.id",
    type: ReportColumnType.Numeric,
  },
  {
    title: "Student name",
    key: "MvStudents.name",
    type: ReportColumnType.Link,
    linkAccessor: userLinkAccessorBuilder("MvStudents.id"),
  },
  {
    title: "Student University",
    key: "MvStudents.universityName",
    type: ReportColumnType.Text,
  },
  {
    title: "Student email",
    key: "MvStudents.email",
    type: ReportColumnType.Text,
  },
  {
    title: "Internship start date",
    key: "MvStudents.WeStartsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Internship end date",
    key: "MvStudents.WeEndsAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Internship company category",
    key: "MvStudents.WeCompanyCategory",
    type: ReportColumnType.Text,
  },
  {
    title: "Internship title",
    key: "MvStudents.WeTitle",
    type: ReportColumnType.Text,
  },
  {
    title: "Available for hire date",
    key: "MvStudents.availableForHireAt",
    type: ReportColumnType.Date,
  },
  {
    title: "Student LinkedIn",
    key: "MvStudents.linkedInId",
    type: ReportColumnType.Text,
  },
  {
    title: "Resume",
    key: "MvStudents.ResumeName",
    fileIdMember: "MvStudents.ResumeId",
    type: ReportColumnType.File,
  },
  {
    title: "Degree Type",
    key: "MvStudents.WeDegreeDegree",
    type: ReportColumnType.Text,
  },
  {
    title: "Degree Field",
    key: "MvStudents.WeDegreeField",
    type: ReportColumnType.Text,
  },
  {
    title: "Citizenships",
    key: "MvStudents.countryList",
    type: ReportColumnType.Text,
  },
  {
    title: "US Visa status",
    key: "MvStudents.visa",
    type: ReportColumnType.Text,
  },
  {
    title: "Export Control Status",
    key: "MvStudents.exportControlStatus",
    type: ReportColumnType.Text,
  },
];

export const Internships453MReportConfig: ReportConfiguration = {
  name: "Internships (453M)",
  section: "Student",
  scope: ReportScope.Team,
  query,
  filterableDimensions,
  columns,
  hasDateFilters: true,
  timeDimensionType: TimeDimensionType.DayRange,
  timeDimension: {
    startsAt: "MvStudents.WeStartsAt",
    endsAt: "MvStudents.WeEndsAt",
  },
  sortColumns: extractSortColumnsFromQuery(query),
  timeDimensionPosition: TimeDimensionPosition.Top,
  zipExport: true,
};
