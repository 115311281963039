import { EventBus } from "@/services/eventBus";
import { PluginObject } from "vue";

const plugin: PluginObject<any> = {
  install(Vue, options) {
    Vue.prototype.$bus = EventBus;
  }
};

export default plugin;
