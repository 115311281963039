import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";

import { PluginObject } from "vue";
import {
  faCrosshairs,
  faFire,
  faHeartbeat,
  faLanguage,
  faMapPin,
  faPauseCircle,
  faSlidersHSquare,
  faTrophy,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faFacebook,
  faGithub,
  faGooglePlusSquare,
  faLinkedin,
  faOrcid,
  faResearchgate,
} from "@fortawesome/free-brands-svg-icons";

import {
  faAddressBook,
  faAnalytics,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowToBottom,
  faArrowUp,
  faAsterisk,
  faAward,
  faBan,
  faBell,
  faBolt,
  faBookAlt,
  faBookmark,
  faBookOpen,
  faBoxOpen,
  faBuilding,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCamera,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCertificate,
  faChalkboard,
  faChartBar,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faCheeseburger,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboardCheck,
  faClock,
  faCloudDownload,
  faCog,
  faCogs,
  faComment,
  faComments,
  faCommentsAlt,
  faCompressWide,
  faCopy,
  faDatabase,
  faDesktop,
  faDiploma,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchange,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandWide,
  faExternalLinkAlt,
  faEye,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCertificate,
  faFileCheck,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileExport,
  faFileImage,
  faFileImport,
  faFilePdf,
  faFilePowerpoint,
  faFileSearch,
  faFileUser,
  faFileVideo,
  faFileWord,
  faFilter,
  faFire as faFireSolid,
  faFlag,
  faFlask,
  faFolder,
  faFolderOpen,
  faFunction,
  faGlobeAmericas,
  faHandshake,
  faHandsHelping,
  faHdd,
  faHistory,
  faHome,
  faHourglass,
  faICursor,
  faInfo,
  faInfoCircle,
  faLayerGroup,
  faLevelDown,
  faLevelUp,
  faLevelUpAlt,
  faLightbulb,
  faLink,
  faListUl,
  faLock,
  faLockOpen,
  faMagic,
  faMailBulk,
  faMegaphone,
  faMicroscope,
  faMinus as faMinusSolid,
  faMinusCircle,
  faNewspaper,
  faPencil,
  faPenNib,
  faPercent,
  faPhone,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPrint,
  faProjectDiagram,
  faQuestionCircle,
  faRocketLaunch,
  faSackDollar,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faShare,
  faSignature,
  faSignIn,
  faSignOut,
  faSitemap,
  faSlash,
  faSmile,
  faSortAmountDownAlt,
  faStar,
  faStickyNote,
  faStopCircle,
  faStopwatch,
  faSuitcase,
  faSyncAlt,
  faTachometerAltFastest,
  faTag,
  faTags,
  faTasks,
  faTasksAlt,
  faTh,
  faThLarge,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUniversity,
  faUpload,
  faUser,
  faUserClock,
  faUserCog,
  faUserCrown,
  faUserEdit,
  faUserGraduate,
  faUserLock,
  faUserPlus,
  faUsers,
  faUsersClass,
  faUsersCog,
  faUserShield,
  faUserSlash,
  faUserTag,
  faUserTie,
} from "@fortawesome/pro-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faChartScatter,
  faColumns,
  faMinus,
  faSync as faSyncLight,
  faTable,
} from "@fortawesome/pro-light-svg-icons";

import {
  faTh as faThDuo,
  faUserFriends as faUserFriendsDuo,
  faUsers as faUsersDuo,
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
  faBookOpen,
  faFileUser,
  faGlobeAmericas,
  faComment,
  faSackDollar,
  faUserGraduate,
  faTrophy,
  faUserTie,
  faAnalytics,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowLeft,
  faArrowRight,
  faArrowToBottom,
  faArrowUp,
  faArrowsAlt,
  faAsterisk,
  faAward,
  faBan,
  faBookAlt,
  faBookmark,
  faBolt,
  faBoxOpen,
  faBuilding,
  faCalendarCheck,
  faCalendarDay,
  faCamera,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCertificate,
  faChalkboard,
  faChartBar,
  faChartNetwork,
  faChartScatter,
  faCheck,
  faCheckCircle,
  faCheeseburger,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCloudDownload,
  faCog,
  faCogs,
  faColumns,
  faComments,
  faCommentsAlt,
  faCompressWide,
  faCopy,
  faDatabase,
  faDesktop,
  faDiploma,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchange,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandWide,
  faExternalLinkAlt,
  faEye,
  faFacebook,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCertificate,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileExport,
  faFileImage,
  faFileImport,
  faFilePdf,
  faFilePowerpoint,
  faFileSearch,
  faFileVideo,
  faFileWord,
  faFilter,
  faFlask,
  faFolder,
  faFolderOpen,
  faFunction,
  faGithub,
  faGooglePlusSquare,
  faHandsHelping,
  faHandshake,
  faHdd,
  faHeartbeat,
  faHistory,
  faHome,
  faHourglass,
  faICursor,
  faInfo,
  faInfoCircle,
  faLanguage,
  faLayerGroup,
  faLevelUpAlt,
  faLinkedin,
  faLink,
  faListUl,
  faLock,
  faLockOpen,
  faMagic,
  faMailBulk,
  faMicroscope,
  faMinus,
  faMinusCircle,
  faMinusSolid,
  faNewspaper,
  faOrcid,
  faPauseCircle,
  faPenNib,
  faPencil,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPrint,
  faProjectDiagram,
  faQuestionCircle,
  faResearchgate,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faShare,
  faSignIn,
  faSignOut,
  faSignature,
  faSitemap,
  faSlash,
  faSortAmountDownAlt,
  faStar,
  faStickyNote,
  faStopCircle,
  faSuitcase,
  faSyncAlt,
  faSyncLight,
  faTable,
  faTag,
  faTags,
  faTasks,
  faTasksAlt,
  faTh,
  faThDuo,
  faThLarge,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUniversity,
  faUpload,
  faUser,
  faUserClock,
  faUserCrown,
  faUserEdit,
  faUserFriendsDuo,
  faUserLock,
  faUserPlus,
  faUserShield,
  faUserCog,
  faUserSlash,
  faUsers,
  faUsersClass,
  faUsersDuo,
  faUserTag,
  faUsersCog,
  faSlidersHSquare,
  faMapPin,
  faFire,
  faCrosshairs,
  faUsersCog,
  faLightbulb,
  faBell,
  faFlag,
  faLevelUp,
  faLevelDown,
  faDollarSign,
  faPercent,
  faFileCheck,
  faCalendar,
  faRocketLaunch,
  faSmile,
  faTachometerAltFastest,
  faMegaphone,
  faStopwatch,
  faHandsHelping,
  faPhone,
  faAddressBook,
  faClipboardCheck,
  faFireSolid
);

const plugin: PluginObject<any> = {
  install(Vue, options) {
    Vue.component("FaIcon", FontAwesomeIcon);
    Vue.component("FaLayers", FontAwesomeLayers);
    Vue.component("FaLayersText", FontAwesomeLayersText);
  },
};

export default plugin;
