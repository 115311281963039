import Locales from "@/services/Locales";
import i18n from "@/i18n";
import Component from "vue-class-component";
import Vue from "vue";
import { Validation } from "vuelidate";
import { Emit, Watch } from "vue-property-decorator";

@Component
export default class ValidationMixin extends Vue {
  /**
   * Helper method to output buefy
   */
  public bType(hasError: boolean): string {
    return hasError ? "is-danger" : "";
  }

  public bMessage(
    fieldValidation: Validation,
    params: Record<string, string>
  ): string {
    const vuelidateMessages = Locales.get("vuelidate", false)[i18n.locale];
    for (const validation in fieldValidation.$params) {
      if (!fieldValidation[validation] && fieldValidation.$error) {
        return this.bReplace(
          vuelidateMessages[validation] || "Undefined message",
          params
        );
      }
    }

    return "";
  }

  public bCustomMessage(fieldValidation: Validation, text: string): string {
    if (fieldValidation.$error) {
      return text;
    }

    return "";
  }

  public bReplace(subject: string, params: Record<string, string>): string {
    if (!subject) {
      return subject;
    }

    for (const token in params) {
      subject = subject.replace(`{${token}}`, params[token]);
    }

    return subject;
  }

  public handleFieldChanged(event: Event, validationField: string): void {
    validationField
      .split(".")
      .reduce((object, field) => object[field], this.$v)
      .$touch();
  }

  public editUrl(url: string): void {
    //The start of the url needs to look like a protocol
    if (typeof this.url === "undefined") {
      return;
    }

    const regex = /^[0-9a-z]+:\/\/.+/i;
    if (url !== null && url.length > 2 && regex.exec(url) === null) {
      //if not we default to https
      this.url = "https://" + this.url;
    }
    this.$v.url.$touch();
  }

  // Bind on <form @input="handleFormInput">
  @Emit("input")
  public handleFormInput(event): void {
    return this.form;
  }

  @Watch("$v.$invalid", {
    deep: true
  })
  @Emit("valid")
  public handleWatchFormIsValid(value: boolean, oldValue: boolean): void {
    return !value;
  }
}
