import Project from "@/models/Project";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree
} from "vuex";
import { RootState } from "@/store";

export enum MutationTypes {
  SetProject = "SetProject"
}

export interface IProjectState {
  project: Project | null;
}

const state: IProjectState = {
  project: null
};

const getters: GetterTree<IProjectState, RootState> = {
  users: state => {
    if (!state.project) {
      return [];
    }

    return state.project.users;
  }
};

const actions: ActionTree<IProjectState, RootState> = {
  setProject(
    { commit }: ActionContext<IProjectState, RootState>,
    project: Project | null
  ) {
    commit(MutationTypes.SetProject, project);
  }
};

const mutations: MutationTree<IProjectState> = {
  [MutationTypes.SetProject](state: IProjectState, project: Project | null) {
    state.project = project;
  }
};

export const project: Module<IProjectState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
