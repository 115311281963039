import Dict from "@/staticCollections/dict";

export enum ESignatoryStatus {
  Signed = "signed",
  InProgress = "in_progress",
  Pending = "pending",
  Rejected = "rejected"
}

export interface ISignatoryStatus {
  caption: string;
  icon: string | [string, string];
}

const items: Record<ESignatoryStatus, ISignatoryStatus> = {
  [ESignatoryStatus.Signed]: {
    caption: "Signed",
    icon: "check"
  },
  [ESignatoryStatus.Rejected]: {
    caption: "Rejected",
    icon: "ban"
  },
  [ESignatoryStatus.InProgress]: {
    caption: "In Progress",
    icon: "pen-nib"
  },
  [ESignatoryStatus.Pending]: {
    caption: "Pending",
    icon: "clock"
  }
};

export default new Dict<ESignatoryStatus, ISignatoryStatus>(items);
