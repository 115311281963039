import { AxiosPromise } from "axios";
import CursorPaginatedResponse from "@/interfaces/responses/CursorPaginatedResponse";
import { Notification } from "@/models/Notification";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { cursorPaginatedToClass } from "@/api/lib";

export function feed(): AxiosPromise<CursorPaginatedResponse<Notification[]>> {
  return $http
    .get(`${prefix}/news`)
    .then(response => cursorPaginatedToClass(Notification, response));
}
