import IModel from "@/models/IModel";
import ITimestamps from "@/models/TimestampsInterface";
import Signatory, { computeStatus } from "@/models/Signatory";
import { Type } from "class-transformer";
import { spaceship } from "@/services/Utils";

export enum SubjectTypes {
  // @todo: Should read NoteVersion instead. I don't have time to evaluate refactoring impact of changing this
  Note = "App\\Models\\Note"
}

export enum ElectronicSignatureStatus {
  Active = "active",
  Complete = "complete",
  Rejected = "rejected"
}

export default class ElectronicSignature implements IModel, ITimestamps {
  public id: number;
  public subject_type: SubjectTypes = SubjectTypes.Note;
  @Type(() => Signatory)
  public signatories: Signatory[] = [];
  public status: ElectronicSignatureStatus = ElectronicSignatureStatus.Active;
  public status_at: string | null = null;
  public created_at: string | null = null;
  public updated_at: string | null = null;

  public get signatoriesWithStatus(): Signatory[] {
    return this.signatories.map(sig => {
      return Object.assign(new Signatory(), sig, {
        status: computeStatus(sig, this.signatories)
      });
    });
  }

  public isUserHaveAlreadySigned(userId: number): boolean {
    if (!userId) {
      return false;
    }

    return this.signatories
      .filter(sig => sig.action_at !== null)
      .some(sig => sig.user.id === userId);
  }

  public isUserRequestedToSignNow(userId: number): boolean {
    if (!userId) {
      return false;
    }

    const nextSig = this.signatories
      .slice(0)
      .sort((a, b) => spaceship(a.order, b.order))
      .find(sig => sig.action_at === null);

    return nextSig !== undefined && nextSig.user?.id === userId;
  }

  public isUserRequestedToSignLater(userId: number): boolean {
    if (!userId) {
      return false;
    }

    return (
      this.signatories
        .filter(sig => sig.action_at === null)
        .some(sig => sig.user.id === userId) &&
      this.isUserRequestedToSignNow(userId)
    );
  }
}
