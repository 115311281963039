import _Vue from "vue";
import { formal, formattedFormation } from "@/utils/Format";
import { getDisambiguatedUser } from "@/components/user/userDisambiguation";

export default {
  install(Vue: typeof _Vue, options: any) {
    Vue.mixin({
      filters: {
        formal,
        formattedFormation,
        getDisambiguatedUser
      }
    });
  }
};
