import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import { AxiosPromise, AxiosResponse } from "axios";
import UpdateFormRequest from "@/forms/requests/workExperience/UpdateFormRequest";
import WorkExperience from "@/models/WorkExperience";

export function create(
  userId: number,
  data: UpdateFormRequest
): AxiosPromise<WorkExperience> {
  return $http
    .post(`${prefix}/users/${userId}/work-experiences`, data)
    .then(response => responseToClass(WorkExperience, response));
}

export function update(
  workExperienceId: number,
  data: UpdateFormRequest
): AxiosPromise<WorkExperience> {
  return $http
    .put(`${prefix}/work-experiences/${workExperienceId}`, data)
    .then(
      response =>
        responseToClass(WorkExperience, response) as AxiosResponse<
          WorkExperience
        >
    );
}

export function remove(workExperienceId: number): AxiosPromise<WorkExperience> {
  return $http
    .delete(`${prefix}/work-experiences/${workExperienceId}`)
    .then(
      response =>
        responseToClass(WorkExperience, response) as AxiosResponse<
          WorkExperience
        >
    );
}
