import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import User from "@/models/User";
import { AxiosPromise } from "axios";
import InviteFormRequest from "@/forms/requests/user/InviteFormRequest";

export function list(teamId: number): AxiosPromise<User[]> {
  return $http
    .get(`${prefix}/teams/${teamId}/users`)
    .then(response => responseToClass(User, response));
}

export function searchAvailable(
  teamId: number,
  query: string
): AxiosPromise<User[]> {
  return $http
    .get(`${prefix}/teams/${teamId}/users-available`, {
      params: {
        query
      }
    })
    .then(response => responseToClass(User, response));
}

export function attach(
  teamId: number,
  userId: number,
  data = {}
): AxiosPromise<User> {
  return $http
    .put(`${prefix}/teams/${teamId}/users/${userId}`, data)
    .then(response => responseToClass(User, response));
}

export function promoteToMainTeam(
  teamId: number,
  userId: number,
  data = {}
): AxiosPromise<User> {
  return $http
    .put(`${prefix}/teams/${teamId}/users/${userId}/promote-main-team`, data)
    .then(response => responseToClass(User, response));
}

export function detach(teamId: number, userId: number) {
  return $http.delete(`${prefix}/teams/${teamId}/users/${userId}`);
}

export function inviteByEmail(
  teamId: number,
  email: string,
  data: InviteFormRequest
): AxiosPromise<User> {
  return $http
    .post(`${prefix}/teams/${teamId}/users`, { email, ...data })
    .then(response => responseToClass(User, response));
}
