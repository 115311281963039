import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/v2/*",
    name: "v2",
    component: () => {
      return ""; // return nothing as it's React Router that is handling these routes.
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "*",
    name: "not_found",
    component: () =>
      import(/* webpackChunkName: "route.not-found" */ "../views/404.vue"),
  },
  {
    path: "/unsubscribe-notifications",
    name: "unsubscribe_notifications",
    component: () =>
      import(
        /* webpackChunkName: "route.unsubscribe-notifications" */ "../views/NotificationsUnsubscribe.vue"
      ),
  },
];

export default routes;
