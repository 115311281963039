import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import { AxiosPromise, AxiosResponse } from "axios";
import ProjectParticipation from "@/models/ProjectParticipation";
import UpdateViewModel from "@/forms/requests/organization/projectParticiption/UpdateFormRequest";

export function update(
  participationId: number,
  data: UpdateViewModel
): AxiosPromise<ProjectParticipation> {
  return $http
    .put<UpdateViewModel, AxiosResponse<ProjectParticipation>>(
      `${prefix}/project-participations/${participationId}`,
      data
    )
    .then(response => responseToClass(ProjectParticipation, response));
}
